import { ChevronLeftIcon, ChevronRightIcon } from '@axieinfinity/dango-icons'
import { Button, ButtonVariant } from '@axieinfinity/konan'

type Props = {
  isDisabled: boolean
  onPress: (direction: 'previous' | 'next') => VoidFunction
}

export const PaginationButtons: React.FC<Props> = ({ isDisabled, onPress }) => {
  return (
    <>
      <Button
        variant={ButtonVariant.Plain}
        text={<ChevronLeftIcon size={24} />}
        disabled={isDisabled}
        data-slot="button"
        data-functionality="previous"
        onClick={onPress('previous')}
      />

      <Button
        variant={ButtonVariant.Plain}
        text={<ChevronRightIcon size={24} />}
        disabled={isDisabled}
        data-slot="button"
        data-functionality="next"
        onClick={onPress('next')}
      />
    </>
  )
}
