import { Typography } from '@axieinfinity/konan'
import cx from 'classnames'

import styles from './well.module.scss'

type Props = {
  icon?: React.ReactNode
  title?: string
  children?: React.ReactNode
  className?: string
}

export const Well: React.FC<Props> = (props) => {
  return (
    <div className={cx(styles.container, props.className)}>
      <div className={styles.icon}>{props.icon}</div>
      <div className={styles.content}>
        <Typography.Text bold level="2">
          {props.title}
        </Typography.Text>
        <Typography.Text level="2">
          {props.children}
        </Typography.Text>
      </div>
    </div>
  )
}
