import { Search02Icon } from '@axieinfinity/dango-icons'
import { useSetAtom } from 'jotai'
import { FC } from 'react'

import { isMobileSearchOpenedAtom } from '#/core/stores'

import sharedStyles from './shared.module.scss'

const MobileSearch: FC = () => {
  const setIsMobileSearchOpened = useSetAtom(isMobileSearchOpenedAtom)

  return (
    <button className={sharedStyles.button} onClick={() => setIsMobileSearchOpened(true)}>
      <Search02Icon />
    </button>
  )
}

export default MobileSearch
