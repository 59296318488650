import { Intent } from '@axieinfinity/konan'
import { useMemo } from 'react'
import { match, P } from 'ts-pattern'

import { CircularProgress } from '#/components/common/loader'
import { useStoreCategoryList, useStoreMerchantList } from '#/external/store/hooks'

import { StoreFeaturedItem } from './featured-item'
import { StoreItem } from './items'
import styles from './section.module.scss'

type Props = {
  merchantId: number
}

const FEATURE_GAME_NAME = 'Featured'

export const StoreSection: React.FC<Props> = ({ merchantId }) => {
  const {
    data,
    isLoading: isCategoryListLoading,
    error: categoryListError,
  } = useStoreCategoryList(merchantId)
  // prefetch merchant list
  const { isLoading: isMerchantListLoading, error: merchantListError } =
    useStoreMerchantList()

  const isLoading = isCategoryListLoading || isMerchantListLoading
  const error = categoryListError || merchantListError

  const featuredItem = useMemo(() => data?.find((item) => item.name === FEATURE_GAME_NAME), [data])
  const restItems = useMemo(() => data?.filter((item) => item.name !== FEATURE_GAME_NAME), [data])

  return (
    <div className={styles.container}>
      {match({ data, isLoading, error })
        .with({ isLoading: true }, () => <div className={styles.loading}><CircularProgress intent={Intent.Primary} value="indeterminate" /></div>)
        .with({ error: P.not(P.nullish) }, () => (
          <div className={styles.error}>
            <img src="/assets/beat-confused.png" />
            <p>Oop! Something happened. Please try again later.</p>
          </div>
        ))
        .with({ data: P.not(P.nullish) }, () => (
          <>
            {featuredItem && (
              <div className={styles.fragment}>
                <h2 className={styles.title}>{featuredItem.name}</h2>
                <StoreFeaturedItem
                  merchantId={merchantId}
                  categoryId={featuredItem.id}
                />
              </div>
            )}
            {restItems?.map((item) => (
              <div key={item.id} className={styles.fragment}>
                <h2 className={styles.title}>{item.name}</h2>
                <StoreItem merchantId={merchantId} categoryId={item.id} />
              </div>
            ))}
          </>
        ))
        .otherwise(() => null)}
    </div>
  )
}
