import { useQuery } from '@tanstack/react-query'

import { logger } from '#/core/logger'
import { services } from '#/core/services'

export const newsQueryKeys = {
  all: ['news'] as const,
}

export const useNews = () => {
  return useQuery({
    queryKey: newsQueryKeys.all,
    queryFn: async () => {
      try {
        const { response, error, result } = await services.request(
          'get /v2/public/news?limit=4',
        )
        if (response.status >= 500 || error) {
          return []
        } else {
          return result
        }
      } catch (e) {
        logger.error(e, { error: e }, 'useNews')

        return []
      }
    },
  })
}
