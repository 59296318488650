import { GameDetails } from '@axieinfinity/hub-services'
import { useResponsive } from '@axieinfinity/konan'
import cx from 'classnames'

import { Vote } from '../vote'
import { GameActions } from './actions'
import { PlayOnMobile } from './mobile'
import { MobileGameActions } from './mobile-actions'
import { SocialShare } from './share'
import styles from './shared.module.scss'

type Props = Pick<React.HTMLAttributes<HTMLDivElement>, 'className'> & {
  minimal?: boolean
  game: GameDetails
}

export const Controls: React.FC<Props> = ({
  className,
  minimal = false,
  game,
}) => {
  const { lg: isLargeDimension } = useResponsive()
  const isDuringEvent = Boolean(game.event)

  return (
    <div
      className={cx(styles.controls, className, {
        [styles.horizontal]: minimal,
      })}
    >
      {isLargeDimension ? (
        <GameActions game={game} minimal={minimal} />
      ) : (
        <MobileGameActions game={game} />
      )}
      {isLargeDimension && <PlayOnMobile game={game} minimal={minimal} />}
      <SocialShare game={game} minimal={minimal} />
      {!minimal && isDuringEvent && <Vote game={game} />}
    </div>
  )
}
