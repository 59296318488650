import styles from './header.module.scss'

export const AGJHeader: React.FC<React.PropsWithChildren<{ className?: string }>> = ({ children, className }) => {
  return (
    <header className={[styles.header, className].join(' ')}>
      <div className={styles.axie} />
      <div className={styles.axs} />
      <div className={styles.slp} />
      {children}
    </header>
  )
}
