import type { CollectionGame } from '@axieinfinity/hub-services'
import { Button, Intent, Tag } from '@axieinfinity/konan'
import { YouTubePlayer, type YoutubePlayerRef } from '@axieinfinity/youtube-player'
import { useMemoizedFn } from 'ahooks'
import { useRef } from 'react'
import { useNavigate } from 'react-router'
import { match, P } from 'ts-pattern'

import { platformSymbolMapper } from '#/constants'
import { serializeUrlSearchParams } from '#/utils'

import styles from './card.module.scss'

type Props = CollectionGame & {
  onMediaPlayerEnd?: VoidFunction
}

export const Card: React.FC<Props> = ({
  slug,
  collection,
  logoUrl,
  name,
  description,
  genres,
  platforms,
  onMediaPlayerEnd,
}) => {
  const navigate = useNavigate()

  const figureRef = useRef<HTMLElement>(null)
  const playerRef = useRef<YoutubePlayerRef>(null)

  const handleVideoEnd = useMemoizedFn(() => {
    onMediaPlayerEnd?.()
  })

  function handleTagPress(slug?: string) {
    return (event: React.PointerEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()

      navigate(
        slug
          ? `/games?${serializeUrlSearchParams({ genre: [slug] })}`
          : '/games'
      )
    }
  }

  const handlePressPlayNow = useMemoizedFn(() => {
    navigate(`games/${slug}`)
  })

  const backgroundImageUrl = match(collection.media)
    .returnType<string>()
    .with({ type: 'embed' }, ({ thumbnail }) => thumbnail)
    .with({ type: 'image' }, ({ src }) => src)
    .otherwise(() => '')

  return (
    <figure className={styles.figure} ref={figureRef}>
      <div
        style={{ backgroundImage: `url(${backgroundImageUrl})` }}
        data-slot="media-container"
      >
        {collection.media.type === 'embed' && (
          <YouTubePlayer
            className={styles.player}
            ref={playerRef}
            key={collection.media.src}
            video={collection.media.src}
            options={{ playerVars: { autoplay: 0, controls: 1, rel: 0 } }}
            onEnd={handleVideoEnd}
          />
        )}
      </div>

      <figcaption style={{ backgroundImage: `url(${backgroundImageUrl})` }} data-slot="caption">
        <div data-slot="inner">
          <img data-slot="logo" src={logoUrl} />
          <div data-slot="name">{name}</div>
          <div data-slot="description">{description}</div>
          <div data-slot="genre-list">
            {match(genres)
              .with(undefined, () => null)
              .with(P.not(P.nullish), (genres) => {
                return (
                  <>
                    {genres.slice(0, 2).map((genre) => (
                      <Tag
                        className={styles.tag}
                        intent={Intent.Default}
                        key={genre.slug}
                        interactive
                        onClick={handleTagPress(genre.slug)}
                      >
                        {genre.name}
                      </Tag>
                    ))}
                    {genres.length > 2 && (
                      <Tag
                        className={styles.tag}
                        intent={Intent.Default}
                        key="other"
                        interactive
                        onClick={handleTagPress()}
                      >
                        {`+ ${genres.length - 2}`}
                      </Tag>
                    )}
                  </>
                )
              })
              .exhaustive()}
          </div>
          <div data-slot="control">
            <Button intent={Intent.Primary} text="Play Now" onClick={handlePressPlayNow} />

            <div data-slot="platform">
              {platforms?.map(platform => {
                const Icon = platformSymbolMapper[platform.os]

                return Icon && <Icon key={platform.os} size={20} />
              })}
            </div>
          </div>
        </div>
      </figcaption>
    </figure>
  )
}
