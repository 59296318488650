import { Button, ButtonVariant, ReactAria } from '@axieinfinity/konan'
import cx from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { useSetAtom } from 'jotai'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router'

import { loginDialogAtom } from '#/core/stores'
import { useCaptureEvent } from '#/hooks'
import { useUserProfile } from '#/hooks/query'

import type { NavigationItem } from '../types'
import styles from './navigation-item.module.scss'

const MotionDialog = motion(ReactAria.Dialog)

type Props =
  | NavigationItem
  & {
    variant?: ButtonVariant
  }

export const NavItem: React.FC<Props> = ({ variant = ButtonVariant.Plain, label, href, authRequired, items }) => {
  const captureEvent = useCaptureEvent()
  const navigate = useNavigate()

  const setLoginDialog = useSetAtom(loginDialogAtom)

  const { data: profile } = useUserProfile()

  const triggerRef = useRef<HTMLDivElement>(null)
  const popoverRef = useRef<HTMLElement>(null)
  const popoverTimer = useRef<NodeJS.Timeout | null>(null)
  const [isOpening, setOpen] = useState(false)

  function handleNavigation({ label, href, authRequired }: Partial<NavigationItem>) {
    return () => {
      captureEvent(`Navigate to ${label}`, { authenticated: !!profile })
      setOpen(false)

      if (authRequired && !profile) {
        return setLoginDialog({ isVisible: true, redirect: href })
      }

      if (href) {
        return navigate(href)
      }
    }
  }

  const isActive =
    location.pathname === href ||
    items?.some(({ href }) => href && location.pathname.startsWith(href))

  function handleMouseLeave() {
    popoverTimer.current = setTimeout(() => {
      setOpen(false)
    }, 300)
    if (!popoverRef.current) return
    popoverRef.current.onmouseenter = () => {
      setOpen(true)
      if (popoverTimer.current) clearTimeout(popoverTimer.current)
    }
    popoverRef.current.onmouseleave = () => {
      setOpen(false)
    }
  }

  return (
    <div className={styles.item} ref={triggerRef}>
      <Button
        className={cx(styles.button, { [styles.active]: isActive })}
        variant={variant}
        text={label}
        onClick={handleNavigation({ label, href, authRequired })}
        onPointerEnter={() => setOpen(true)}
        onPointerLeave={handleMouseLeave}
      />

      {isActive && <motion.div className={styles.indicator} layoutId="navActiveLinkIndicator" />}

      {items?.length && (
        <AnimatePresence>
          {isOpening && (
            <ReactAria.Popover
              triggerRef={triggerRef}
              isOpen
              placement="bottom left"
              onOpenChange={setOpen}
            >
              <MotionDialog
                className={styles.popover}
                initial={{ opacity: 0, rotateX: -20 }}
                animate={{ opacity: 1, rotateX: 0 }}
                exit={{ opacity: 0, rotateX: -20 }}
                ref={popoverRef}
              >
                {items?.map(({ label, href, authRequired }, index) => (
                  <button
                    className={styles.childItem}
                    key={label + index}
                    onClick={handleNavigation({ label, href, authRequired })}
                  >
                    {label}
                  </button>
                ))}
              </MotionDialog>
            </ReactAria.Popover>
          )}
        </AnimatePresence>
      )}
    </div>
  )
}
