import { ChevronLeftIcon, ChevronRightIcon, PlayCircleIcon } from '@axieinfinity/dango-icons'
import { GameMedia } from '@axieinfinity/hub-services'
import { useVirtualizer } from '@tanstack/react-virtual'
import { useMemoizedFn } from 'ahooks'
import { useRef } from 'react'
import { match, P } from 'ts-pattern'

import styles from './caption.module.scss'

const listItemWidth = 144
const listItemGap = 16

type Props = {
	items: GameMedia[]
	itemCount: number
	activeItem?: GameMedia
	onSelect: (item: GameMedia) => VoidFunction
}

export const FigCaption: React.FC<Props> = ({ items, itemCount, activeItem, onSelect }) => {
	const parentRef = useRef<HTMLDivElement>(null)

	const virtualizer = useVirtualizer({
		horizontal: true,
		count: itemCount,
		getScrollElement: () => parentRef.current,
		estimateSize: () => listItemWidth + listItemGap,
		overscan: 2,
	})

	const scrollTo = useMemoizedFn((direction: 'next' | 'prev') => () => {
		const range = virtualizer.calculateRange()

		match({ direction, range })
			.with({ range: P.nullish }, () => undefined)
			.with(
				{ direction: 'prev', range: P.not(P.nullish) },
				({ range }) => virtualizer.scrollToIndex(range.startIndex - 1, { align: 'start' })
			)
			.with(
				{ direction: 'next', range: P.not(P.nullish) },
				({ range }) => virtualizer.scrollToIndex(range.startIndex + 1, { align: 'start' })
			)
			.exhaustive()
	})

	const virtualItems = virtualizer.getVirtualItems()
	const range = virtualizer.calculateRange()

	return (
		<figcaption className={styles.caption}>
			<div className={styles.listWrapper} ref={parentRef}>
				<ul
					className={styles.list}
					style={{ width: virtualizer.getTotalSize() }}
				>
					{virtualItems.map((virtualItem) => {
						const item = items[virtualItem.index]
						const thumbnailUrl = match(item)
							.with({ type: 'embed' }, ({ thumbnail }) => thumbnail)
							.with({ type: 'image' }, ({ src }) => src)
							.otherwise(() => '')
						const isActive = match({ activeItem, item })
							.with({ activeItem: P.nullish }, () => false)
							.with(
								{ activeItem: { type: 'embed' }, item: { type: 'embed' } },
								({ activeItem, item }) =>
									activeItem.thumbnail === item.thumbnail
							)
							.with(
								{ activeItem: { type: 'image' }, item: { type: 'image' } },
								({ activeItem, item }) => activeItem.src === item.src
							)
							.with(
								{ activeItem: { type: 'embed' }, item: { type: 'image' } },
								({ activeItem, item }) => activeItem.thumbnail === item.src
							)
							.with(
								{ activeItem: { type: 'image' }, item: { type: 'embed' } },
								({ activeItem, item }) => activeItem.src === item.thumbnail
							)
							.otherwise(() => false)

						return (
							<li
								className={styles.item}
								style={{
									width: virtualItem.size - listItemGap,
									height: '100%',
									position: 'absolute',
									top: 0,
									left: 0,
									backgroundImage: `url(${thumbnailUrl})`,
									transform: `translateX(${virtualItem.start}px)`,
								}}
								key={virtualItem.key}
								data-active={isActive ? 'true' : undefined}
								onClick={!isActive ? onSelect(item) : undefined}
							>
								{item.type === 'embed' && (
									<PlayCircleIcon className={styles.icon} size={40} />
								)}
							</li>
						)
					})}
				</ul>
			</div>

			<div className={styles.controls}>
				<button
					data-slot="control"
					data-hidden={range?.startIndex === 0 ? 'true' : undefined}
					onClick={scrollTo('prev')}
				>
					<ChevronLeftIcon size={24} />
				</button>

				<button
					data-slot="control"
					data-hidden={range?.endIndex === itemCount - 1 ? 'true' : undefined}
					onClick={scrollTo('next')}
				>
					<ChevronRightIcon size={24} />
				</button>
			</div>
		</figcaption>
	)
}
