import { Button, Intent } from '@axieinfinity/konan'
import cx from 'classnames'
import { useAtomValue } from 'jotai'
import { useNavigate } from 'react-router'

import { isSearchEnabledAtom } from '#/core/stores'
import { isBrowsingOnWeb } from '#/utils'

import Search from '../search'
import type { NavigationItem, ProfileMenuItem } from '../types'
import { NavItem } from './navigation-item'
import { Profile } from './profile'
import styles from './section.module.scss'

type Props = {
  navigationItems: NavigationItem[]
  profileMenuItems: ProfileMenuItem[]
}

export const DesktopMenu: React.FC<Props> = ({ navigationItems, profileMenuItems }) => {
  const isSearchEnabled = useAtomValue(isSearchEnabledAtom)
  const navigate = useNavigate()

  const handleNavigation = () => {
    navigate('/about')
  }

  return (
    <div className={styles.container}>
      <div className={styles.group}>
        {navigationItems.map((props, index) => <NavItem key={index} {...props} />)}
      </div>
      <div className={cx(styles.group, styles.rightGroup)}>
        {isSearchEnabled && <Search />}
        {isBrowsingOnWeb && (
          <Button
            intent={Intent.Primary}
            text="Install Hub"
            onClick={handleNavigation}
          />
        )}
        <Profile items={profileMenuItems} />
      </div>
    </div>
  )
}
