export function isEmpty(value: unknown) {
  if (value === null || value === undefined) {
    return true
  }

  if (typeof value === 'string' || Array.isArray(value)) {
    return value.length
  }

  if (typeof value === 'object') {
    return Object.keys(value).length === 0
  }

  if (typeof value === 'number') {
    return false
  }

  return !value
}

export function snakeToCamel(snakeCaseString: string): string {
  return snakeCaseString.replace(/_([a-z])/g, (_, letter) =>
    letter.toUpperCase()
  )
}

export function convertKeysToCamelCase<T>(obj: T): T {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToCamelCase(item)) as unknown as T
  }

  return Object.keys(obj).reduce<Record<string, T>>((acc, key) => {
    const camelCaseKey = snakeToCamel(key)
    acc[camelCaseKey] = convertKeysToCamelCase((obj as never)[key])

    return acc
  }, {}) as T
}

export function camelCaseToWords(value: string) {
  return (
    value
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, function (str) {
        return str.toUpperCase()
      })
  )
}

export function isNotTypeEmpty<TValue>(value: TValue | null | undefined): value is TValue {
	return value !== null && value !== undefined
}

export function downloadFile(url: string): void {
  const anchorTag = document.createElement('a')
  anchorTag.href = url
  anchorTag.download = 'true'
  anchorTag.click()
}
