import { GameDetails, VoteEventGamePayload } from '@axieinfinity/hub-services'
import { useMutation } from '@tanstack/react-query'
import { toast } from 'sonner'

import { errorMessages } from '#/constants'
import { queryClient } from '#/core/react-query'
import { services } from '#/core/services'

import { useHandler } from '../handler'
import { useLoginDialog } from '../login-dialog'
import { useCaptureEvent } from '../tracking'
import { gameDetailsQueryKeys } from './game-details'

export const useVote = (game: GameDetails, onSuccess: () => void) => {
	const captureEvent = useCaptureEvent()
	const { handleGetAccessToken } = useHandler()
	const { openLoginDialog } = useLoginDialog()

	return useMutation({
		mutationFn: async (body: VoteEventGamePayload) => {
			const event = ['Send vote']
			const eventPayload: Record<string, unknown> = {
				slug: game.slug,
				name: game.name,
				type: body.eventType,
			}

			const accessToken = await handleGetAccessToken()
			if (!accessToken) {
				event.push('Open Login dialog')
				captureEvent(event.join(' - '), eventPayload)

				return openLoginDialog()
			}
			const { response, error, result } = await services.request('post /v2/users/games/[slug]/votes',
				{ slug: game.slug, body }
			)
			if (response.status >= 500) {
				event.push('Server busy')
				eventPayload['status'] = response.status
				toast.error(errorMessages.serverBusy)
			} else if (error) {
				event.push('Error')
				eventPayload['error'] = error
				toast.error(error?.message || errorMessages.unexpectedError)
			}
			if (result) {
				event.push('Success')
			}
			captureEvent(event.join(' - '), eventPayload)
		},
		onSuccess: () => {
			onSuccess?.()
			queryClient.invalidateQueries({ queryKey: gameDetailsQueryKeys.details(game.slug) })
		},
	})
}
