import type { GameDetails } from '@axieinfinity/hub-services'
import { Intent, Tag } from '@axieinfinity/konan'
import { useLocation, useNavigate } from 'react-router'
import { match, P } from 'ts-pattern'

import { platformSymbolMapper } from '#/constants'

import styles from './card.module.scss'

type Props = GameDetails & {
  onTagClick?: (slug: string) => void
}

export const GameCard: React.FC<Props> = ({
  slug,
  thumbnailUrl,
  organization,
  name,
  genres,
  platforms,
  onTagClick,
}) => {
  const location = useLocation()
  const navigate = useNavigate()

  function handleFigurePress() {
    navigate(`/games/${slug}`)
  }

  function onClick(slug: string) {
    return (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()
      onTagClick?.(slug)
    }
  }

  function handleClickOtherTag(event: React.MouseEvent<HTMLDivElement>) {
    event.preventDefault()
    event.stopPropagation()

    navigate('/games')
  }

  return (
    <figure className={styles.figure} tabIndex={0} onClick={handleFigurePress}>
      <div data-slot="image-container">
        <img src={thumbnailUrl} data-slot="image" />
      </div>

      <figcaption data-slot="caption">
        <div data-slot="platform">
          {platforms?.map(platform => {
            const Icon = platformSymbolMapper[platform.os]

            return Icon && <Icon key={platform.os} size={20} />
          })}
        </div>
        <div data-slot="publisher">{organization?.name}</div>
        <div data-slot="name">{name}</div>
        <div data-slot="tags">
          {match(genres)
            .with(undefined, () => null)
            .with(P.not(P.nullish), (genres) => (
              <>
                {genres.slice(0, 2).map((genre) => (
                  <Tag
                    className={styles.tag}
                    intent={Intent.Default}
                    key={genre.slug}
                    interactive={typeof onTagClick === 'function'}
                    onClick={onClick(genre.slug)}
                  >
                    {genre.name}
                  </Tag>
                ))}
                {genres.length > 2 && (
                  <Tag
                    className={styles.tag}
                    intent={Intent.Default}
                    key={'other'}
                    interactive={location.pathname !== '/games'}
                    onClick={handleClickOtherTag}
                  >
                    {`+ ${genres.length - 2}`}
                  </Tag>
                )}
              </>
            ))
            .exhaustive()}
        </div>
      </figcaption>
    </figure>
  )
}
