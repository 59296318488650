import { WC_DEFAULT_PROJECT_ID } from './constants'
import { RoninMobileConnector } from './mobile-connector'
import { MobileConnectorOptions } from './types'

export * from './base-connector'
export * from './constants'
export * from './mobile-connector'
export * from './types'
export * from './utils'

export const createRoninMobileConnector = async (options: MobileConnectorOptions) => {
  const connector = new RoninMobileConnector()
  await connector.init({
    ...options,
    projectId: options.projectId || WC_DEFAULT_PROJECT_ID,
  })

  return connector
}
