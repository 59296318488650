import { Intent } from '@axieinfinity/konan'
import cx from 'classnames'

import { CircularProgress } from '#/components/common/loader/circular-progress'

import styles from './overlay.module.scss'

const defaultLoaderComponent = <CircularProgress strokeWidth={6} intent={Intent.Primary} value="indeterminate" />

type Props = {
  maskOpacity?: number
  fit?: boolean
  isVisible?: boolean
  loader?: React.ReactNode
}

export const LoadingOverlay: React.FC<Props> = ({
  maskOpacity = 1,
  fit = false,
  isVisible = true,
  loader = defaultLoaderComponent,
}) => {
  if (!isVisible) {
    return null
  }

  return (
    <div className={cx(styles.container, { [styles.fit]: fit })}>
      <div className={styles.mask} style={{ opacity: maskOpacity }} />
      <div className={styles.loader}>{loader}</div>
    </div>
  )
}
