import { Button, ButtonVariant } from '@axieinfinity/konan'
import { GameDetails } from '@axieinfinity/hub-services'
import { Fragment } from 'react'

import { GameGrid } from '#/components/views/game'
import { useCaptureEvent, useGameFilter, usePaginationQuery } from '#/hooks'
import { useEventGames } from '#/hooks/query'
import { serializeNavigateParams } from '#/utils'

import styles from './list.module.scss'

export const AGJGameList: React.FC = () => {
	const captureEvent = useCaptureEvent()

	const { filters } = useGameFilter()
	const { page, filterBy, setState } = usePaginationQuery()
	const gameFilters = { ...filters, filterBy }

	const navigationParams = serializeNavigateParams({
		from: '/axie-game-jam/2023/submissions',
		name: 'Axie Game Jam 2023',
		filters: { ...gameFilters, filterBy, page },
	})

	const { isLoading, error, data } = useEventGames('agj-2023', { page, pageSize: 18 }, gameFilters)

	function onClickGameCard(game: GameDetails) {
		const { slug, organization, name, id, genres } = game
		captureEvent('Click Game Card on Greenlight List', {
			game: { id, name, slug, genres: genres?.map(g => g.slug) },
			game_org: {
				name: organization?.name,
				slug: organization?.slug,
			},
		})
	}

	const changeFilter = (filterBy: 'newest' | 'top_rate') => {
		setState({ page: 1, filterBy })
	}

	return (
		<Fragment>
			<div className={styles.filter}>
				<Button
					text="Newest"
					variant={
						filterBy === undefined || filterBy === 'newest'
							? ButtonVariant.Default
							: ButtonVariant.Plain
					}
					onClick={() => changeFilter('newest')}
				/>
				<Button
					text="Top rated"
					variant={
						filterBy === 'top_rate'
							? ButtonVariant.Default
							: ButtonVariant.Plain
					}
					onClick={() => changeFilter('top_rate')}
				/>
			</div>
			<GameGrid
				{...{
					isLoading,
					error,
					emptyImage: '/assets/buba-empty-result.png',
					data: data?.data.map(details => details.game),
					navigationParams,
					onClickGameCard,
					pagination: data?.pagination,
				}}
			/>
		</Fragment>
	)
}
