import { BanIcon, BookDownload01Icon, BookUpload01Icon, MobileIcon, PlayCircleIcon, SettingIcon } from '@axieinfinity/dango-icons'
import { Modal, ReactAria } from '@axieinfinity/konan'
import { GameDetails } from '@axieinfinity/hub-services'
import { useAtomValue } from 'jotai'
import { Fragment, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { match } from 'ts-pattern'

import { CircularProgress } from '#/components/common/loader'
import { GameButtonState } from '#/constants'
import { downloadProgressAtom } from '#/core/stores'
import { useCaptureEvent, useGame, useGameDialog } from '#/hooks'
import { extractMobileResource } from '#/utils'

import { PlayOnMobileContent } from '../dialogs/play-on-mobile-content'
import styles from './actions.module.scss'

const navigateParams = new URLSearchParams({
	from: '/library',
	name: 'Library',
})

type GameActions = Record<
	GameButtonState,
	{ label: string; icon: React.ReactNode; action?: () => void }
>
export const LibraryGameAction: React.FC<GameDetails> = game => {
	const {
		state,
		launchGame,
		addToLibrary,
		isAvailableBrowsingPlatform,
		isMobileSupported,
		isInstalled,
		installed,
	} = useGame(game)
	const captureEvent = useCaptureEvent()
	const navigate = useNavigate()
	const { openDialog } = useGameDialog()

	const progress = useAtomValue(downloadProgressAtom)
	const currentPercentage =
		progress?.total && progress?.transferred
			? (progress.transferred / progress.total) * 100
			: undefined

	const actions = useMemo<GameActions>(() => {
		const result: GameActions = {
			[GameButtonState.Install]: {
				label: 'Install',
				icon: <BookDownload01Icon size={24} />,
				action: () => openDialog('install', game),
			},
			[GameButtonState.ComingSoon]: {
				label: 'Coming Soon',
				icon: undefined,
				action: undefined,
			},
			[GameButtonState.Queued]: {
				label: 'Queuing',
				icon: (
					<CircularProgress size={24} strokeWidth={4} value="indeterminate" />
				),
				action: undefined,
			},
			[GameButtonState.Installing]: {
				label: 'Installing',
				icon: (
					<CircularProgress
						size={24}
						strokeWidth={4}
						value={currentPercentage ?? 'indeterminate'}
					/>
				),
				action: undefined,
			},
			[GameButtonState.Play]: {
				label: 'Play',
				icon: <PlayCircleIcon />,
				action: () => launchGame(),
			},
			[GameButtonState.Playing]: {
				label: 'Playing',
				icon: undefined,
				action: undefined,
			},
			[GameButtonState.Update]: {
				label: 'Install Update',
				icon: <BookUpload01Icon size={24} />,
				action: () => openDialog('update', game),
			},
			[GameButtonState.NotSupported]: {
				label: 'Not Available',
				icon: <BanIcon size={24} />,
				action: undefined,
			},
			[GameButtonState.AddToLibrary]: {
				label: 'Add To Library',
				icon: <BookDownload01Icon size={24} />,
				action: () => addToLibrary(),
			},
		}

		return result
	}, [addToLibrary, currentPercentage, game, launchGame, openDialog])

	if (!state) return null

	const isGameUnavailable =
		state === GameButtonState.Install && !isAvailableBrowsingPlatform

	if (isGameUnavailable) {
		return (
			<button className={styles.action} disabled>
				<BanIcon />
				<span>Not available</span>
			</button>
		)
	}

	function captureMenuEvent(event: string, props?: object) {
		const { id, name, slug } = game
		captureEvent(`Game Menu - ${event}`, {
			game: { id, name, slug, currentVersion: installed?.version },
			...props,
		})
	}

	const primary = actions[state]
	const mobilePlatformResources = extractMobileResource(
		game.latestVersion?.resources
	)

	const onMenuAction = (action: string | number) => {
		match(action)
			.with('detail', () => {
				captureMenuEvent('Click View Game Details')
				navigate(`/games/${game.slug}?${navigateParams.toString()}`)
			})
			.with('manage', () => {
				captureMenuEvent('Click Manage')
				openDialog('manage', game)
			})
			.with('uninstall', () => {
				captureMenuEvent('Click Uninstall')
				openDialog('uninstall', game)
			})
			.otherwise(() => null)
	}

	return (
		<div className={styles.actions}>
			<ReactAria.Button
				className={styles.primary}
				isDisabled={primary.action === undefined}
				onPress={primary.action}
			>
				{primary.icon}
				<span>{primary.label}</span>
			</ReactAria.Button>

			{isMobileSupported && (
				<Modal
					target={({ open }) => (
						<ReactAria.Button className={styles.secondary} onPress={open}>
							<MobileIcon size={32} />
						</ReactAria.Button>
					)}
					isDismissable
				>
					<PlayOnMobileContent
						resource={{
							mobileResources: Object.values(mobilePlatformResources).filter(Boolean),
							name: game.name,
						}}
					/>
				</Modal>
			)}

			<ReactAria.MenuTrigger>
				<ReactAria.Button className={styles.secondary}>
					<SettingIcon size={32} />
				</ReactAria.Button>
				<ReactAria.Popover placement="bottom right" className={styles.popover}>
					<ReactAria.Menu
						className={styles.menuContent}
						onAction={onMenuAction}
					>
						<ReactAria.MenuItem id="detail" className={styles.settingItem}>
							View game details
						</ReactAria.MenuItem>
						{isInstalled && (
							<Fragment>
								<ReactAria.MenuItem id="manage" className={styles.settingItem}>
									Manage
								</ReactAria.MenuItem>
								<ReactAria.MenuItem
									id="uninstall"
									className={styles.settingItem}
								>
									Uninstall
								</ReactAria.MenuItem>
							</Fragment>
						)}
					</ReactAria.Menu>
				</ReactAria.Popover>
			</ReactAria.MenuTrigger>
		</div>
	)
}
