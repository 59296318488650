import { Button, Intent, Typography } from '@axieinfinity/konan'
import { useNavigate } from 'react-router'

import { useCaptureEvent } from '#/hooks'

import styles from './placeholder.module.scss'

export const LibraryPlaceholder: React.FC = () => {
  const captureEvent = useCaptureEvent()
  const navigate = useNavigate()

  function goToHome() {
    captureEvent('Click Explore Games - Empty Library')
    navigate('/')
  }

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.image} style={{ backgroundImage: 'url(/assets/axie-gotcha.svg)' }} />
        <div className={styles.content}>
          <Typography.Heading level="3">Library is empty!</Typography.Heading>
          <Typography.Text className={styles.subText}>No game has been added to your Library yet.</Typography.Text>

          <Button className={styles.button} intent={Intent.Primary} text="Explore Games" onClick={goToHome} />
        </div>
      </div>
    </section>
  )
}
