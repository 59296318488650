import { ExternalLinkIcon } from '@axieinfinity/dango-icons'
import { Button, Intent } from '@axieinfinity/konan'
import { COOKIE_NAME } from '@axieinfinity/kukki'
import { config } from '@mavishub/config'
import { useAtomValue } from 'jotai'
import { useEffect, useMemo, useState } from 'react'

import { exchangeTokenId } from '#/core/browser/request'
import { cookie } from '#/core/cookie'
import { logger } from '#/core/logger'
import { appVersionAtom } from '#/core/stores'
import { useCaptureEvent, useHandler, useLoginDialog } from '#/hooks'
import { checkSupportedVersion, isBrowsingOnDesktop } from '#/utils'

import { idSessionStorage } from './helper'
import styles from './id.module.scss'
import { RedirectFromType, RedirectState } from './types'

const authInfo = {
  scope: ['openid', 'profile', 'email'].join(' '),
  redirect: config.idRedirect,
}

const buildIdUrl = (idEndpointUrl: string, id: string, from: RedirectFromType) => {
  const stateData: RedirectState = {
    id,
    from,
    next: window.location.pathname,
  }
  const state = encodeURIComponent(JSON.stringify(stateData))
  const authInfoQueryStr = new URLSearchParams(authInfo).toString()

  return `${idEndpointUrl}/client/${config.idClientId}/authorize?${authInfoQueryStr}&state=${state}`
}

export const IdLogin: React.FC = () => {
  const appVersion = useAtomValue(appVersionAtom)
  const { isVisible } = useLoginDialog()
  const [error, setError] = useState<string>('')
  const captureEvent = useCaptureEvent()
  const { handleLogin, handleOverlay } = useHandler()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    isVisible && setError('')
  }, [isVisible])

  useEffect(() => {
    const unsubscribe = window.bridge?.kataru.on(
      'deeplink:authorize_id',
      async (_, { idToken, idState }) => {
        if (cookie.get(COOKIE_NAME.ACCESS_TOKEN)) {
          return
        }

        const idAuthState = idSessionStorage.getIdTokenState()
        if (idState === null || idState !== idAuthState) {
          setError('Something went wrong!')

          return
        }

        try {
          setLoading(true)
          const data = await exchangeTokenId(idToken)
          await handleLogin(data, false)
          handleOverlay({ idToken })
        } catch (error) {
          setError('Something went wrong!')
          logger.error('ID Login [App] - Failed', { error })
        } finally {
          setLoading(false)
        }
      }
    )

    return () => void unsubscribe?.()
  }, [handleLogin, setError, handleOverlay, captureEvent])

  const idEndpointUrl = useMemo(() => {
    const supportedWaypoint = checkSupportedVersion(appVersion, '2.4.1')

    // todo: remove https://id.skymavis.com if all updated to 2.4.1
    return supportedWaypoint ? config.idEndpoint : 'https://id.skymavis.com' // old domain for v2.4.1
  }, [appVersion])

  const onLogin = () => {
    const idRandom = crypto.randomUUID()
    idSessionStorage.setIdTokenState(idRandom)

    if (isBrowsingOnDesktop) {
      const url = buildIdUrl(idEndpointUrl, idRandom, 'app')
      window.open(url, '_blank')
    } else {
      const url = buildIdUrl(idEndpointUrl, idRandom, 'web')
      window.location.href = url
    }
    captureEvent('Click ID Login')
  }

  const handleRegisterClick = () => {
    captureEvent('Click ID Register')
  }

  return (
    <section className={styles.container}>
      <div className={styles.logo}>
        <img src="/ronin-waypoint-logo.svg" alt="Ronin Waypoint Logo" />
      </div>
      <Button
        className={styles.button}
        type="button"
        intent={Intent.Primary}
        text="Login with Ronin Waypoint"
        loading={loading}
        onClick={onLogin}
      />
      {error && <div className={styles.error}>{error}</div>}
      <footer className={styles.register}>
        <span>Don&apos;t have an account?</span>
        <a
          className={styles.link}
          href={`${idEndpointUrl}/register`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleRegisterClick}
        >
          Register Now
          <ExternalLinkIcon size={16} />
        </a>
      </footer>
    </section>
  )
}
