import { GameDetails } from '@axieinfinity/hub-services'

import { useCaptureEvent, useGameFilter, usePaginationQuery } from '#/hooks'
import { useEventGames } from '#/hooks/query'
import { serializeNavigateParams } from '#/utils'

import { GameGrid } from '../game'

export const GreenlightGameList: React.FC = () => {
	const captureEvent = useCaptureEvent()
	const { filters } = useGameFilter()
	const { page } = usePaginationQuery()

	const navigationParams = serializeNavigateParams({
		from: '/greenlight',
		name: 'Greenlight',
		filters: { ...filters, page },
	})

	const { isLoading, error, data } = useEventGames('greenlight', { page, pageSize: 18 }, filters)

	function onClickGameCard(game: GameDetails) {
		const { slug, organization, name, id, genres } = game
		captureEvent('Click Game Card on Greenlight List', {
			game: { id, name, slug, genres: genres?.map(g => g.slug) },
			game_org: {
				name: organization?.name,
				slug: organization?.slug,
			},
		})
	}

	return (
		<GameGrid
			{...{
				isLoading,
				error,
				emptyImage: '/assets/buba-empty-result.png',
				data: data?.data.map(details => details.game),
				navigationParams,
				onClickGameCard,
				pagination: data?.pagination,
			}}
		/>
	)
}
