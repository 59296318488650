import { JSONRPCRequestPayload } from 'ethereum-types'
import { EventEmitter } from 'events'

import { ConnectorType, EIP1193Event, EIP1193Provider, IBaseConnector, InitialData, JsonRpcRequest } from './types'

export abstract class BaseConnector extends EventEmitter implements IBaseConnector {
  provider?: EIP1193Provider
  type: ConnectorType = ConnectorType.EXTENSION

  abstract connect(): Promise<InitialData>
  abstract disconnect(): Promise<void>
  abstract setupProviderEventListeners(): void

  protected static createFinalPayload(payload: Partial<JSONRPCRequestPayload>): Partial<JSONRPCRequestPayload> {
    return {
      id: BaseConnector.getRandomId(),
      jsonrpc: '2.0',
      params: [],
      ...payload,
    }
  }

  private static getRandomId(): number {
    const extraDigits = 3
    const baseTen = 10
    const datePart = new Date().getTime() * Math.pow(baseTen, extraDigits)
    const extraPart = Math.floor(Math.random() * Math.pow(baseTen, extraDigits))

    return datePart + extraPart
  }

  public async emitPayloadAsync<T>(payload: Partial<JSONRPCRequestPayload>): Promise<T> {
    const finalPayload = BaseConnector.createFinalPayload(payload)

    return new Promise((resolve, reject) => {
      if (!this.provider) {
        throw new Error('Provider is not set')
      }
      const web3Provider = this.provider as EIP1193Provider
      web3Provider.sendAsync(finalPayload as JsonRpcRequest, (error, result) => {
        if (error) {
          reject(error)
        } else if (result !== undefined) {
          resolve((result.result || result) as T)
        }
      })
    })
  }

  getAccounts() {
    return this.emitPayloadAsync<string[]>({ method: 'eth_accounts' })
  }

  requestAccounts() {
    return this.emitPayloadAsync<string[]>({ method: 'eth_requestAccounts' })
  }

  getChainId() {
    return this.emitPayloadAsync<number | string>({ method: 'eth_chainId' })
  }

  getInitialData() {
    return this.emitPayloadAsync<InitialData>({ method: 'wallet_initialData' })
  }

  async syncAccount() {
    const accounts = await this.getAccounts()
    this.emit(EIP1193Event.ACCOUNTS_CHANGED, accounts)

    return accounts
  }

  async syncChainId() {
    const chainId = await this.getChainId()
    this.emit(EIP1193Event.CHAIN_CHANGED, chainId)

    return chainId
  }
}
