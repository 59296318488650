import { Dialog, Typography } from '@axieinfinity/konan'
import { useAtom } from 'jotai'

import { twoFactorTokenAtom } from '#/core/stores'
import { useCaptureEvent } from '#/hooks/tracking'

import styles from './dialog.module.scss'
import { TwoFactorCodeInput } from './input'

export const TwoFactorDialog: React.FC = () => {
  const captureEvent = useCaptureEvent()

  const [twoFactorInfo, setTwoFactorInfo] = useAtom(twoFactorTokenAtom)

  function onClose() {
    captureEvent('Close 2FA Dialog')
    setTwoFactorInfo(null)
  }

  function onVerified() {
    captureEvent('2FA Verified')
    setTwoFactorInfo(null)
  }

  const isVisible = twoFactorInfo !== null

  return (
    <Dialog
      destroyOnClose
      className={styles.dialog}
      rootClassName={styles.dialogRoot}
      size="medium"
      visible={isVisible}
      onClose={onClose}
    >
      <div className={styles.container}>
        <Typography.Heading level="5">Security verification</Typography.Heading>
        <Typography.Text>Enter your 6-digit authentication code below</Typography.Text>
        <TwoFactorCodeInput isVisible={isVisible} onSuccess={onVerified} onExpired={onClose} />
      </div>
    </Dialog>
  )
}
