import { AppleIcon, ClockCircle01Icon, PlayCircleIcon, WindowsIcon } from '@axieinfinity/dango-icons'
import classNames from 'classnames'
import { useSetAtom } from 'jotai'

import { Tooltip } from '#/components/common/tooltip'
import handlers from '#/core/handlers'
import { recentlyPlayedAtom } from '#/core/stores'
import { userOs } from '#/utils'

import styles from './quick-filters.module.scss'

export type LibraryQuickFilter = 'system' | 'playable' | 'recent'

const filters: Array<{
  label: string
  icon: JSX.Element
  key: LibraryQuickFilter
}> = [
    {
      label: 'Only show games that are playable on the current OS',
      icon: userOs === 'macos' ? <AppleIcon /> : <WindowsIcon />,
      key: 'system',
    },
    {
      label: 'Only show installed games',
      icon: <PlayCircleIcon />,
      key: 'playable',
    },
    {
      label: 'Sort by recent activity',
      icon: <ClockCircle01Icon />,
      key: 'recent',
    },
  ]

type Props = {
  value?: LibraryQuickFilter
  onValueChange: React.Dispatch<
    React.SetStateAction<LibraryQuickFilter | undefined>
  >
}
export const LibraryQuickFilters: React.FC<Props> = ({
  value,
  onValueChange,
}) => {
  const setRecentlyPlayed = useSetAtom(recentlyPlayedAtom)

  const toggleFilter = (filter: LibraryQuickFilter) => {
    onValueChange(active => {
      if (active === filter) {
        return undefined
      } else {
        if (filter === 'recent') {
          handlers.getRecentlyPlayed()?.then(setRecentlyPlayed)
        }

        return filter
      }
    })
  }

  return (
    <div className={styles.quickFilters}>
      <h5>Quick filters</h5>
      <div className={styles.filterActions}>
        {filters.map(item => (
          <Tooltip key={item.key} content={item.label} style={{ maxWidth: 200 }}>
            <button
              className={classNames(
                styles.action,
                item.key === value && styles.active
              )}
              onClick={() => toggleFilter(item.key)}
            >
              {item.icon}
            </button>
          </Tooltip>
        ))}
      </div>
    </div>
  )
}
