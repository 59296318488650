import { Button, ButtonVariant, Intent } from '@axieinfinity/konan'
import { useMemoizedFn } from 'ahooks'
import { Fragment, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { match, P } from 'ts-pattern'

import { Skeleton } from '#/components/common/loader/skeleton'
import { Tooltip } from '#/components/common/tooltip'
import { gameGenreSymbolMapper } from '#/constants'
import { usePopularGameGenres } from '#/hooks/query'
import { useCaptureEvent } from '#/hooks/tracking'
import { serializeUrlSearchParams } from '#/utils'

import { GameFilterCategory } from '../../filters'
import { ObservableListItem, PaginationButtons, Section } from '../generic'
import styles from './section.module.scss'

export const GameGenreSection: React.FC = () => {
  const captureEvent = useCaptureEvent()
  const navigate = useNavigate()

  const listElementRef = useRef<HTMLUListElement>(null)

  const { isLoading, data } = usePopularGameGenres()

  const handleBrowseAllClick = useMemoizedFn(() => {
    captureEvent('Browse all Genres')
    navigate('/games')
  })

  const handleTagClick = useMemoizedFn(
    (name: string) => () => captureEvent(`Browse ${name} Genre`)
  )

  const handlePaginationPress = useMemoizedFn((direction: 'previous' | 'next') => () => {
    captureEvent(`Click view ${direction} page`, { collection: 'Most Played Games' })

    if (!listElementRef.current) {
      return
    }

    const nodes = listElementRef.current.childNodes
    const lastItemIndex = nodes.length === 1 ? 0 : nodes.length - 1
    const inViewNodes = Array
      .from(nodes, (node, index) => ({ isInViewport: (node as HTMLLIElement).dataset.inViewport === 'true', index }))
      .filter((node) => node.isInViewport)

    if (inViewNodes.length === 0) {
      return
    }

    const nextElementIndex = match(direction)

      .with('previous', () => inViewNodes[0].index === 0 ? lastItemIndex : inViewNodes[0].index - 1)
      .with('next', () => inViewNodes[inViewNodes.length - 1].index === lastItemIndex ? 0 : inViewNodes[inViewNodes.length - 1].index + 1)
      .exhaustive()

    const element = nodes.item(nextElementIndex) as HTMLLIElement
    element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
  })

  return (
    <Section
      className={styles.section}
      heading="Popular Genres"
      suffix={(
        <Button
          intent={Intent.Primary}
          variant={ButtonVariant.Plain}
          text="Browse all"
          data-slot="button"
          onClick={handleBrowseAllClick}
        />
      )}
      pagination={<PaginationButtons isDisabled={isLoading} onPress={handlePaginationPress} />}
    >
      {match({ isLoading, data })
        .with({ isLoading: true }, () => (
          <ul data-slot="list">
            {Array.from({ length: 6 }, (_, index) => (
              <li key={index} data-slot="list-item">
                <Skeleton height={120} corner="rounded" data-slot="loader" />
              </li>
            ))}
          </ul>
        ))
        .with({ data: P.when((data) => data !== undefined && data.length === 0) }, () => (
          <div data-slot="placeholder" data-content="No data found" />
        ))
        .with({ data: P.not(P.nullish) }, ({ data }) => (
          <ul ref={listElementRef} data-slot="list">
            {data.map(({ slug, name, description }) => {
              const Icon = gameGenreSymbolMapper[slug] ?? Fragment

              return (
                <ObservableListItem containerRef={listElementRef} key={slug} data-slot="list-item">
                  <Tooltip side="bottom" hidden={!description} content={description}>
                    <Link
                      to={`/games?${serializeUrlSearchParams({ [GameFilterCategory.Genre]: [slug] })}`}
                      data-slot="genre"
                      onClick={handleTagClick(name)}
                    >
                      <div data-slot="content">
                        <Icon size={32} data-slot="symbol" />
                        <span data-slot="text">{name}</span>
                      </div>
                    </Link>
                  </Tooltip>
                </ObservableListItem>
              )
            })}
          </ul>
        ))
        .otherwise(() => null)}
    </Section>
  )
}
