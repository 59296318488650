import { useBrowserGlobalListener, useDesktopGlobalListener } from '#/hooks'
import { isBrowsingOnWeb } from '#/utils'

type Props = React.PropsWithChildren

export const DesktopWrapper: React.FC<Props> = ({ children }) => {
  useDesktopGlobalListener()

  return children
}

export const BrowserWrapper: React.FC<Props> = ({ children }) => {
  useBrowserGlobalListener()

  return children
}

export const PlatformDetector: React.FC<Props> = ({ children }) => {
  if (isBrowsingOnWeb) {
    return (
      <BrowserWrapper>{children}</BrowserWrapper>
    )
  }

  return (
    <DesktopWrapper>{children}</DesktopWrapper>
  )
}
