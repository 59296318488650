import cx from 'classnames'
import { useMemo } from 'react'
import { match, P } from 'ts-pattern'

import styles from './skeleton.module.scss'

function formatSize(input: string | number) {
  return typeof input === 'number' ? `${input}px` : input
}

type Props = React.HTMLAttributes<HTMLDivElement> & {
  size?: number
  width?: number | string
  height?: number | string
  fill?: boolean
  fillWidth?: boolean
  fillHeight?: boolean
  corner?: 'none' | 'rounded' | 'rounded-sm' | 'rounded-lg' | 'rounded-xl' | 'rounded-full'
}

export const Skeleton: React.FC<Props> = ({
  style,
  size,
  fill = true,
  fillWidth = false,
  fillHeight = false,
  className,
  width,
  height,
  corner = 'none',
  ...props
}) => {
  const elementWidth = useMemo(() => match({ size, width, fill, fillWidth })
    .with({ size: P.number }, ({ size }) => formatSize(size))
    .with({ width: P.not(P.nullish) }, ({ width }) => formatSize(width))
    .with({ fill: true }, { fillWidth: true }, () => '100%')
    .otherwise(() => undefined), [size, width, fill, fillWidth])
  const elementHeight = useMemo(() => match({ size, height, fill, fillHeight })
    .with({ size: P.number }, ({ size }) => formatSize(size))
    .with({ height: P.not(P.nullish) }, ({ height }) => formatSize(height))
    .with({ fill: true }, { fillHeight: true }, () => '100%')
    .otherwise(() => undefined), [size, height, fill, fillHeight])

  return (
    <div
      {...props}
      className={cx(className, styles.loader, styles[corner])}
      style={{
        width: elementWidth,
        height: elementHeight,
        ...style,
      }}
    />
  )
}
