import { Markdown } from '#/components/common/markdown'

import styles from './description.module.scss'

type Props = {
  data: string
}

export const DescriptionSection: React.FC<Props> = ({ data }) => {
  return (
    <section className={styles.section}>
      <div className={styles.header}>Description</div>

      <div className={styles.body}>
        <Markdown>{data}</Markdown>
      </div>
    </section>
  )
}
