import { GameNewsResponse } from '@axieinfinity/hub-services'

import { LoadableImage } from '#/components/common/loader/loadable-image'
import { formatDate } from '#/utils'

import styles from './card.module.scss'

type Props = GameNewsResponse

export const NewsCard: React.FC<Props> = ({ cover_image, title, post_date }) => {
	return (
		<figure className={styles.figure}>
			<LoadableImage className={styles.image} containerClassName={styles.imageContainer} src={cover_image} />

			<figcaption className={styles.caption}>
				<div className={styles.title}>{title}</div>
				<div className={styles.description}>
					{formatDate(+new Date(post_date), {
						year: 'numeric',
						month: 'short',
						day: '2-digit',
					})}
				</div>
			</figcaption>
		</figure>
	)
}
