import cx from 'classnames'
import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router'

import { isBrowsingOnDesktop } from '#/utils'

import { Footer } from './footer'
import styles from './layout.module.scss'
import { NavigationBar } from './navigation/navbar'

type Props = React.PropsWithChildren

export const Layout: React.FC<Props> = () => {
  const { pathname } = useLocation()

  /**
   * Fix page navigate position
   */
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <div id="layout" className={cx(styles.layout, { [styles.desktop]: isBrowsingOnDesktop })}>
      <NavigationBar />
      <Outlet />
      <Footer />
    </div>
  )
}
