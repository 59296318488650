import { useAtomValue } from 'jotai'

import { appVersionAtom } from '#/core/stores'

import styles from './beta-badge.module.scss'

export const BetaBadge: React.FC = () => {
  const appVersion = useAtomValue(appVersionAtom)

  if (!appVersion?.includes('beta')) {
    return null
  }

  return (
    <div className={styles.badge}>
      <div className={styles.text}>BETA VERSION</div>
    </div>
  )
}
