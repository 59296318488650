import { GameDetails } from '@axieinfinity/hub-services'
import { Button, Intent } from '@axieinfinity/konan'
import { config } from '@mavishub/config'
import cx from 'classnames'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'sonner'

import { Deeplink } from '#/components/common/deeplink'
import { GameButtonState } from '#/constants'
import { logger } from '#/core/logger'
import { useCaptureEvent, useGame, useLoginDialog } from '#/hooks'
import { autoPerformDeeplinkQueryPart } from '#/hooks/global/deeplink'
import { useGameDetails, useUserLibrary, useUserProfile } from '#/hooks/query'
import { isBrowsingOnWeb } from '#/utils'

import styles from './shared.module.scss'

export type GameActionProps = {
	minimal?: boolean
	game: GameDetails
}

export const GameActions: React.FC<GameActionProps> = ({
	game,
	minimal,
}) => {
	const captureEvent = useCaptureEvent()
	const { setLoginDialog } = useLoginDialog()
	const { isComingsoon, gameButtonProps, onlyAvailableToPlayOnWeb, webResource } =
		useGame(game)
	const { isLoading: isLibaryLoading } = useUserLibrary()
	const { isLoading: isValidatingGame } = useGameDetails(game.slug)
	const navigate = useNavigate()
	const searchParams = useSearchParams()

	const { data: profile } = useUserProfile()
	const [isActionPerform, setActionPerform] = useState(false)

	const isLoading =
		isValidatingGame || isLibaryLoading || isActionPerform

	async function onClick() {
		let clickActionError = null
		const action = `Click ${gameButtonProps.text}`
		const clickOnButton = gameButtonProps.onClick
		try {
			setActionPerform(true)
			const { id, name, slug } = game
			captureEvent(action, { game: { id, name, slug } })
			if (!profile) {
				captureEvent('Open login in Game Details', { game: game.slug })
				setLoginDialog({ isVisible: true })
			} else {
				await clickOnButton?.()
			}
		} catch (error) {
			clickActionError = error
			logger.error(error, { error }, 'InstallOrPlay ' + action)
		} finally {
			captureEvent(action, { error: clickActionError, slug: game.slug })
			setActionPerform(false)
		}
	}

	useEffect(() => {
		if (window.location.search.includes(autoPerformDeeplinkQueryPart)) {
			try {
				/* Remove deeplink params to perform the action */
				navigate(
					window.location.pathname +
					window.location.search.replace(autoPerformDeeplinkQueryPart, '')
				)
				if (!profile) {
					setLoginDialog({ isVisible: true })
				} else {
					setTimeout(() => gameButtonProps.onClick?.(), 400)
				}
			} catch (error) {
				const message =
					error instanceof Error ? error.message : 'Unexpected error'
				toast.error(`Cannot peform the action. ${message}`)
				logger.error(error, { error }, 'InstallOrPlay')
			}
		}
	}, [gameButtonProps, searchParams, navigate, profile, setLoginDialog])

	if (isComingsoon) {
		return <Button disabled text={GameButtonState.ComingSoon} />
	}

	if (isBrowsingOnWeb && onlyAvailableToPlayOnWeb && game.slug !== 'project-t') {
		const webUrl = webResource?.downloadUrl ?? `${config.playGameDomain}/${game.slug}`

		return (
			<a
				href={webUrl}
				target="_blank"
				rel="noopener noreferrer"
			>
				<Button
					className={cx(styles.cta, { [styles.minimal]: minimal })}
					intent={Intent.Primary}
					text={GameButtonState.Play}
					onClick={() =>
						captureEvent('Play Game on Web', {
							slug: game.slug,
							name: game.name,
						})}
				/>
			</a>
		)
	}

	if (isBrowsingOnWeb && profile) {
		const navigateTo =
			game.slug === 'project-t'
				? `${config.playGameDomain}/${game.slug}`
				: 'https://welcome.skymavis.com/download/'

		return (
			<Deeplink
				open={`${config.appProtocol}://run/${game.slug}`}
				orNavigate={navigateTo}
			>
				<Button
					className={cx(styles.cta, { [styles.minimal]: minimal })}
					intent={Intent.Primary}
					text={onlyAvailableToPlayOnWeb ? GameButtonState.Play : GameButtonState.Install}
				/>
			</Deeplink>
		)
	}

	return (
		<Button
			className={cx(styles.cta, { [styles.minimal]: minimal })}
			intent={Intent.Primary}
			disabled={isLoading || gameButtonProps.onClick === undefined}
			text={gameButtonProps.text}
			onClick={onClick}
		/>
	)
}
