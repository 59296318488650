import { Button, Intent, Typography } from '@axieinfinity/konan'
import { Link } from 'react-router-dom'

import styles from './error.module.scss'

type ErrorStatusCode = 500 | 404
const contents: Record<ErrorStatusCode, React.ReactNode> = {
  '404': (
    <>
      <Typography.Heading level="3">Game Not Found</Typography.Heading>

      <Link to="/">
        <Button className={styles.button} intent={Intent.Primary} text="Back to Home" />
      </Link>
    </>
  ),
  '500': (
    <>
      <div className={styles.heading}>
        <Typography.Heading className={styles.title} level="3">
          500 - Internal Server Error
        </Typography.Heading>
        <Typography.Text className={styles.subText}>
          The server encountered an error that prevented it from fulfilling the request.
        </Typography.Text>
      </div>

      <Link to="/">
        <Button className={styles.button} intent={Intent.Primary} text="Back to Home" />
      </Link>
    </>
  ),
}

type Props = {
  code: ErrorStatusCode
}

export const ErrorPageContent: React.FC<Props> = ({ code }) => {
  return (
    <section className={styles.section}>
      <img className={styles.mascot} src="/assets/game-not-found.png" />
      {contents[code]}
    </section>
  )
}
