import { Tabs } from '@axieinfinity/konan'
import { GameDetails, GameWithResourcePlatform } from '@axieinfinity/hub-services'
import { useRef } from 'react'

import { formatPlatformOs, isEmpty, userOs } from '#/utils'

import { TabContent } from './content'
import styles from './section.module.scss'

export const SystemRequirementsSection: React.FC<GameDetails> = (game) => {
	const sectionRef = useRef<HTMLDivElement>(null)
	const platforms = game.platforms?.filter(
		({ type }) => type !== 'web'
	) as GameWithResourcePlatform[]

	if (!platforms?.length) {
		return null
	}

	const modifiedPlatforms = platforms.filter(
		(platform) => !(isEmpty(platform.requirements?.minimum) && isEmpty(platform.requirements?.recommended))
	)

	if (!modifiedPlatforms.length) {
		return null
	}

	function scrollIntoView() {
		requestAnimationFrame(() => {
			const parentElement = document.getElementById('game-details-content-container')
			if (parentElement !== null && sectionRef.current !== null) {
				parentElement.scrollTop = sectionRef.current.offsetTop + sectionRef.current.getBoundingClientRect().height
			}
		})
	}

	const currentPlatformIndex = platforms.findIndex((platform) => platform.os === userOs)
	if (currentPlatformIndex > 0) {
		modifiedPlatforms.splice(currentPlatformIndex, 1)
		modifiedPlatforms.unshift(platforms[currentPlatformIndex])
	}

	return (
		<section className={styles.section} ref={sectionRef}>
			<div className={styles.header}>System Requirements</div>

			<div className={styles.body}>
				<Tabs className={styles.tabs} onTabClick={scrollIntoView}>
					{modifiedPlatforms.map((platform) => (
						<Tabs.TabPane tab={formatPlatformOs(platform.os)} tabKey={platform.os} key={platform.os}>
							<TabContent {...platform} />
						</Tabs.TabPane>
					))}
				</Tabs>
			</div>
		</section>
	)
}
