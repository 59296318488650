import { Link } from 'react-router-dom'

import styles from './navigation.module.scss'

const navigationConfiguration = {
  'DISCOVER': [
    { label: 'Browse', href: '/games', internal: true },
    { label: 'Greenlight', href: '/greenlight', internal: true },
    { label: 'App.axie', href: 'https://app.axieinfinity.com/', internal: false },
    { label: 'Mavis Market', href: 'https://marketplace.skymavis.com/', internal: false },
  ],
  'COMMUNITY': [
    { label: 'Blog', href: 'https://blog.axieinfinity.com/', internal: false },
    { label: 'Help Center', href: 'https://support.axieinfinity.com/hc/en-us', internal: false },
  ],
} as const

export const NavigationSection: React.FC = () => {
  return (
    <section className={styles.section}>
      {Object.entries(navigationConfiguration).map(([category, items]) => (
        <div data-slot="column" key={category}>
          <div data-slot="header">{category}</div>
          <ul data-slot="navigation-list">
            {items.map(({ href, internal, label }, index) => (
              <li data-slot="navigation-list-item" key={index}>
                <Link
                  data-slot="anchor"
                  to={href}
                  target={internal ? undefined : '_blank'}
                  rel={internal ? undefined : 'noreferrer'}
                >
                  {label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </section>
  )
}
