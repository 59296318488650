import { useAtomValue } from 'jotai'
import { useLocation, useNavigate } from 'react-router'

import { appVersionAtom } from '#/core/stores'
import { useCaptureEvent } from '#/hooks'
import { isBrowsingOnDesktop } from '#/utils'

import styles from './logo.module.scss'

export const Logo: React.FC = () => {
  const captureEvent = useCaptureEvent()
  const location = useLocation()
  const navigate = useNavigate()

  const appVersion = useAtomValue(appVersionAtom)

  function backHome() {
    captureEvent('Click Logo - Back Home', { appVersion })
    navigate('/')
  }

  return (
    <div className={styles.container}>
      <button
        className={styles.brand}
        disabled={location.pathname === '/'}
        onClick={backHome}
      />

      {isBrowsingOnDesktop && (
        <div className={styles.label}>{`Version ${appVersion}`}</div>
      )}
    </div>
  )
}
