import { QRCode } from 'react-qrcode-logo'

type Props = {
  value: string
  logo?: string
} & Omit<React.ComponentProps<typeof QRCode>, 'value' | 'logoImage'>

export const QR: React.FC<Props> = ({ value, logo, ...props }) => (
  <QRCode
    value={value}
    logoImage={logo}
    removeQrCodeBehindLogo
    size={160}
    logoWidth={20}
    quietZone={10}
    logoPadding={4}
    logoPaddingStyle="circle"
    ecLevel="M"
    qrStyle="dots"
    fgColor="#1C1F25"
    bgColor="#FFF"
    eyeRadius={[8, 8, 8]}
    {...props}
  />
)
