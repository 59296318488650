import useUrlState from '@ahooksjs/use-url-state'

export const usePaginationQuery = () => {
  const [state, setState] = useUrlState<{
    page?: number
    filterBy?: 'newest' | 'top_rate'
    desc?: boolean
  }>(
    {
      page: undefined,
      filterBy: undefined,
      desc: undefined,
    },
    { navigateMode: 'replace' }
  )

  return {
    page: state.page
      ? Number.isNaN(Number(state.page))
        ? undefined
        : Number(state.page)
      : undefined,
    filterBy: state.filterBy,
    desc: state.desc,
    state,
    setPage: (page: number) => setState({ ...state, page }),
    setFilterBy: (filterBy: 'newest' | 'top_rate') =>
      setState({ ...state, filterBy }),
    setDesc: (desc: boolean) => setState({ ...state, desc }),
    setState,
  }
}
