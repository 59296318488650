import cx from 'classnames'

import { CircularProgress } from '#/components/common/loader/circular-progress'

import styles from './validate.module.scss'

type Props = {
  isVisible: boolean
}

export const ValidatingIndicator: React.FC<Props> = ({ isVisible }) => {
  return (
    <div className={cx(styles.container, { [styles.visible]: isVisible })}>
      <div className={cx(styles.indicator)}>
        <CircularProgress size={20} strokeWidth={3} value="indeterminate" />
      </div>
    </div>
  )
}
