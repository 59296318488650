import { RoninMobileConnector } from '@mavishub/connect'
import { match } from 'ts-pattern'

import { services } from '#/core/services'

export const prepareWeb3Auth = async (connector: RoninMobileConnector, address: string) => {
  const nonce = await services.request('get /v2/auth/ronin/fetch-nonce?address=[address]', { address })
  const result = match(nonce.result)
    .with(undefined, () => {
      throw new Error('Cannot connect to authenticate server')
    })
    .otherwise((data) => data)

  const domain = 'hub.skymavis.com'
  const description = 'wants you to sign in with your Ronin account:'
  const statement =
    'I accept the Terms of Use (https://axieinfinity.com/terms-of-use) and the Privacy Policy (https://axieinfinity.com/privacy-policy)'
  const roninAddress = address.replace('0x', 'ronin:')
  const uri = 'URI: https://hub.skymavis.com'
  const version = 'Version: 1'
  const chainId = 'Chain ID: 2020'
  const nonceText = `Nonce: ${result.nonce}`
  const issuedAtText = `Issued At: ${result.issued_at}`
  const expirationTimeText = `Expiration Time: ${result.expiration_time}`
  const notBeforeText = `Not Before: ${result.not_before}`

  const message = `${domain} ${description}\n${roninAddress}\n\n${statement}\n\n${uri}\n${version}\n${chainId}\n${nonceText}\n${issuedAtText}\n${expirationTimeText}\n${notBeforeText}`

  const signature = await new Promise<string>((resolve, reject) => {
    connector.ethProvider?.sendAsync(
      {
        method: 'eth_sign',
        params: [address, message],
      },
      (error: unknown, response) => {
        if (error) reject(error)
        if (!response) reject(new Error('Cannot get signature'))
        resolve(response as unknown as string)
      }
    )
  })

  return { message, signature }
}

const ID_TOKEN_STATE_KEY = 'id_token_state'
export const idSessionStorage = {
  getIdTokenState: () => sessionStorage.getItem(ID_TOKEN_STATE_KEY),
  setIdTokenState: (idRandom: string) => sessionStorage.setItem(ID_TOKEN_STATE_KEY, idRandom),
}
