import { useParams } from 'react-router'

import { EventGameList } from '#/components/views/event/list'
import { EventBannerTitle } from '#/components/views/event/text'
import { FilterColumn, GameFilterCategory } from '#/components/views/filters'
import { useCaptureScreen } from '#/hooks'
import { useEventDetails } from '#/hooks/query'

import styles from './submissions.module.scss'

export const EventSubmissionsScreen = () => {
  const { slug } = useParams()
  useCaptureScreen(`Event ${slug} submissions page`)

  const { data } = useEventDetails(slug || 'undefined')

  return (
    <div className={styles.screen}>
      <div className={styles.container}>
        <div className={styles.title}>
          <EventBannerTitle text={data?.title || ''} />
        </div>
        <div className={styles.entry}>
          <aside>
            <FilterColumn
              filters={[
                GameFilterCategory.Genre,
                GameFilterCategory.Platform,
              ]}
            />
          </aside>
          <div className={styles.body}>
            {slug && <EventGameList slug={slug} />}
          </div>
        </div>
      </div>
    </div>
  )
}
