import { ChevronDownIcon } from '@axieinfinity/dango-icons'
import cx from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { useSetAtom } from 'jotai'
import { useCallback, useReducer } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { match, P } from 'ts-pattern'

import { mobileMenuAtom } from '#/core/stores'
import { useCaptureEvent } from '#/hooks'

import type { NavigationItem } from '../types'
import styles from './navigation-item.module.scss'
import sharedStyles from './shared.module.scss'

type Props =
  | React.HTMLAttributes<HTMLDivElement>
  & NavigationItem
  & { level?: number }

export const NavItem: React.FC<Props> = ({ level = 1, label, symbol, href, items }) => {
  const captureEvent = useCaptureEvent()
  const location = useLocation()
  const navigate = useNavigate()

  const setMobileMenu = useSetAtom(mobileMenuAtom)

  const [isOpen, toggleIsOpen] = useReducer((prevState) => !prevState, false)

  const handleItemClick = useCallback(
    ({ label, href }: Partial<NavigationItem>) => () => {
      captureEvent(`Navigate to ${label}`)
      setMobileMenu({ isVisible: false })

      if (href) {
        navigate(href)
      }
    },
    [captureEvent, navigate, setMobileMenu]
  )

  const hasChildList = items !== undefined && items.length > 0

  return (
    <>
      <div className={styles.item} data-level={level}>
        <button
          className={cx(sharedStyles.button, sharedStyles.block)}
          data-active={location.pathname === href ? 'true' : undefined}
          onClick={handleItemClick({ label, href })}
        >
          {match(symbol)
            .with(P.not(P.nullish), (Element) => <Element size={20} />)
            .otherwise(() => null)}

          {label}
        </button>

        {hasChildList && (
          <button
            className={cx(sharedStyles.button, styles.suffix)}
            data-active={isOpen ? 'true' : undefined}
            onClick={toggleIsOpen}
          >
            <ChevronDownIcon size={20} data-slot="suffix" />
          </button>
        )}
      </div>

      <AnimatePresence>
        {hasChildList && isOpen && (
          <motion.div
            className={styles.childList}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'unset' }}
            exit={{ opacity: 0, height: 0 }}
          >
            {items.map((props, index) => <NavItem {...props} key={index} level={level + 1} />)}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
