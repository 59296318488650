import { SignClientTypes } from '@walletconnect/types'
import { EventEmitter } from 'events'

export interface RequestArguments {
  readonly method: string
  readonly params?: readonly unknown[] | object
}

export interface JsonRpcRequest {
  id: string | undefined
  jsonrpc: '2.0'
  method: string
  params?: Array<unknown>
}

export interface JsonRpcResponse {
  id: string | undefined
  jsonrpc: '2.0'
  method: string
  result?: unknown
  error?: Error
}

export type JsonRpcCallback = (error: Error, response: JsonRpcResponse) => unknown

export interface EIP1193Provider extends EventEmitter {
  request: (args: RequestArguments) => Promise<unknown>
  sendAsync: (payload: JsonRpcRequest, callback: JsonRpcCallback) => void
}

export enum EIP1193Event {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  ACCOUNTS_CHANGED = 'accountsChanged',
  CHAIN_CHANGED = 'chainChanged',
}

export enum WCEvent {
  DISPLAY_URI = 'display_uri',
  SESSION_PING = 'session_ping',
  SESSION_UPDATE = 'session_update',
  SESSION_EVENT = 'session_event',
  SESSION_DELETE = 'session_delete',
}

export type MobileConnectorOptions = {
  projectId?: string
  clientMeta: SignClientTypes.Metadata
  autoHandleDisplayUri?: boolean
}

export declare interface IBaseConnector {
  on(event: EIP1193Event.CONNECT, listener: () => void): this
  on(event: EIP1193Event.DISCONNECT, listener: (account: string) => void): this
  on(event: EIP1193Event.CHAIN_CHANGED, listener: (chainId: number) => void): this
  on(event: EIP1193Event.ACCOUNTS_CHANGED, listener: (accounts: string[]) => void): this
}

export interface IRoninMobileConnector {
  on(event: WCEvent.DISPLAY_URI, listener: (uriValue: string) => void): this
  setChainId(chainId: number): void
}

/**
 * Type of connector:
 * EXTENSION: connect to browser extension
 * MOBILE: connect to mobile via WalletConnect
 * IN_APP: connect to mobile but inside a webview
 */
export enum ConnectorType {
  EXTENSION = 'extension',
  MOBILE = 'mobile',
  IN_APP = 'in_app',
}

/**
 * ConnectorOptions
 * mobileConnectorOptions: options using for mobile WalletConnect
 * shouldRequestAccounts: should send 'eth_requestAccounts' request or just 'eth_accounts', default is 'true'
 */
export type ConnectorOptions = {
  mobileConnectorOptions?: MobileConnectorOptions
  shouldRequestAccounts?: boolean
}

export enum ConnectorStatus {
  IDLE = 'idle',
  CONNECTING = 'connecting',
  CONNECTED = 'connected',
}

export type InitialData = {
  account?: string
  chainId?: number
}
