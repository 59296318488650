import { useSetAtom } from 'jotai'
import { useEffect } from 'react'

import { isSearchEnabledAtom } from '#/core/stores'

export const withDisableSearch = <T extends JSX.IntrinsicAttributes>(
  Component: React.ComponentType<T>
): React.FC<T> => {
  const WrappedComponent: React.FC<T> = props => {
    const setIsSearchEnabled = useSetAtom(isSearchEnabledAtom)

    useEffect(() => {
      setIsSearchEnabled(false)

      return () => setIsSearchEnabled(true)
    }, [setIsSearchEnabled])

    return <Component {...props} />
  }

  return WrappedComponent
}
