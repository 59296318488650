import { DislikeIcon, LikeIcon } from '@axieinfinity/dango-icons'
import { Button, Modal, Typography } from '@axieinfinity/konan'
import { GameDetails } from '@axieinfinity/hub-services'
import cx from 'classnames'
import { useMemo, useState } from 'react'

import { Tooltip } from '#/components/common/tooltip'
import { useCaptureEvent, useHandler, useLoginDialog } from '#/hooks'
import { useGameDetails, useUserProfile, useVote } from '#/hooks/query'
import { compactNumber } from '#/utils'

import { CommentDialog } from './comment'
import { VoteDialogContent } from './content'
import { VoteRequirementContent } from './requirement'
import styles from './vote.module.scss'

export const Vote: React.FC<{ game: GameDetails }> = ({ game }) => {
	const { handleGetAccessToken } = useHandler()
	const { data: profile } = useUserProfile()
	const captureEvent = useCaptureEvent()
	const [isOpenComment, setOpenComment] = useState(false)

	const { openLoginDialog } = useLoginDialog()
	const { isLoading } = useGameDetails(game.slug)
	const { mutateAsync: handleVote, isPending } = useVote(game, () => setOpenComment(true))

	const upvoted = Boolean(game.currentVote?.eventType === 'upvote_create')
	const downvoted = Boolean(game.currentVote?.eventType === 'downvote_create')

	/* Vote requirements */
	const accountAgeRequirementMet = useMemo(() => {
		if (!profile) return false
		const joinDate = new Date(profile.createdAt * 1000)
		joinDate.setDate(joinDate.getDate() + 1)

		return joinDate <= new Date()
	}, [profile])
	// const gameInLibraryRequirementMet = Boolean(checkGameInLibrary(game.slug))
	// const sessionPlayedRequirementMet = true
	const voteRequirements = {
		accountAgeRequirementMet,
		// gameInLibraryRequirementMet,
		// sessionPlayedRequirementMet,
	}
	const allRequirementsMet = Object.values(voteRequirements).every(item => item)

	const voted =
		game.currentVote !== undefined &&
		game.currentVote.eventType !== 'downvote_remove' &&
		game.currentVote.eventType !== 'upvote_remove'
	const commented = game.currentComment !== undefined
	const recommended = commented && upvoted
	const notrecommended = commented && downvoted

	const isStartedVotingPeriod = useMemo(() => {
		if (game.event?.startVoteTime) {
			const startVoteTime = new Date(game.event.startVoteTime * 1000)

			return startVoteTime <= new Date()
		}

		return true
	}, [game.event?.startVoteTime])

	const shouldDisableActionButton =
		isLoading ||
		isPending ||
		voted ||
		!isStartedVotingPeriod

	const openDialogMiddleware = async (
		type: 'Upvote' | 'Downvote',
		next: () => void
	) => {
		const events = [`Click ${type}`]
		const accessToken = await handleGetAccessToken()
		if (!accessToken) {
			events.push('Open Login dialog')
			captureEvent(events.join(' - '))

			return openLoginDialog()
		}
		events.push(`Open ${allRequirementsMet ? 'Confirm' : 'Requirement'} dialog`)
		captureEvent(events.join(' - '), { voteRequirements })
		next()
	}

	const TooltipWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
		if (isStartedVotingPeriod || !game.event?.startVoteTime) {
			return children
		}

		return (
			<Tooltip
				content={`The voting has not started for this event yet. Please wait until ${new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(game.event.startVoteTime * 1000))}`}
			>
				{children}
			</Tooltip>
		)
	}

	return (
		<div className={styles.container}>
			<Typography.Text>
				{voted ? (
					'Thanks for voting!'
				) : (
					<span>
						Do you recommend <strong>{game.name}</strong>?
					</span>
				)}
			</Typography.Text>
			<div className={styles.actions}>
				<Modal
					isDismissable
					turnIntoDrawerOnMobile
					target={({ open }) => (
						<Button
							disabled={shouldDisableActionButton}
							className={cx(
								styles.thumbup,
								{ [styles.voted]: upvoted },
								commented && (recommended ? styles.expand : styles.hide)
							)}
							text={
								<div className={styles.voteLabel}>
									<LikeIcon />
									<span>{recommended ? 'You recommended' : 'Yes'}</span>
									<span className={styles.votecount}>
										{compactNumber(game.upvoteCount || 0)}
									</span>
								</div>
							}
							onClick={() => openDialogMiddleware('Upvote', open)}
						/>
					)}
				>
					{({ close, isMobileViewPort }) =>
						allRequirementsMet ? (
							<VoteDialogContent
								game={game}
								type="upvote"
								closeModal={close}
								shouldDisableVote={shouldDisableActionButton}
								isMobileViewPort={isMobileViewPort}
								confirmVote={() => handleVote({ eventType: 'upvote_create' })}
							/>
						) : (
							<VoteRequirementContent
								{...voteRequirements}
								isMobileViewPort={isMobileViewPort}
							/>
						)}
				</Modal>
				<Modal
					isDismissable
					turnIntoDrawerOnMobile
					target={({ open }) => (
						<TooltipWrapper>
							<Button
								disabled={shouldDisableActionButton}
								className={cx(
									styles.thumbdown,
									{ [styles.voted]: downvoted },
									commented && (notrecommended ? styles.expand : styles.hide)
								)}
								text={
									<div className={styles.voteLabel}>
										<DislikeIcon />
										<span>{recommended ? 'You not recommend' : 'No'}</span>
									</div>
								}
								onClick={() => openDialogMiddleware('Downvote', open)}
							/>
						</TooltipWrapper>
					)}
				>
					{({ close, isMobileViewPort }) =>
						allRequirementsMet ? (
							<TooltipWrapper>
								<VoteDialogContent
									game={game}
									type="downvote"
									closeModal={close}
									shouldDisableVote={shouldDisableActionButton}
									isMobileViewPort={isMobileViewPort}
									confirmVote={() => handleVote({ eventType: 'downvote_create' })}
								/>
							</TooltipWrapper>
						) : (
							<VoteRequirementContent
								{...voteRequirements}
								isMobileViewPort={isMobileViewPort}
							/>
						)}
				</Modal>
			</div>
			{voted && !commented && (
				<div className={styles.commentButton}>
					<Button text="Add comment" onClick={() => setOpenComment(true)} />
				</div>
			)}
			<CommentDialog
				isOpen={isOpenComment}
				game={game}
				onOpenChange={setOpenComment}
			/>
		</div>
	)
}
