import { Helmet } from 'react-helmet-async'

import { useCaptureScreen } from '#/hooks'

import { FilterColumn, GameFilterCategory } from '../views/filters'
import { GreenlightHeader } from '../views/greenlight/header'
import { GreenlightGameList } from '../views/greenlight/list'
import styles from './greenlight.module.scss'

export const GreenLightScreen = () => {
  useCaptureScreen('GreenLight')

  return (
    <div className={styles.screen}>
      <Helmet>
        <title>Greenlight - Mavis Hub</title>
      </Helmet>

      <GreenlightHeader />
      <div className={styles.container}>
        <aside className={styles.aside}>
          <FilterColumn filters={[GameFilterCategory.Genre, GameFilterCategory.Platform]} />
        </aside>
        <section className={styles.body}>
          <GreenlightGameList />
        </section>
      </div>
    </div>
  )
}
