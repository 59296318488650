import { ChevronDownIcon } from '@axieinfinity/dango-icons'
import { Button, ButtonVariant, Intent, Size } from '@axieinfinity/konan'
import cx from 'classnames'
import { useAtomValue } from 'jotai'
import { useState } from 'react'

import { logger } from '#/core/logger'
import { downloadQueueAtom, DownloadQueueItem } from '#/core/stores'

import { DownloadCard } from './card'
import styles from './section.module.scss'

export const DownloadIndicator: React.FC = () => {
  const downloadQueue = useAtomValue(downloadQueueAtom)

  const [isExpand, setIsExpand] = useState<boolean>(false)

  function collapse() {
    setIsExpand(false)
  }

  function onCardClick(_: number, game: DownloadQueueItem) {
    return function (event: React.MouseEvent<HTMLElement>) {
      event.stopPropagation()

      if (!isExpand) {
        setIsExpand(true)
      } else {
        logger.debug('Card click', game)
      }
    }
  }

  const displayItems = isExpand ? downloadQueue : downloadQueue.slice(0, 3)

  return (
    <div
      className={cx(styles.container, {
        [styles.visible]: downloadQueue.length > 0,
      })}
    >
      <div className={cx(styles.stack, { [styles.collapse]: !isExpand })}>
        {displayItems.map((game, index) => (
          <DownloadCard
            active={index === 0}
            game={game}
            className={cx(styles.card, {
              [styles.secondInStack]: !isExpand && index === 1,
              [styles.thirdInStack]: !isExpand && index === 2,
            })}
            key={game.slug}
            onClick={onCardClick(index, game)}
          />
        ))}
      </div>

      <div className={cx(styles.action, { [styles.visible]: isExpand })}>
        <Button
          className={styles.collapseButton}
          intent={Intent.Default}
          size={Size.Small}
          variant={ButtonVariant.Default}
          icon={ChevronDownIcon}
          text="Collapse"
          onClick={collapse}
        />
      </div>
    </div>
  )
}
