import { Button, Dialog, Intent, Typography } from '@axieinfinity/konan'
import Balancer from 'react-wrap-balancer'
import { toast } from 'sonner'
import { match, P } from 'ts-pattern'

import handlers from '#/core/handlers'
import { useCaptureEvent, useGameDialog } from '#/hooks'

import styles from './game.module.scss'

export const GameUninstallDialog: React.FC = () => {
  const { isVisible, resource, closeDialog } = useGameDialog()
  const captureEvent = useCaptureEvent()

  async function uninstallGame() {
    captureEvent('Click Confirm Uninstall Game', {
      game: {
        id: resource?.id,
        name: resource?.name,
        slug: resource?.slug,
      },
    })

    if (!resource) throw new Error('Error: Cannot get game resource')
    const result = await handlers.requestUninstallGame(resource.slug)
    if (result !== undefined) {
      match(result)
        .with(true, () => {
          closeDialog()
          toast(`${resource.name} is successfully uninstalled`)
        })
        .with(P.string, (message) => toast.error(message))
        .with(false, () => toast('Unexpected error, cannot uninstall game.'))
        .exhaustive()
    }
  }

  if (!resource) return null

  return (
    <Dialog size="medium" visible={isVisible('uninstall')} onClose={closeDialog}>
      {resource !== null && (
        <section className={styles.section}>
          <Typography.Heading level="5">Uninstall {resource.name}</Typography.Heading>

          <div className={styles.information}>
            <div className={styles.logo} style={{ backgroundImage: `url(${resource.logoUrl})` }} />
            <Typography.Text level="2">{resource.name}</Typography.Text>
          </div>

          <Typography.Text>
            <Balancer>
              Are you sure you want to uninstall this game? The game will remain in your Library. You will need to
              redownload its content if you play again in the future.
            </Balancer>
          </Typography.Text>

          <div className={styles.footer}>
            <Button className={styles.cancel} text="Cancel" onClick={closeDialog} />
            <Button
              className={styles.uninstall}
              intent={Intent.Danger}
              autoFocus
              text="Uninstall"
              onClick={uninstallGame}
            />
          </div>
        </section>
      )}
    </Dialog>
  )
}
