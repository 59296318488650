import { Button, Intent, Typography } from '@axieinfinity/konan'
import { GameDetails } from '@axieinfinity/hub-services'
import cx from 'classnames'

import styles from './vote.module.scss'

export const VoteDialogContent: React.FC<{
	type: 'upvote' | 'downvote'
	game: GameDetails
	shouldDisableVote: boolean
	isMobileViewPort?: boolean
	closeModal: () => void
	confirmVote: () => Promise<void>
}> = ({
	type,
	game,
	shouldDisableVote,
	isMobileViewPort,
	closeModal,
	confirmVote,
}) => (
		<div
			className={cx(styles.dialogNote, { [styles.desktop]: !isMobileViewPort })}
		>
			<Typography.Heading level="5" className={styles.heading}>
				Are you sure about your voting?
			</Typography.Heading>
			<Typography.Text>
				You are about to vote{' '}
				<span className={styles['text-' + type]}>
					“{type === 'upvote' ? 'YES' : 'NO'}”
				</span>{' '}
				for <strong>{game.name}</strong> by {game.organization?.name}!
			</Typography.Text>
			<Typography.Text>
				Please note that you cannot change your vote for the game after
				confirming.
			</Typography.Text>
			<div className={styles.actions}>
				<Button text="Cancel" disabled={shouldDisableVote} onClick={closeModal} />
				<Button
					intent={type === 'upvote' ? Intent.Primary : Intent.Danger}
					disabled={shouldDisableVote}
					text="Confirm Voting"
					onClick={async () => {
						await confirmVote()
						closeModal()
					}}
				/>
			</div>
		</div>
	)
