import './styles/app.scss'

import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Navigate, Route, Routes } from 'react-router'
import { BrowserRouter } from 'react-router-dom'

import { Layout } from './components/layouts/layout'
import { PlatformDetector } from './components/layouts/platform'
import { AboutScreen } from './components/screens/about'
import { AxieGameJam2023 } from './components/screens/axie-game-jam/2023'
import { AxieGameJam2023Submissions } from './components/screens/axie-game-jam/submissions'
import { AxieGameJamToS } from './components/screens/axie-game-jam/tos'
import { EventScreen } from './components/screens/event'
import { EventSubmissionsScreen } from './components/screens/event/submissions'
import { GameDetailsScreen } from './components/screens/game-details'
import { BrowseGamesScreen } from './components/screens/games'
import { GreenLightScreen } from './components/screens/greenlight'
import { HomeScreen } from './components/screens/home'
import { IdRedirectScreen } from './components/screens/id-redirect'
import { WrappedLibraryScreen } from './components/screens/library'
import { OfflineScreen } from './components/screens/offline'
import { AuthDialogs } from './components/views/auth/dialogs'
import { DevModeView } from './components/views/dev-mode/dev-mode'
import { CreateWalletDialog } from './components/views/dialogs/create-wallet'
import { GameDialogs } from './components/views/dialogs/dialogs'
import { OpenExternalDialog } from './components/views/dialogs/open-external'
import { DownloadIndicator } from './components/views/indicators/download'
import { ToastContainer } from './components/views/toast/toast'
import { Updater } from './components/views/updater/updater'
import { queryClient } from './core/react-query'
import { MossProvider } from './hooks'
import { isBrowsingOnDesktop, isBrowsingOnWeb } from './utils'

export const Application: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <HelmetProvider>
          <PlatformDetector>
            <MossProvider>
              <Helmet>
                <title>Mavis Hub</title>
              </Helmet>

              <Routes>
                <Route element={<Layout />}>
                  <Route path="/" element={<HomeScreen />} />
                  <Route path="axiejam2023" element={<Navigate to="/axie-game-jam/2023" replace />} />
                  <Route path="axie-game-jam">
                    <Route index element={<Navigate to="/axie-game-jam/terms" replace />} />
                    <Route path="2023">
                      <Route index element={<AxieGameJam2023 />} />
                      <Route path="submissions" element={<AxieGameJam2023Submissions />} />
                    </Route>
                    <Route path="terms" element={<AxieGameJamToS />} />
                  </Route>
                  <Route path="event/:slug">
                    <Route index element={<EventScreen />} />
                    <Route path="submissions" element={<EventSubmissionsScreen />} />
                  </Route>
                  <Route path="games/:slug" element={<GameDetailsScreen />} />
                  <Route path="games" element={<BrowseGamesScreen />} />
                  {isBrowsingOnDesktop && <Route path="library" element={<WrappedLibraryScreen />} />}
                  {isBrowsingOnWeb && <Route path="about" element={<AboutScreen />} />}
                  <Route path="greenlight" element={<GreenLightScreen />} />
                  <Route
                    path="*"
                    element={<Navigate to="/" replace />}
                  />
                </Route>
                <Route path="id/login/redirect" element={<IdRedirectScreen />} />
              </Routes>

              <AuthDialogs />

              <OpenExternalDialog />
              <CreateWalletDialog />
              <Updater />
              <GameDialogs />
              <DownloadIndicator />
              <ToastContainer />
              <OfflineScreen />
              {isBrowsingOnDesktop && <DevModeView />}
            </MossProvider>
          </PlatformDetector>
        </HelmetProvider>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
