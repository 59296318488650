
import { useMemoizedFn } from 'ahooks'
import { useRef } from 'react'
import { match, P } from 'ts-pattern'

import { Skeleton } from '#/components/common/loader'
import { useCaptureEvent } from '#/hooks'
import { useWidgetGames } from '#/hooks/query'

import { ObservableListItem, PaginationButtons, Section } from '../generic'
import { Card } from './card'
import styles from './section.module.scss'

export const MostPlayedGameSection: React.FC = () => {
  const captureEvent = useCaptureEvent()

  const listElementRef = useRef<HTMLUListElement>(null)

  const { isLoading, data } = useWidgetGames('most-played')

  const handlePaginationPress = useMemoizedFn((direction: 'previous' | 'next') => () => {
    captureEvent(`Click view ${direction} page`, { collection: 'Most Played Games' })

    if (!listElementRef.current) {
      return
    }

    const nodes = listElementRef.current.childNodes
    const lastItemIndex = nodes.length === 1 ? 0 : nodes.length - 1
    const inViewNodes = Array
      .from(nodes, (node, index) => ({ isInViewport: (node as HTMLLIElement).dataset.inViewport === 'true', index }))
      .filter((node) => node.isInViewport)

    if (inViewNodes.length === 0) {
      return
    }

    const nextElementIndex = match(direction)

      .with('previous', () => inViewNodes[0].index === 0 ? lastItemIndex : inViewNodes[0].index - 1)
      .with('next', () => inViewNodes[inViewNodes.length - 1].index === lastItemIndex ? 0 : inViewNodes[inViewNodes.length - 1].index + 1)
      .exhaustive()

    const element = nodes.item(nextElementIndex) as HTMLLIElement
    element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
  })

  return (
    <Section
      className={styles.section}
      heading="Most Played Games"
      pagination={<PaginationButtons isDisabled={isLoading} onPress={handlePaginationPress} />}
    >
      {match({ isLoading, data })
        .with({ isLoading: true }, () => (
          <ul data-slot="list">
            {Array.from({ length: 6 }, (_, index) => (
              <li key={index} data-slot="list-item">
                <Skeleton fill data-slot="loader" />
              </li>
            ))}
          </ul>
        ))
        .with(
          { data: P.nullish },
          { data: P.when((data) => data !== undefined && data.length === 0) },
          () => <div data-slot="placeholder" data-content="No data found" />)
        .otherwise(({ data }) => (
          <ul ref={listElementRef} data-slot="list">
            {data?.map((game) => (
              <ObservableListItem key={game.slug} containerRef={listElementRef} data-slot="list-item">
                <Card {...game} />
              </ObservableListItem>
            ))}
          </ul>
        ))}
    </Section>
  )
}
