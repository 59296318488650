import { GameInstallationDialog } from './installation'
import { GameManageDialog } from './manage'
import { GameUninstallDialog } from './uninstall'
import { GameUpdateDialog } from './update'

export const GameDialogs: React.FC = () => (
  <>
    <GameInstallationDialog />
    <GameUpdateDialog />
    <GameManageDialog />
    <GameUninstallDialog />
  </>
)
