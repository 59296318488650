export enum SupportedSymbol {
  RON = 'RON',
  AXS = 'AXS',
  SLP = 'SLP',
  WETH = 'WETH',
  USDC = 'USDC',
}

export type Token = {
  decimals: number
  symbol: SupportedSymbol
}

export type Web3Config = {
  tokens: Record<SupportedSymbol, Token>
}

export const web3Config: Web3Config = {
  tokens: {
    RON: {
      decimals: 18,
      symbol: SupportedSymbol.RON,
    },

    AXS: {
      decimals: 18,
      symbol: SupportedSymbol.AXS,
    },

    SLP: {
      decimals: 0,
      symbol: SupportedSymbol.SLP,
    },

    WETH: {
      decimals: 18,
      symbol: SupportedSymbol.WETH,
    },

    USDC: {
      decimals: 6,
      symbol: SupportedSymbol.USDC,
    },
  },
}

export const getTokenInstanceBySymbol = (symbol: SupportedSymbol) => {
  const upperCaseSymbol = symbol.toLocaleUpperCase() as SupportedSymbol

  if (Object.keys(SupportedSymbol).includes(upperCaseSymbol)) {
    return web3Config.tokens[upperCaseSymbol]
  }

  return web3Config.tokens.USDC
}
