import { Profile } from '@axieinfinity/hub-services'
import { useQuery } from '@tanstack/react-query'

import { logger } from '#/core/logger'
import { services } from '#/core/services'

import { useHandler } from '../handler'
import { useUserProfile } from './profile'

export const userLibraryQueryKey = (profile?: Profile['asProfile'] | null) => ['profile', profile?.userID ?? 'unknown']
export const useUserLibrary = () => {
	const { data: profile } = useUserProfile()
	const { handleGetAccessToken } = useHandler()

	return useQuery({
		queryKey: userLibraryQueryKey(profile),
		queryFn: async () => {
			if (!profile) return []

			const accessToken = await handleGetAccessToken()
			if (!accessToken) return []

			try {
				const { response, error, result } = await services.request('get /v2/users/games')
				if (response.status >= 500 || error) {
					return []
				} else {
					return result.data
				}
			} catch (error) {
				logger.error(error, { error }, 'useUserLibrary')

				return []
			}
		},
	})
}
