import { GameDetails } from '@axieinfinity/hub-services'
import { useMemoizedFn } from 'ahooks'
import { useMemo } from 'react'
import { useNavigate } from 'react-router'

import styles from './card.module.scss'

type Props = GameDetails

export const Card: React.FC<Props> = ({ slug, thumbnailUrl, name, genres }) => {
  const navigate = useNavigate()

  const handlePress = useMemoizedFn(() => {
    navigate(`/games/${slug}`)
  })

  const gameGenre = useMemo(() => {
    if (genres === undefined) {
      return null
    }

    let str = genres.slice(0, 3).map((genre) => genre.name).join(', ')

    if (genres.length > 3) {
      str = str + ',...'
    }

    return str
  }, [genres])

  return (
    <figure className={styles.figure} tabIndex={0} onClick={handlePress}>
      <img src={thumbnailUrl} data-slot="image" />

      <figcaption data-slot="caption">
        <div data-slot="heading">{name}</div>
        <div data-slot="description">{gameGenre}</div>
      </figcaption>
    </figure>
  )
}
