import {
  ChevronDownIcon,
  Filter01Icon,
  Remove01Icon,
} from '@axieinfinity/dango-icons'
import { PartnershipTypeEnum, SupportPlatformOs } from '@axieinfinity/hub-services'
import {
  Accordion,
  Button,
  Checkbox,
  Loader,
  Typography,
} from '@axieinfinity/konan'
import { Fragment, useState } from 'react'

import { useGameFilter } from '#/hooks'
import { useGameGenres } from '#/hooks/query'
import { formatPartnershipType, formatPlatformOs, randomInt } from '#/utils'

import styles from './filter.module.scss'

export enum GameFilterCategory {
  Partnership = 'partnershipType',
  Genre = 'genre',
  Platform = 'platform'
}

function formatCategoryName(input: GameFilterCategory) {
  switch (input) {
    case GameFilterCategory.Partnership:
      return 'PARTNERSHIP'
    case GameFilterCategory.Genre:
      return 'GAME GENRE'
    default:
      return 'PLATFORM'
  }
}

const availablePlatformOS: SupportPlatformOs[] = [
  'windows',
  'macos',
  'android',
  'android-google-play',
  'ios',
  'web',
]

export const FilterColumn: React.FC<{
  filters: GameFilterCategory[]
}> = ({ filters }) => {
  const [showFilters, setShowFilters] = useState(false)
  const showFilterList = () => setShowFilters(true)
  const hideFilterList = () => setShowFilters(false)

  const { data: genres, isLoading: isGenresLoading } = useGameGenres()
  const { filters: selected, handleFilterChange } = useGameFilter()

  const filterCounts =
    selected.partnershipType?.length +
    selected.genre?.length +
    selected.platform?.length ?? 0

  return (
    <Fragment>
      <div className={styles.toggleButton} onClick={showFilterList}>
        <Button text={`Filters (${filterCounts})`} icon={Filter01Icon} />
      </div>
      <div className={styles.filters} data-display={showFilters}>
        <Typography.Heading level="5" className={styles.heading}>
          Filters
          <div className={styles.closeFilters} onClick={hideFilterList}>
            <Remove01Icon />
          </div>
        </Typography.Heading>
        <Accordion.Root
          type="multiple"
          defaultValue={filters}
          className={styles.accordion}
        >
          {/* Partnership */}
          {filters.includes(GameFilterCategory.Partnership) && (
            <Accordion.Item
              value={GameFilterCategory.Partnership}
              className={styles.item}
            >
              <Accordion.Header className={styles.header}>
                <Accordion.Trigger className={styles.trigger}>
                  {formatCategoryName(GameFilterCategory.Partnership)}
                  <ChevronDownIcon className={styles.chevron} />
                </Accordion.Trigger>
              </Accordion.Header>
              <Accordion.Content className={styles.content}>
                <div className={styles.checkboxes}>
                  {Object.values(PartnershipTypeEnum).map(type => (
                    <Checkbox
                      label={formatPartnershipType(type)}
                      key={type}
                      value={type}
                      checked={selected.partnershipType?.includes(type)}
                      onChange={e =>
                        handleFilterChange(
                          GameFilterCategory.Partnership,
                          type,
                          e.currentTarget.checked
                        )}
                    />
                  ))}
                </div>
              </Accordion.Content>
            </Accordion.Item>
          )}
          {/* Genre */}
          {filters.includes(GameFilterCategory.Genre) && (
            <Accordion.Item
              value={GameFilterCategory.Genre}
              className={styles.item}
            >
              <Accordion.Header className={styles.header}>
                <Accordion.Trigger className={styles.trigger}>
                  {formatCategoryName(GameFilterCategory.Genre)}
                  <ChevronDownIcon className={styles.chevron} />
                </Accordion.Trigger>
              </Accordion.Header>
              <Accordion.Content className={styles.content}>
                {isGenresLoading ? (
                  <div className={styles.loaderContainer}>
                    {Array.from({ length: 5 }, (_, k) => (
                      <div
                        className={styles.loaderWrapper}
                        key={k}
                      >
                        <Loader.Skeleton
                          containerClassName={styles.loader}
                          width={20}
                          height={20}
                        />
                        <Loader.Skeleton
                          containerClassName={styles.loader}
                          width={randomInt(50, 200)}
                          height={20}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className={styles.checkboxes}>
                    {genres?.map(({ slug, name }) => (
                      <Checkbox
                        label={name}
                        value={slug}
                        key={slug}
                        checked={selected.genre?.includes(slug)}
                        onChange={e =>
                          handleFilterChange(
                            GameFilterCategory.Genre,
                            slug,
                            e.currentTarget.checked
                          )}
                      />
                    ))}
                  </div>
                )}
              </Accordion.Content>
            </Accordion.Item>
          )}
          {/* Platform */}
          {filters.includes(GameFilterCategory.Platform) && (
            <Accordion.Item
              value={GameFilterCategory.Platform}
              className={styles.item}
            >
              <Accordion.Header className={styles.header}>
                <Accordion.Trigger className={styles.trigger}>
                  {formatCategoryName(GameFilterCategory.Platform)}
                  <ChevronDownIcon className={styles.chevron} />
                </Accordion.Trigger>
              </Accordion.Header>
              <Accordion.Content className={styles.content}>
                <div className={styles.checkboxes}>
                  {availablePlatformOS.map(os => (
                    <Checkbox
                      label={formatPlatformOs(os)}
                      value={os}
                      key={os}
                      checked={selected.platform?.includes(os)}
                      onChange={e =>
                        handleFilterChange(
                          GameFilterCategory.Platform,
                          os,
                          e.currentTarget.checked
                        )}
                    />
                  ))}
                </div>
              </Accordion.Content>
            </Accordion.Item>
          )}
        </Accordion.Root>
      </div>
    </Fragment>
  )
}
