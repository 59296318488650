import { useCaptureEvent } from '#/hooks'
import { latestBuild } from '#/utils'

import { NavigationSection } from './navigation'
import styles from './section.module.scss'
import { SocialLinkSection } from './social-link'
import { SubscriberSection } from './subscriber'

const footerNavigationItems = [
  { label: 'Terms of Use', href: 'https://cdn.skymavis.com/mavis-hub/terms-of-use.pdf' },
  { label: 'Privacy Policy', href: 'https://cdn.skymavis.com/files/terms-of-use.pdf' },
]

export const Footer: React.FC = () => {
  const captureEvent = useCaptureEvent()

  return (
    <footer className={styles.footer}>
      <div className={styles.main}>
        <div data-slot="subscriber">
          <SubscriberSection />
        </div>

        <div data-slot="navigation">
          <NavigationSection />
        </div>

        <div data-slot="social-link">
          <SocialLinkSection />
        </div>
      </div>

      <div className={styles.sub}>
        <div className={styles.copyright} title={latestBuild}>
          {`© ${new Date().getFullYear()} Sky Mavis. All rights reserved. All Games and trademarks are the property of their respective owners.`}
        </div>

        <nav className={styles.navigation}>
          {footerNavigationItems.map(({ label, href }, index) => (
            <a className={styles.item} key={index} href={href} rel="noopener noreferrer" target="_blank" onClick={() => captureEvent('Click ' + label + ' on Footer')}>{label}</a>
          ))}
        </nav>
      </div>
    </footer>
  )
}
