import { Button, ButtonVariant } from '@axieinfinity/konan'
import { GameDetails } from '@axieinfinity/hub-services'
import { Fragment } from 'react'

import { GameGrid } from '#/components/views/game'
import { useCaptureEvent, useGameFilter, usePaginationQuery } from '#/hooks'
import { useEventGames } from '#/hooks/query'

import styles from './list.module.scss'

export const EventGameList: React.FC<{ slug: string }> = ({ slug }) => {
	const captureEvent = useCaptureEvent()

	const { filters } = useGameFilter()
	const { page, setPage, filterBy, setFilterBy } = usePaginationQuery()
	const gameFilters = { ...filters, filterBy }

	const { isLoading, error, data } = useEventGames(slug, { page, pageSize: 18 }, gameFilters)

	function onClickGameCard(game: GameDetails) {
		const { slug, organization, name, id, genres } = game
		captureEvent('Click Game Card on Event ' + slug, {
			game: { id, name, slug, genres: genres?.map(g => g.slug) },
			game_org: {
				name: organization?.name,
				slug: organization?.slug,
			},
		})
	}

	return (
		<Fragment>
			<div className={styles.filter}>
				<Button
					text="Newest"
					variant={
						filterBy === undefined || filterBy === 'newest'
							? ButtonVariant.Default
							: ButtonVariant.Plain
					}
					onClick={() => {
						setFilterBy('newest')
						setPage(1)
					}}
				/>
				<Button
					text="Top rated"
					variant={
						filterBy === 'top_rate'
							? ButtonVariant.Default
							: ButtonVariant.Plain
					}
					onClick={() => {
						setFilterBy('top_rate')
						setPage(1)
					}}
				/>
			</div>
			<GameGrid
				{...{
					isLoading,
					error,
					emptyImage: '/assets/buba-empty-result.png',
					data: data?.data.map(details => details.game),
					onClickGameCard,
					pagination: data?.pagination,
				}}
			/>
		</Fragment>
	)
}
