import { HelpCircle01Icon } from '@axieinfinity/dango-icons'
import type { CollectibleType, GameDetails, GameGenreResponse } from '@axieinfinity/hub-services'
import { Tag } from '@axieinfinity/konan'
import { globalConfig } from '@mavishub/config'
import { useMemoizedFn } from 'ahooks'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { match, P } from 'ts-pattern'

import { Tooltip } from '#/components/common/tooltip'
import { platformSymbolMapper } from '#/constants'
import { useCaptureEvent } from '#/hooks'
import {
  convertBytes,
  formatPlatformOs,
  getCurrentOSResource,
  serializeUrlSearchParams,
  userOs,
} from '#/utils'

import { GameFilterCategory } from '../filters'
import styles from './detail.module.scss'

function getCollectibleExternalLink(type: CollectibleType, address: string) {
  switch (type) {
    case 'ERC20':
      return `${globalConfig.explorerEndpoint}/token/${address}`
    case 'ERC721':
    case 'ERC1155': {
      const pathFound: string = globalConfig.marketplaceAssetPath[address as `0x${string}`]
      if (pathFound !== undefined) {
        return `${globalConfig.marketplaceEndpoint}/${pathFound}`
      } else {
        return ''
      }
    }
    default:
      return ''
  }
}

type Row = { label: string; value: React.ReactNode }
type Props = GameDetails

export const GameDetail: React.FC<Props> = (game) => {
  const captureEvent = useCaptureEvent()

  const rows = useMemo(() => {
    const result: Row[] = [
      {
        label: 'platform',
        value: (
          <div className={styles.platforms}>
            {game.platforms?.map(platform => {
              const Icon = platformSymbolMapper[platform.os]

              return Icon && <Tooltip key={platform.os} content={formatPlatformOs(platform.os)}>
                <div><Icon key={platform.os} size={20} /></div>
              </Tooltip>
            })}
          </div>
        ),
      },
      { label: 'developed by', value: game.organization?.name ?? '' },
    ]

    if (userOs === 'windows' || userOs === 'macos') {
      const resourceFound = getCurrentOSResource(game.latestVersion?.resources)

      if (resourceFound?.size) {
        result.push({ label: 'size', value: convertBytes(resourceFound.size) })
      }
    }

    return result
  }, [game.organization?.name, game.platforms, game.latestVersion?.resources])

  const handleGameTagClick = useMemoizedFn((genre: GameGenreResponse) => () => {
    const { id, name, slug } = game
    captureEvent('Click Game Details\'s Tag', {
      game: { id, name, slug },
      tag: genre.slug,
    })
  })

  const handleCollectibleClick = useMemoizedFn((type: string, address: string) => () => {
    const { id, name, slug } = game
    const symbol = game.collectibles?.find(c => c.address === address)?.symbol
    captureEvent('Click Game Details\'s Collectible', {
      game: { id, name, slug },
      collectible: { address, type, symbol },
    })
  })

  const genreUrlPrefix = match(game)
    .with(
      { event: { code: 'agj-2023' } },
      () => '/axie-game-jam/2023/submissions'
    )
    .with(
      { metaData: P.when((v) => v?.includes('early-testing')) },
      () => '/greenlight'
    )
    .otherwise(() => '/games')

  const hasCollectibleSection = game.collectibles !== undefined && game.collectibles.length > 0

  return (
    <div className={styles.container}>
      <div className={styles.header}>Details</div>

      <div className={styles.tags}>
        {game.genres?.map((genre) => (
          <Link
            key={genre.slug}
            to={`${genreUrlPrefix}?${serializeUrlSearchParams({
              [GameFilterCategory.Genre]: [genre.slug],
            })}`}
            onClick={handleGameTagClick(genre)}
          >
            <Tag className={styles.tag} interactive>
              {genre.name}
            </Tag>
          </Link>
        ))}
      </div>

      {rows.map(({ label, value }, index) => (
        <div className={styles.row} key={index}>
          <div className={styles.label}>{label}</div>
          <span className={styles.content}>{value}</span>
        </div>
      ))}

      {hasCollectibleSection && (
        <>
          <div className={styles.separator} />

          <div className={styles.collectible}>
            <div className={styles.label}>
              <div className={styles.text}>collectibles</div>
              <Tooltip content="These are the collectibles that have utility in this game.">
                <div className={styles.suffix}>
                  <HelpCircle01Icon size={20} />
                </div>
              </Tooltip>
            </div>

            <div className={styles.list}>
              {game.collectibles?.map(({ address, type, logoUrl }) => (
                <a
                  key={address}
                  href={getCollectibleExternalLink(type, address)}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleCollectibleClick(type, address)}
                >
                  <div className={styles.token}>
                    <img src={logoUrl} />
                  </div>
                </a>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
