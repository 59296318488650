import { ResponseStatusCode } from '@axieinfinity/hub-services'
import { Intent, Typography } from '@axieinfinity/konan'
import cx from 'classnames'
import { useAtomValue } from 'jotai'
import { CodeInput, getSegmentCssWidth } from 'rci'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'sonner'

import { errorMessages } from '#/constants/error'
import { clearTokens } from '#/core/browser/request'
import { persistStorage } from '#/core/local-storage'
import { services } from '#/core/services'
import { twoFactorTokenAtom } from '#/core/stores'
import { useHandler } from '#/hooks'
import { useCaptureEvent } from '#/hooks/tracking'

import styles from './input.module.scss'

const CODE_LENGTH = 6

type InputState = 'input' | 'loading' | 'error' | 'success'

export type Props = {
	isVisible: boolean
	onSuccess?: () => void
	onExpired?: () => void
}

export const TwoFactorCodeInput: React.FC<Props> = ({ isVisible, onSuccess, onExpired }) => {
	const captureEvent = useCaptureEvent()
	const { handleLogin } = useHandler()

	const twoFactorInfo = useAtomValue(twoFactorTokenAtom)

	const [state, setState] = useState<InputState>('input')
	const [error, setError] = useState<string>('')
	const inputRef = useRef<HTMLInputElement>(null)

	function handleInputChange({ target: input }: React.ChangeEvent<HTMLInputElement>) {
		input.value = input.value.replace(/\D+/g, '')

		if (state === 'error') setState('input')
		if (error) setError('')
		if (input.value.length === CODE_LENGTH) handleSubmit(input)
	}

	function focusInput(input?: HTMLInputElement | null) {
		const target = input ? input : inputRef.current
		target !== null && setTimeout(() => target.focus(), 500)
	}

	function resetInput(input?: HTMLInputElement | null) {
		const target = input ? input : inputRef.current
		target !== null && (target.value = '')
	}

	async function handleSubmit(input: HTMLInputElement) {
		if (!twoFactorInfo?.mfaToken) return

		let err = null
		try {
			setState('loading')
			clearTokens()
			const { response, error, result } = await services.request('post /v2/auth/2fa/verify', {
				headers: { Authorization: `Bearer ${twoFactorInfo.mfaToken}` },
				body: { code: input.value },
			})
			if (response.status >= 500) {
				err = errorMessages.serverBusy
				setState('input')
				setError(err)
				resetInput(input)
			} else if (error) {
				if (error.code === ResponseStatusCode.InvalidAccessToken) {
					err = 'Session expired'
					toast.error(err)
					onExpired?.()
				} else {
					err = error.message
					setState('error')
					setError(err)
					resetInput(input)
					focusInput(input)
				}
			} else {
				await handleLogin(result, persistStorage.getIsSession())
				setState('success')
				onSuccess?.()
			}
		} catch (error) {
			err = error instanceof Error ? error.message : 'unexpected_error'
			setState('error')
			setError(error instanceof Error ? error.message : 'An unexpected error occurred, please try again later')
		} finally {
			captureEvent('Verify 2FA', { err })
		}
	}

	useEffect(() => {
		if (isVisible) focusInput()
		else {
			setTimeout(() => {
				setState('input')
				setError('')
				resetInput()
			}, 500)
		}
	}, [isVisible])

	return (
		<>
			<CodeInput
				className={cx(styles.codeInput, styles[state])}
				spacing="8px"
				paddingX="16px"
				paddingY="12px"
				fontSize="20px"
				length={CODE_LENGTH}
				disabled={state === 'loading'}
				inputRef={inputRef}
				spellCheck={false}
				inputMode="numeric"
				pattern="[0-9]*"
				autoComplete="one-time-code"
				renderSegment={({ state }) => (
					<div
						key={crypto.randomUUID()}
						className={styles.segment}
						style={{ width: getSegmentCssWidth('16px') }}
						data-state={state}
					/>
				)}
				onChange={handleInputChange}
			/>

			{error && (
				<Typography.Text level="3" intent={Intent.Danger}>
					{error}
				</Typography.Text>
			)}
		</>
	)
}
