import { KataruInvokeFnOnWeb } from '@axieinfinity/kataru'

export const handleOverlayLogin: KataruInvokeFnOnWeb<'app:overlay_login'> = () =>
  window.bridge?.kataru.invoke('app:overlay_login')

export const handleOverlayLogout: KataruInvokeFnOnWeb<'app:overlay_logout'> = () =>
  window.bridge?.kataru.invoke('app:overlay_logout')

export const handleOverlayCreateWallet: KataruInvokeFnOnWeb<'app:overlay_create_wallet'> = () =>
  window.bridge?.kataru.invoke('app:overlay_create_wallet')
