import { CheckCircleIcon, HourglassEndIcon, WarningErrorIcon } from '@axieinfinity/dango-icons'
import { Intent, Typography } from '@axieinfinity/konan'

import { CircularProgress } from '#/components/common/loader/circular-progress'

import { LoginState } from './mobile-qr'
import styles from './qr-overlay.module.scss'

const OverlayResult: Partial<Record<LoginState, Record<'icon' | 'text', React.ReactNode>>> = {
  wait_for_confirm: {
    icon: <CircularProgress intent={Intent.Primary} value="indeterminate" />,
    text: 'Confirm on your app',
  },
  error: {
    icon: <WarningErrorIcon className={styles.warning} size={48} />,
    text: 'Something wrong. Please close this dialog and try again',
  },
  logged_in: {
    icon: <CheckCircleIcon className={styles.success} size={48} />,
    text: 'Logging in...',
  },
  expired: {
    icon: <HourglassEndIcon size={48} />,
    text: 'QR expired',
  },
}

type Props = {
  state?: LoginState
}

export const QROverlay: React.FC<Props> = ({ state }) => {
  if (!state) return null

  const overlayItem = OverlayResult[state]
  if (!overlayItem) return null

  return (
    <div className={styles.overlay}>
      <div className={styles.content}>
        <Typography.Text tag="span" level="3">
          {overlayItem.icon}
          <br />
          {overlayItem.text}
        </Typography.Text>
      </div>
    </div>
  )
}
