import { useSetAtom } from 'jotai'
import { useEffect } from 'react'

import handlers from '#/core/handlers'
import { persistStorage } from '#/core/local-storage'
import { appVersionAtom, downloadProgressAtom, downloadQueueAtom, installedGamesAtom, playingGamesAtom, recentlyPlayedAtom } from '#/core/stores'
import { isBrowsingOnDesktop } from '#/utils'

import { useAuthListener } from './auth'
import { useDesktopDeeplinkListener } from './deeplink'

export const useDesktopGlobalListener = () => {
  const setInstalledGames = useSetAtom(installedGamesAtom)
  const setDownloadQueue = useSetAtom(downloadQueueAtom)
  const setPlayingGames = useSetAtom(playingGamesAtom)
  const setRecentlyPlayed = useSetAtom(recentlyPlayedAtom)
  const setProgress = useSetAtom(downloadProgressAtom)
  const setAppVersion = useSetAtom(appVersionAtom)

  useAuthListener()

  useEffect(() => {
    handlers.getAppVersion()?.then((version) => {
      if (isBrowsingOnDesktop) persistStorage.setAppVersion(version)
      setAppVersion(version)
    })
    handlers.getInstalledGames()?.then(setInstalledGames)
    handlers.getInstallingQueue()?.then(setDownloadQueue)
    handlers.getRecentlyPlayed()?.then(setRecentlyPlayed)

    const unsubscribe = {
      installed: window.bridge?.kataru.on('game:installed', (_, value) => setInstalledGames(value)),
      queue: window.bridge?.kataru.on('game:install_queue', (_, value) => setDownloadQueue(value)),
      progress: window.bridge?.kataru.on('game:install_progress', (_, value) => setProgress(value)),
    }

    return () => {
      unsubscribe.queue?.()
      unsubscribe.installed?.()
      unsubscribe.progress?.()
    }
  }, [setInstalledGames, setDownloadQueue, setProgress, setAppVersion, setRecentlyPlayed])

  /* Playing Status */
  useEffect(() => {
    const unsubscribe = window.bridge?.kataru.on('game:status', (_, { slug, status }) => {
      if (status === 'playing') {
        setPlayingGames((prev) => {
          const next = new Set(prev)
          next.add(slug)

          return next
        })

        handlers.getRecentlyPlayed()?.then(setRecentlyPlayed)

        return
      }
      if (status === 'exiting') {
        setPlayingGames((prev) => {
          const next = new Set(prev)
          next.delete(slug)

          return next
        })
      }
    })

    return () => void unsubscribe?.()
  }, [setPlayingGames, setRecentlyPlayed])

  useDesktopDeeplinkListener()
}
