import { useEffect } from 'react'
import { useNavigate } from 'react-router'

import { useLoginDialog } from '#/hooks'
import { useUserProfile } from '#/hooks/query'

import { LoginDialog } from './login/dialog'
import { TwoFactorDialog } from './two-factor/dialog'

export const AuthDialogs: React.FC = () => {
  const navigate = useNavigate()
  const { redirect, closeLoginDialog } = useLoginDialog()

  const { data: profile } = useUserProfile()

  useEffect(() => {
    if (!profile) return
    if (redirect) navigate(redirect)
    closeLoginDialog()
  }, [profile, redirect, navigate, closeLoginDialog])

  return (
    <>
      <LoginDialog />
      <TwoFactorDialog />
    </>
  )
}
