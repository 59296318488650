import { PartnershipTypeEnum, SupportPlatformOs } from '@axieinfinity/hub-services'
import queryString from 'query-string'
import { match } from 'ts-pattern'

export function truncateAddress(input: string) {
	return `${input.slice(0, 6)}...${input.slice(-6)}`
}

export function formatPlatformOs(input?: SupportPlatformOs) {
	return match(input)
		.with('windows', () => 'Windows')
		.with('macos', () => 'MacOS')
		.with('android', () => 'Android APK')
		.with('android-google-play', () => 'Android')
		.with('ios', () => 'iOS')
		.with('web', () => 'Web')
		.otherwise(input => input)
}

export function formatPartnershipType(input?: PartnershipTypeEnum) {
	return match(input)
		.with(PartnershipTypeEnum.SkyMavisGame, () => 'Sky Mavis Games')
		.with(PartnershipTypeEnum.AxieBuilderProgram, () => 'Axie Builder Program')
		.with(PartnershipTypeEnum.PartnerStudio, () => 'Studio Partnership')
		.with(PartnershipTypeEnum.CommunityDeveloper, () => 'Community Developer')
		.otherwise(input => input)
}

export function serializeUrlSearchParams(init: Record<string, unknown>) {
	return queryString.stringify(init, { arrayFormat: 'comma' })
}

export function serializeNavigateParams({
	from,
	name,
	filters,
}: {
	from: string
	name: string
	filters: Record<string, unknown>
}) {
	const navigationParams = new URLSearchParams({
		from,
		name,
	})

	const stringifyFilters = queryString.stringify(filters, { arrayFormat: 'comma' })
	navigationParams.append('filter', encodeURIComponent(stringifyFilters))

	return navigationParams
}
