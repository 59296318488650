import {
	EventGamesPayload,
	getSearchParams,
	PaginationInput,
} from '@axieinfinity/hub-services'
import { useQuery } from '@tanstack/react-query'
import { toast } from 'sonner'

import { errorMessages } from '#/constants'
import { services } from '#/core/services'

export const eventGamesQueryKeys = {
	all: ['event_games'] as const,
	event: (event: string) => [...eventGamesQueryKeys.all, event] as const,
	filter: (
		event: string,
		pagination: PaginationInput,
		filters: EventGamesPayload
	) => [...eventGamesQueryKeys.event(event), pagination, filters] as const,
}

const defaultResponse = {
	data: [],
	pagination: {
		lastPage: 1,
		totalRows: 0,
		desc: false,
		pageSize: 18,
		page: 1,
	},
}

export const useEventGames = (
	event: string,
	pagination: PaginationInput,
	filters: EventGamesPayload
) => {
	return useQuery({
		queryKey: eventGamesQueryKeys.filter(event, pagination, filters),
		queryFn: async () => {
			try {
				const payload = { ...filters, filterBy: filters.filterBy || 'newest' }
				const { response, error, result } = await services.request(
					'post /v2/public/game-events/[event]/games?[query]',
					{
						event,
						query: getSearchParams(pagination).toString(),
						body: payload,
					}
				)

				if (response.status >= 500) {
					toast.error(errorMessages.serverBusy)

					return defaultResponse
				} else if (error) {
					return defaultResponse
				} else {
					return result
				}
			} catch (_) {
				return defaultResponse
			}
		},
	})
}
