import { Button, Intent } from '@axieinfinity/konan'
import { Fragment, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { CircularProgress } from '#/components/common/loader'
import { useGameDetails, useUserLibrary } from '#/hooks/query'

import { PatchNotes } from '../patchnote'
import { LibraryGameAction } from './actions'
import styles from './detail.module.scss'

type Props = {
  selected: string
}

export const LibraryDetail: React.FC<Props> = ({ selected }) => {
  const { data: game, isLoading } = useGameDetails(selected)
  const { data: library } = useUserLibrary()

  const backgroundStyle = useMemo(() => {
    const backgroundImage = game?.gallery?.find(
      media => media.type === 'image'
    )?.src
    if (backgroundImage) {
      return {
        '--background-image': `url(${backgroundImage})`,
      } as CustomCSSProperties
    }

    return undefined
  }, [game?.gallery])

  if (!game) {
    if (isLoading) {
      return (
        <div className={styles.spinner}>
          <CircularProgress value="indeterminate" />
        </div>
      )
    }

    return null
  }

  return (
    <div className={styles.detail}>
      {library?.length === 0 ? (
        <div className={styles.empty}>
          <div className={styles.emptyContent}>
            <img src="/assets/axie-gotcha.svg" />
            <div>
              <div className={styles.name}>Library is empty!</div>
              <div className={styles.description}>No game has been added to your Library yet.</div>
              <Link to="/">
                <Button text="Explore Games" intent={Intent.Primary} />
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <Fragment>
          <div className={styles.background} style={backgroundStyle} />
          <div className={styles.container}>
            <header className={styles.header} style={backgroundStyle}>
              <h3 className={styles.title}>{game.name}</h3>
              {game.genres && (
                <div className={styles.genres}>
                  {game.genres?.map(genre => (
                    <span className={styles.genre} key={genre.slug}>
                      {genre.name}
                    </span>
                  ))}
                </div>
              )}
              <LibraryGameAction {...game} />
            </header>
          </div>
          <div className={styles.main}>
            <div className={styles.activities}>
              <h4 className={styles.heading}>Patch Notes</h4>
              <PatchNotes game={selected} pagination={{ pageSize: 10 }} />
            </div>
          </div>
        </Fragment>
      )}
    </div>
  )
}
