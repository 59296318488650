import cx from 'classnames'
import { useEffect } from 'react'
import { toast, Toaster } from 'sonner'
import { match, P } from 'ts-pattern'

import { isBrowsingOnDesktop } from '#/utils'

import styles from './toast.module.scss'

export const ToastContainer: React.FC = () => {
  useEffect(() => {
    const unsubscribe = window.bridge?.kataru.on('app:error', (_, e) => {
      match(e)
        .with(P.string, (e) => toast.error(e))
        .otherwise((e) => {
          switch (e.type) {
            case 'app':
            case 'internal_server':
              return toast.error(e.message)
            default:
          }
        })
    })

    return () => void unsubscribe?.()
  }, [])

  return <Toaster closeButton richColors theme="dark" className={cx(styles.toast, { [styles.desktop]: isBrowsingOnDesktop })} position="top-right" />
}
