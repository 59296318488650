import { useAtom } from 'jotai'
import { useCallback } from 'react'

import { loginDialogAtom } from '#/core/stores'

export const useLoginDialog = () => {
  const [{ isVisible, redirect }, setLoginDialog] = useAtom(loginDialogAtom)
  const openLoginDialog = useCallback(() => setLoginDialog((prev) => ({ ...prev, isVisible: true })), [setLoginDialog])
  const closeLoginDialog = useCallback(
    (clearRedirect = true) =>
      setLoginDialog((prev) => (clearRedirect ? { isVisible: false } : { ...prev, isVisible: false })),
    [setLoginDialog]
  )

  return {
    isVisible,
    redirect,
    openLoginDialog,
    closeLoginDialog,
    setLoginDialog,
  }
}
