import { Login01Icon } from '@axieinfinity/dango-icons'
import cx from 'classnames'
import { useSetAtom } from 'jotai'

import { Skeleton } from '#/components/common/loader'
import { loginDialogAtom, mobileMenuAtom } from '#/core/stores'
import { useCaptureEvent } from '#/hooks'
import { useUserProfile } from '#/hooks/query'

import { ProfileInfo } from '../profile-info'
import styles from './profile.module.scss'
import sharedStyles from './shared.module.scss'

export const Profile: React.FC = () => {
  const captureEvent = useCaptureEvent('mobile-menu|profile')
  const { data: profile } = useUserProfile()

  const setLoginDialog = useSetAtom(loginDialogAtom)
  const setMobileMenu = useSetAtom(mobileMenuAtom)

  if (profile === undefined) {
    return (
      <div className={styles.loader}>
        <Skeleton width={40} corner="rounded-full" />
        <Skeleton width={160} height={40} corner="rounded" />
        <Skeleton size={40} />
      </div>
    )
  }

  function openLogInDialog() {
    captureEvent('Click Login')
    setMobileMenu({ isVisible: false })
    setLoginDialog({ isVisible: true })
  }

  if (profile === null) {
    return (
      <button className={cx(sharedStyles.button, sharedStyles.block)} onClick={openLogInDialog}>
        <Login01Icon size={20} />
        Log in
      </button>
    )
  }

return (
    <div className={styles.box}>
      <ProfileInfo {...profile} />
    </div>
  )
}
