import { DiscordIcon, Facebook01Icon } from '@axieinfinity/dango-icons'
import { TwitterXIcon } from '@axieinfinity/konan'
import { match, P } from 'ts-pattern'

import styles from './social-link.module.scss'

const linkList = [
  { label: DiscordIcon, href: 'https://discord.com/invite/axie' },
  { label: TwitterXIcon, href: 'https://twitter.com/AxieInfinity' },
  { label: Facebook01Icon, href: 'https://www.facebook.com/AxieInfinity' },
] as const

export const SocialLinkSection: React.FC = () => {
  return (
    <section className={styles.section}>
      <div data-slot="header">JOIN US</div>
      <ul data-slot="list">
        {linkList.map(({ href, label }, index) => (
          <li data-slot="list-item" key={index}>
            <a data-slot="anchor" href={href} target="_blank" rel="noreferrer">
              {match(label)
                .with(P.not(P.nullish), (Element) => <Element size={24} width={24} height={24} />)
                .otherwise(() => null)}
            </a>
          </li>
        ))}
      </ul>
    </section>
  )
}
