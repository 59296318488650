import { useMemoizedFn } from 'ahooks'
import { useSearchParams } from 'react-router-dom'

import { GameFilterCategory } from '#/components/views/filters'

const parseSearchParam = (
  value: string | null,
  category?: GameFilterCategory
) => {
  let result = value?.split(',') ?? []

  // Migrate old URL state:
  // https://hub.skymavis.com/games?partnershipType=["sky-mavis"]&platform=["android","ios"]
  if (
    typeof value === 'string' &&
    value.startsWith('[') &&
    value.endsWith(']')
  ) {
    try {
      result = JSON.parse(value)

      return result
    } catch (e) {
      result = []

      return result
    } finally {
      if (category) {
        const newRelativePathQuery = new URL(window.location.href)
        newRelativePathQuery.searchParams.set(category, result.join(','))
        history.replaceState(null, '', newRelativePathQuery)
      }
    }
  }

  return result
}

type GameFilters = Record<GameFilterCategory, Array<string>>
export const useGameFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const filters: GameFilters = {
    [GameFilterCategory.Partnership]: parseSearchParam(
      searchParams.get(GameFilterCategory.Partnership),
      GameFilterCategory.Partnership
    ),
    [GameFilterCategory.Genre]: parseSearchParam(
      searchParams.get(GameFilterCategory.Genre),
      GameFilterCategory.Genre
    ),
    [GameFilterCategory.Platform]: parseSearchParam(
      searchParams.get(GameFilterCategory.Platform),
      GameFilterCategory.Platform
    ),
  }

  const handleFilterChange = useMemoizedFn(
    (category: GameFilterCategory, value: string, checked: boolean) => {
      setSearchParams(
        original => {
          const previous = parseSearchParam(original.get(category)) ?? []
          if (checked === false && previous.includes(value)) {
            const index = previous.indexOf(value)
            previous.splice(index, 1)
          } else if (checked === true && !previous.includes(value)) {
            previous.push(value)
          }

          if (previous.length) {
            original.set(category, previous.join(','))
          } else {
            original.delete(category)
          }

          return original
        },
        { replace: true }
      )
    }
  )

  return {
    partnership: filters.partnershipType,
    genre: filters.genre,
    platform: filters.platform,
    filters,
    handleFilterChange,
  }
}
