import { Button, Intent, useResponsive } from '@axieinfinity/konan'
import { COOKIE_NAME } from '@axieinfinity/kukki'
import config from '@mavishub/config'
import customProtocolCheck from 'custom-protocol-check'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router'
import { match } from 'ts-pattern'

import { exchangeTokenId } from '#/core/browser/request'
import { cookie } from '#/core/cookie'
import { logger } from '#/core/logger'
import { useCaptureEvent, useHandler } from '#/hooks'

import { CircularProgress } from '../common/loader'
import { idSessionStorage } from '../views/auth/login/helper'
import { RedirectState } from '../views/auth/login/types'
import styles from './id-redirect.module.scss'

const Logo = () => <img src="/logo-white.svg" alt="Sky Mavis Logo" />

const redirectToNext = (url: string) => (window.location.href = url || '/')

const buildDeepLink = (idToken: string, idState: string) => `${config.appProtocol}://authorize_id?id_token=${idToken}&id_state=${idState}`

type RedirectData = {
  idState: string
  idToken: string
  nextUrl: string
}

export const IdRedirectScreen: React.FC = () => {
  const navigate = useNavigate()
  const captureEvent = useCaptureEvent()
  const { handleLogin } = useHandler()
  const { lg: isLargeDimension } = useResponsive()

  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [redirectData, setRedirectData] = useState<RedirectData>()

  const handleWebLogin = async (idToken: string, nextUrl: string) => {
    try {
      setIsLoading(true)
      const data = await exchangeTokenId(idToken)
      await handleLogin(data, false)
      redirectToNext(nextUrl)
    } catch (error) {
      setIsError(true)
      setIsLoading(false)
      logger.error('ID Login [Web] - Failed', { error })
    }
  }

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const idToken = query.get('data')
    const stateData = query.get('state')

    if (!idToken || !stateData) {
      setIsError(true)

      return
    }

    const state = JSON.parse(decodeURIComponent(stateData)) as RedirectState
    if (state.from === 'app') {
      const url = buildDeepLink(idToken, state.id)
      customProtocolCheck(
        url,
        () => {
          setIsError(true)
          logger.error('ID Login - Custom Mavis Hub protocol not found')
        },
        () => void 0,
        2000,
        () => {
          // not support browsers
          window.open(url, '_blank')
        }
      )

      setRedirectData({
        idToken,
        idState: state.id,
        nextUrl: state.next,
      })

      return
    }

    if (cookie.get(COOKIE_NAME.ACCESS_TOKEN)) {
      redirectToNext(state.next)

      return
    }

    const idAuthState = idSessionStorage.getIdTokenState()
    if (state.id === null || state.id !== idAuthState) {
      setIsError(true)

      return
    }

    handleWebLogin(idToken, state.next)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUseBrowser = () => {
    if (redirectData) {
      handleWebLogin(redirectData.idToken, redirectData.nextUrl)
    }
    captureEvent('Click use Mavis Hub browser')
  }

  return (
    <div className={styles.container}>
      <Helmet>
        <title>Redirecting...</title>
      </Helmet>
      <div className={styles.wrapper}>
        {match({
          isLoading,
          isError,
        })
          .with({ isLoading: true }, () => (
            <div className={styles.loading}>
              <div className={styles.progress}>
                <CircularProgress
                  value="indeterminate"
                  size={isLargeDimension ? 160 : 128}
                />
                <div className={styles.logo}>
                  <Logo />
                </div>
              </div>
              <h1 className={styles.title}>Redirecting Mavis Hub</h1>
              <p className={styles.description}>Loading...</p>
            </div>
          ))
          .with({ isError: true }, () => (
            <div className={styles.error}>
              <div className={styles.logo}>
                <img src="/sleeping-bird.svg" alt="Sky Mavis Logo" />
              </div>
              <h1 className={styles.title}>Something went wrong!</h1>
              <p className={styles.description}>
                We’re having some trouble completing your request right now.
                Please try again later!
              </p>
              <div className={styles.back}>
                <Button
                  className={styles.button}
                  type="button"
                  intent={Intent.Primary}
                  text="Back to home"
                  onClick={() => navigate('/')}
                />
              </div>
            </div>
          ))
          .otherwise(() => (
            <div className={styles.app}>
              <div className={styles.logo}>
                <Logo />
              </div>
              <h1 className={styles.title}>Launching Mavis Hub</h1>
              <p className={styles.description}>
                Click &quot;Open Mavis Hub&quot; to launch the desktop app...
                Not working? Click{' '}
                <a
                  className={styles.link}
                  href={redirectData && buildDeepLink(redirectData.idToken, redirectData.idState)}
                >
                  &quot;here&quot;
                </a>{' '}
                to reopen!
                <br />
                Or you can also use{' '}
                <span className={styles.link} onClick={handleUseBrowser}>
                  Mavis Hub browser.
                </span>
              </p>
            </div>
          ))}
      </div>
    </div>
  )
}
