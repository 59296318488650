import { User04Icon } from '@axieinfinity/dango-icons'
import {
  Button,
  ButtonVariant,
  Popover,
} from '@axieinfinity/konan'
import cx from 'classnames'
import { useSetAtom } from 'jotai'
import { useCallback, useState } from 'react'
import { match,P } from 'ts-pattern'

import { Skeleton } from '#/components/common/loader/skeleton'
import { loginDialogAtom } from '#/core/stores'
import { useCaptureEvent } from '#/hooks'
import { useUserProfile } from '#/hooks/query'

import { AddressInfo } from '../address-info'
import { ProfileInfo } from '../profile-info'
import type { ProfileMenuItem } from '../types'
import styles from './profile.module.scss'

type Props = {
  items: ProfileMenuItem[]
}

export const Profile: React.FC<Props> = ({ items }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const captureEvent = useCaptureEvent()
  const { data: profile, isLoading } = useUserProfile()

  const setLoginDialog = useSetAtom(loginDialogAtom)

  const openMenu = useCallback(() => {
    captureEvent('Profile - Open Menu')
    setIsOpen(prev => !prev)
  }, [captureEvent])

  const closeMenu = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleItemClick = useCallback(
    (label: string, callback: () => void) => {
      return () => {
        captureEvent(`Profile - Click ${label}`)
        closeMenu()
        callback()
      }
    },
    [captureEvent, closeMenu]
  )

  const openLogInDialog = () => {
    captureEvent('Click Login')
    setLoginDialog({ isVisible: true })
  }

  if (profile === undefined) {
    return (
      <div className={styles.container}>
        <Skeleton width={40} corner="rounded-full" />
        <Skeleton width={160} height={40} corner="rounded" />
        <Skeleton size={40} />
      </div>
    )
  }

  if (profile === null) {
    return (
      <Button
        variant={ButtonVariant.Default}
        text="Log in"
        onClick={openLogInDialog}
      />
    )
  }

  return (
    <Popover
      layerClassName={styles.popover}
      isOnDialog
      isOpen={isOpen}
      triggerElement={
        <div className={cx(styles.container, { [styles.loading]: isLoading })} onClick={openMenu}>
          <div className={styles.avatar}>
            <User04Icon size={20} color="#5B6372" />
          </div>

          <div className={styles.information}>
            <div className={styles.name}>{profile.name}</div>
          </div>
        </div>
      }
      onOutsideClick={closeMenu}
    >
      <div className={styles.profile}><ProfileInfo {...profile} /></div>
      <AddressInfo className={styles.addressInfo} {...profile} />
      <div className={styles.navs}>
        {items.map(({ label, symbol, isNegative, onClick }, index) => (
          <div
            className={cx(styles.item, { [styles.negative]: isNegative })}
            key={index}
            onClick={handleItemClick(label, onClick)}
          >
            {match(symbol)
              .with(P.not(P.nullish), (Element) => <Element size={20} />)
              .otherwise(() => null)}
            <span>{label}</span>
          </div>
        ))}
      </div>
    </Popover>
  )
}
