import { Input, Size } from '@axieinfinity/dango'
import { ArrowLeftIcon } from '@axieinfinity/dango-icons'

// import { toast } from 'sonner'
import styles from './subscriber.module.scss'

export const SubscriberSection: React.FC = () => {
  function handleSubmit() {
    // toast.success('Thank you for subscribing')
  }

  return (
    <section className={styles.section}>
      <div className={styles.label}>get the latest updates</div>

      <Input
        className={styles.input}
        size={Size.Large}
        placeholder="Email"
        suffixIcon={<ArrowLeftIcon onClick={handleSubmit} />}
        disabled
      />

      <div className={styles.description}>
        By clicking you agree to our Privacy Policy term. You
        always have the choice to unsubscribe within every email you receive.
      </div>
    </section>
  )
}
