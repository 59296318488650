import { useQuery } from '@tanstack/react-query'

import { services } from '#/core/services'

export const eventDetailsKeys = {
  all: ['event_details'] as const,
  details: (event: string) => [...eventDetailsKeys.all, event] as const,
}
export const useEventDetails = (event: string) => {
  return useQuery({
    queryKey: eventDetailsKeys.details(event),
    queryFn: async () => {
      const { response, error, result } = await services.request('get /v2/public/game-events/[event]', { event })

      if (response.status >= 500 || error) {
        return undefined
      } else {
        return result
      }
    },
  })
}
