import { KataruInvokeFnOnWeb } from '@axieinfinity/kataru'

export const getInstalledGames: KataruInvokeFnOnWeb<'game:installed'> = () =>
	window.bridge?.kataru.invoke('game:installed')

export const requestInstallGame: KataruInvokeFnOnWeb<'game:request_install'> = (payload) =>
	window.bridge?.kataru.invoke('game:request_install', payload)

export const requestUpdateGame: KataruInvokeFnOnWeb<'game:request_update'> = (payload) =>
	window.bridge?.kataru.invoke('game:request_update', payload)

export const requestUninstallGame: KataruInvokeFnOnWeb<'game:uninstall'> = (slug) =>
	window.bridge?.kataru.invoke('game:uninstall', slug)

export const removeFromQueue: KataruInvokeFnOnWeb<'game:remove_from_queue'> = (slug) =>
	window.bridge?.kataru.invoke('game:remove_from_queue', slug)

export const launchGame: KataruInvokeFnOnWeb<'game:launch'> = (slug, isFirstPartyGame, webGameOptions) =>
	window.bridge?.kataru.invoke('game:launch', slug, isFirstPartyGame, webGameOptions)

export const getInstallingQueue: KataruInvokeFnOnWeb<'game:get_installing_queue'> = () =>
	window.bridge?.kataru.invoke('game:get_installing_queue')

export const getDefaultInstallDir: KataruInvokeFnOnWeb<'game:default_install_dir'> = () =>
	window.bridge?.kataru.invoke('game:default_install_dir')

export const getPreviousPlayingSessions: KataruInvokeFnOnWeb<'game:previous_playing_sessions'> = () =>
	window.bridge?.kataru.invoke('game:previous_playing_sessions')

export const getRecentlyPlayed: KataruInvokeFnOnWeb<'game:recently_played'> = () =>
	window.bridge?.kataru.invoke('game:recently_played')
