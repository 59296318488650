import type { GameCollection, GameDetails } from '@axieinfinity/hub-services'
import { useQuery } from '@tanstack/react-query'
import { toast } from 'sonner'
import { match, P } from 'ts-pattern'

import { errorMessages } from '#/constants'
import { logger } from '#/core/logger'
import { services } from '#/core/services'

const defaultResponse: GameCollection = {
  data: [],
  total: 0,
  pagination: {
    page: 1,
    pageSize: 10,
    totalRows: 0,
    lastPage: 1,
  },
}

export const gameCollectionKeys = {
  all: ['game_collection'] as const,
}

export const useGameCollection = () => {
  return useQuery({
    queryKey: gameCollectionKeys.all,
    queryFn: async () => {
      try {
        const { response, error, result } = await services.request(
          'get /v2/public/games/collections?[query]',
          { query: 'type=featured' }
        )

        if (response.status >= 500) {
          toast.error(errorMessages.serverBusy)

          return defaultResponse
        } else if (error) {
          return defaultResponse
        } else {
          return result
        }
      } catch (e) {
        logger.error(e, { error: e }, 'useGameCollection')

        return defaultResponse
      }
    },
  })
}

const defaultWidgetGames: GameDetails[] = []
export const useWidgetGames = (category: 'most-played' | 'highlight') => {
  return useQuery({
    queryKey: ['widget', category],
    queryFn: async () => {
      try {
        return match(await services.request(`get /v2/public/games/widget/${category}`))
          .with(
            { response: { status: P.number.gte(500) } },
            () => {
              toast.error(errorMessages.serverBusy)

              return defaultWidgetGames
            }
          )
          .with({ error: P.not(P.nullish).select() }, ({ message }) => {
            toast.error(message)

            return defaultWidgetGames
          })
          .otherwise(({ result }) => result.data)
      } catch (e) {
        logger.error(e, { error: e }, 'useMostPlayedGame')

        return defaultWidgetGames
      }
    },
  })
}
