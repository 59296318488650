import { useInView } from 'framer-motion'
import { useRef } from 'react'

type Props = React.HTMLAttributes<HTMLLIElement> & {
  containerRef?: React.RefObject<HTMLElement>
  index?: number
}

export const ObservableListItem: React.FC<Props> = ({ containerRef, index, ...props }) => {
  const elementRef = useRef<HTMLLIElement | null>(null)

  const isInView = useInView(elementRef, { root: containerRef, amount: 1 })

  return (
    <li
      {...props}
      ref={elementRef}
      data-index={typeof index === 'number' ? index : undefined}
      data-in-viewport={isInView ? 'true' : undefined}
    />
  )
}
