import { MobileIcon } from '@axieinfinity/dango-icons'
import { Button, Modal } from '@axieinfinity/konan'
import { GameDetails } from '@axieinfinity/hub-services'
import cx from 'classnames'

import { extractMobileResource } from '#/utils'

import { PlayOnMobileContent } from '../../dialogs/play-on-mobile-content'
import styles from './shared.module.scss'

type Props = {
	minimal?: boolean
	game: GameDetails
}

export const PlayOnMobile: React.FC<Props> = ({ minimal = false, game }) => {
	const resources = Object.values(extractMobileResource(game.latestVersion?.resources)).filter(Boolean)

	if (!resources.length) {
		return null
	}

	return (
		<Modal
			target={({ open }) => (
				<Button
					className={cx(styles.button, styles.animated)}
					{...(minimal
						? { text: <MobileIcon /> }
						: { text: 'Play on mobile', icon: MobileIcon })}
					disabled={!resources.some(resource => Boolean(resource?.downloadUrl))}
					onClick={open}
				/>
			)}
			turnIntoDrawerOnMobile
			isDismissable
		>
			<PlayOnMobileContent resource={{ mobileResources: resources, name: game.name }} />
		</Modal>
	)
}
