import { BookUpload02Icon } from '@axieinfinity/dango-icons'
import { GameDetails } from '@axieinfinity/hub-services'
import classNames from 'classnames'
import { useAtomValue } from 'jotai'

import { Tooltip } from '#/components/common/tooltip'
import { GameButtonState } from '#/constants'
import { downloadProgressAtom } from '#/core/stores'
import { useGame } from '#/hooks'

import styles from './item.module.scss'
import { LibraryItemContextMenu } from './menu'

type Props = {
	game: GameDetails
	selected?: string
	onClick(): void
}
export const LibraryItem: React.FC<Props> = ({ game, selected, onClick }) => {
	const { state } = useGame(game)
	const progress = useAtomValue(downloadProgressAtom)

	const currentPercentage =
		progress?.total && progress?.transferred
			? (progress.transferred / progress.total) * 100
			: undefined

	return (
		<LibraryItemContextMenu game={game}>
			<button
				className={classNames(
					styles.label,
					game.slug === selected && styles.selected
				)}
				data-state={state}
				onClick={onClick}
			>
				<img src={game.logoUrl} className={styles.logo} />
				<span className={styles.name}>
					<Tooltip
						content={
							game.name +
							(state === GameButtonState.Update ? ' - Update Available' : '')
						}
					>
						<span className={styles.status}>
							<span className={styles.title}>{game.name}</span>
							{state === GameButtonState.Installing && currentPercentage && (
								<span className={styles.progress}>
									- {Math.floor(currentPercentage)}%
								</span>
							)}
						</span>
					</Tooltip>
					{(state === GameButtonState.Queued ||
						state === GameButtonState.Installing) && (
							<svg className={styles.incidator}>
								<rect
									width="100%"
									height={2}
									rx={2}
									fill="#3C424D"
									className={styles.track}
								/>
								<rect
									width={
										state === GameButtonState.Queued && !currentPercentage
											? '100%'
											: `${currentPercentage}%`
									}
									height={2}
									rx={2}
									fill="#0094FF"
									className={classNames(styles.fill, {
										[styles.indeterminate]: state === GameButtonState.Queued || currentPercentage === undefined,
									})}
								/>
							</svg>
						)}
				</span>
				{state === GameButtonState.Update && (
					<BookUpload02Icon className={styles.icon} />
				)}
			</button>
		</LibraryItemContextMenu>
	)
}
