import type { GameMedia } from '@axieinfinity/hub-services'
import { YouTubePlayer } from '@axieinfinity/youtube-player'
import { useMemoizedFn } from 'ahooks'
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import { match } from 'ts-pattern'

import styles from './image.module.scss'

const slideChangeInterval = 10_000
const updateInterval = 100

export type Ref = {
  resetInterval: VoidFunction
}

type Props = React.HTMLAttributes<HTMLDivElement> & {
  autoplay?: boolean
  media?: GameMedia
  onComplete?: (currentMedia: GameMedia) => void
}

export const FigImage = forwardRef<Ref, Props>(({ autoplay, media, onComplete }, forwardedRef) => {
  const remaining = useRef<number>(slideChangeInterval)

  const handleVideoEnd = useMemoizedFn((media: GameMedia) => () => {
    onComplete?.(media)
  })

  useEffect(() => {
    if (autoplay && media?.type === 'image') {
      const interval = setInterval(() => {
        if (remaining.current <= 0) {
          remaining.current = slideChangeInterval
          onComplete?.(media)
        } else {
          remaining.current -= updateInterval
        }
      }, 100)

      return () => clearInterval(interval)
    }
  }, [autoplay, media, onComplete])

  useImperativeHandle(forwardedRef, () => ({
    resetInterval() {
      remaining.current = slideChangeInterval
    },
  }), [])

  const isImage = media?.type === 'image'
  const backgroundImageUrl = match(media)
    .returnType<string>()
    .with({ type: 'embed' }, ({ thumbnail }) => thumbnail)
    .with({ type: 'image' }, ({ src }) => src)
    .otherwise(() => '')

  return (
    <div
      className={styles.image}
      style={{ backgroundImage: `url(${backgroundImageUrl})` }}
      // ? force progress bar to restart when source changes
      key={media?.src}
    >
      {media?.type === 'embed' && (
        <YouTubePlayer
          className={styles.player}
          key={media.src}
          video={media.src}
          options={{ playerVars: { autoplay: 1, controls: 1, rel: 0 } }}
          onEnd={handleVideoEnd(media)}
        />
      )}

      <div
        style={{ '--animation-duration': `${slideChangeInterval}ms` } as CustomCSSProperties}
        data-slot="progress-bar"
        data-hidden={!isImage || !autoplay ? 'true' : undefined}
      />
    </div>
  )
})
