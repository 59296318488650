import { ChevronLeftIcon, ChevronRightIcon } from '@axieinfinity/dango-icons'
import {
  GameDetails,
  GetPublicGamesPayload,
  PaginationInput,
  PartnershipTypeEnum,
} from '@axieinfinity/hub-services'
import { Button, useResponsive } from '@axieinfinity/konan'
import cx from 'classnames'
import { useCallback, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { match, P } from 'ts-pattern'

import { Skeleton } from '#/components/common/loader/skeleton'
import { useCaptureEvent } from '#/hooks'
import { usePublicGames } from '#/hooks/query'
import { serializeUrlSearchParams } from '#/utils'

import { GameFilterCategory } from '../../filters'
import { GameCard, sectionTitle, SectionType } from '../../game'
import { BrowseAllCard } from './all'
import styles from './section.module.scss'

const sectionToSearchParams: Record<
  SectionType,
  Record<GameFilterCategory, string[]> | Record<string, unknown>
> = {
  skymavisGames: { partnershipType: [PartnershipTypeEnum.SkyMavisGame] },
  builderProgram: { partnershipType: [PartnershipTypeEnum.AxieBuilderProgram] },
  partnershipStudio: { partnershipType: [PartnershipTypeEnum.PartnerStudio] },
  newReleases: {},
  upcomingReleases: {},
}

const queryOption: Record<SectionType, GetPublicGamesPayload> = {
  newReleases: { section: 'NEW' },
  upcomingReleases: { section: 'UPCOMING' },
  skymavisGames: {
    partnershipType: [PartnershipTypeEnum.SkyMavisGame],
  },
  builderProgram: {
    partnershipType: [PartnershipTypeEnum.AxieBuilderProgram],
  },
  partnershipStudio: {
    partnershipType: [PartnershipTypeEnum.PartnerStudio],
  },
}

const defaultPagination: PaginationInput = {
  page: 1,
  pageSize: 4,
}

type Props = Pick<
  React.HTMLAttributes<HTMLElement>,
  'className' | 'children'
> & {
  type: SectionType
  /**
   * Hide section by default & only show if content is ready
   */
  defaultHidden?: boolean
}

export const GameSection: React.FC<Props> = ({
  className,
  type,
  defaultHidden = false,
}) => {
  const captureEvent = useCaptureEvent()
  const navigate = useNavigate()
  const { md: isMediumDimension } = useResponsive()

  const [pagination, SetPagination] = useState<PaginationInput>(defaultPagination)

  const { isLoading, data } = usePublicGames(queryOption[type] ?? {}, {
    ...defaultPagination,
    ...pagination,
  })

  const onClickPrevPage = useCallback(
    function () {
      captureEvent('Click View Previous Games', { section: type })
      SetPagination(prevState => ({
        ...prevState,
        page:
          prevState.page !== undefined && prevState.page > 1
            ? prevState.page - 1
            : 1,
      }))
    },
    [captureEvent, type]
  )

  const onClickNextPage = useCallback(
    function () {
      captureEvent('Click View Next Games', { section: type })
      SetPagination(prevState => ({
        ...prevState,
        page: prevState.page !== undefined ? prevState.page + 1 : 1,
      }))
    },
    [type, captureEvent]
  )

  const paginationButtons = useMemo(() => {
    return match(data)
      .with(P.not(P.nullish), ({ pagination }) => {
        const hasPrevButton = pagination.page > 1
        const hasNextButton = pagination.page < pagination.lastPage

        return (
          <div className={styles.paginationControls}>
            <Button
              className={cx(styles.button, styles.prev, {
                [styles.visible]: hasPrevButton,
              })}
              text={<ChevronLeftIcon />}
              onClick={onClickPrevPage}
            />

            <Button
              className={cx(styles.button, styles.next, {
                [styles.visible]: hasNextButton,
              })}
              text={<ChevronRightIcon />}
              onClick={onClickNextPage}
            />
          </div>
        )
      })
      .otherwise(() => null)
  }, [data, onClickPrevPage, onClickNextPage])

  function onTagClick(slug: string) {
    captureEvent('Click Game Tag', {
      section: type,
      tag: slug,
    })

    navigate(
      `/games?${serializeUrlSearchParams({
        [GameFilterCategory.Genre]: [slug],
      })}`
    )
  }

  if (
    !isLoading &&
    (!data || data.pagination === null || data.pagination.totalRows === 0)
  ) {
    return null
  }

  function onClickGameCard(game: GameDetails) {
    return () => {
      const { id, name, slug, organization } = game
      const genres = game.genres?.map(g => g.slug)
      captureEvent('Click Game Card', {
        section: type,
        game: { id, name, slug, genres },
        game_org: {
          name: organization?.name,
          slug: organization?.slug,
        },
      })
    }
  }

  return (
    <section
      className={cx(styles.section, className, {
        [styles.defaultHidden]: defaultHidden && !data?.data.length,
      })}
    >
      <div className={styles.header}>
        <div className={styles.text}>{sectionTitle[type]}</div>
        {!isMediumDimension && paginationButtons}
      </div>

      <div className={styles.body}>
        {match({ isLoading, data })
          .when(
            ({ isLoading, data }) => isLoading || data === undefined,
            () => (
              <div className={styles.grid}>
                {Array.from({ length: 4 }, (_, index) => (
                  <Skeleton className={styles.loader} corner="rounded" key={index} />
                ))}
              </div>
            )
          )
          .with({ data: P.not(P.nullish) }, ({ data }) => {
            const hasBrowseAllCard =
              data.pagination.page === data.pagination.lastPage &&
              data.pagination.lastPage > 1
            const recordToDisplay = hasBrowseAllCard
              ? data.data.slice(0, 3)
              : data.data

            return (
              <>
                <div className={styles.grid}>
                  {recordToDisplay?.map(game => (
                    <Link
                      className={styles.item}
                      key={game.slug}
                      to={`/games/${game.slug}`}
                      onClick={onClickGameCard(game)}
                    >
                      <GameCard {...game} onTagClick={onTagClick} />
                    </Link>
                  ))}

                  {hasBrowseAllCard && (
                    <Link
                      className={styles.item}
                      to={`/games?${serializeUrlSearchParams(
                        sectionToSearchParams[type]
                      )}`}
                    >
                      <BrowseAllCard />
                    </Link>
                  )}
                </div>
                {isMediumDimension && paginationButtons}
              </>
            )
          })
          .otherwise(() => null)}
      </div>
    </section>
  )
}
