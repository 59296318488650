
import { User04Icon } from '@axieinfinity/dango-icons'
import { ASProfile } from '@axieinfinity/hub-services'

import styles from './profile-info.module.scss'

type Props = Pick<ASProfile, 'name' | 'email'>

export const ProfileInfo: React.FC<Props> = ({ name, email }) => {
  return (
    <div className={styles.container}>
      <div className={styles.avatar}>
        <User04Icon size={40} color="#5B6372" />
      </div>

      <div className={styles.information}>
        <div className={styles.name}>{name}</div>
        {!!email && <div className={styles.email}>{email}</div>}
      </div>
    </div>
  )
}
