import { Search01Icon } from '@axieinfinity/dango-icons'
import { Input } from '@axieinfinity/konan'
import { useDebounce } from 'ahooks'
import React, { Fragment, useState } from 'react'

import styles from './aside.module.scss'
import { LibraryList } from './list'
import { LibraryQuickFilter, LibraryQuickFilters } from './quick-filters'

type Props = {
  selectedGame?: string
  setSelectedGame: (game: string) => void
}
export const LibraryAside: React.FC<Props> = ({
  selectedGame,
  setSelectedGame,
}) => {
  const [searchValue, setSearchValue] = useState<string>('')
  const debounceSearchValue = useDebounce(searchValue, { wait: 200 })

  const [quickFilter, setQuickFilter] = useState<
    LibraryQuickFilter | undefined
  >(undefined)

  return (
    <Fragment>
      <Input
        type="text"
        value={searchValue}
        prefixIcon={<Search01Icon className={styles.searchIcon} />}
        className={styles.searchBox}
        placeholder="Search game by name"
        onChange={e => setSearchValue(e.currentTarget.value)}
      />
      <LibraryQuickFilters value={quickFilter} onValueChange={setQuickFilter} />
      <LibraryList
        selected={selectedGame}
        searchValue={debounceSearchValue}
        quickFilter={quickFilter}
        onSelectedChange={setSelectedGame}
      />
    </Fragment>
  )
}
