import { useMemoizedFn } from 'ahooks'
import { useRef } from 'react'

import {
  FeaturedGameSection,
  GameGenreSection,
  HighlightGameSection,
  HomeBanner,
  HomeEventBanner,
  MostPlayedGameSection,
  NewsSection,
} from '#/components/views/home'
import { useCaptureScreen } from '#/hooks/tracking'

import { BlurryBackground, type Ref as BlurryBackgroundRef } from '../views/blurry-background'
import styles from './home.module.scss'

export const HomeScreen: React.FC = () => {
  useCaptureScreen('Home')

  const blurryBackgroundRef = useRef<BlurryBackgroundRef>(null)

  const handleBannerChange = useMemoizedFn((src: string) => {
    blurryBackgroundRef.current?.updateSource(src)
  })

  return (
    <div className={styles.screen}>
      <BlurryBackground ref={blurryBackgroundRef} />

      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <HomeBanner onChange={handleBannerChange} />
          <FeaturedGameSection />
          <MostPlayedGameSection />
          <HighlightGameSection />
          <NewsSection />
          <GameGenreSection />
          <HomeEventBanner />
        </div>
      </div>
    </div>
  )
}
