import { Typography } from '@axieinfinity/konan'
import cx from 'classnames'

import { CircularProgress } from '#/components/common/loader/circular-progress'

import styles from './validate.module.scss'

type Props = {
  isVisible: boolean
  percentage: number
}

export const UpdaterDownloading: React.FC<Props> = ({ isVisible, percentage }) => {
  return (
    <div className={cx(styles.container, styles.update, { [styles.visible]: isVisible })}>
      <div className={cx(styles.indicator)}>
        <CircularProgress size={20} strokeWidth={3} value="indeterminate" />
        <Typography.Text>Downloading new update... {percentage.toFixed(2)}%</Typography.Text>
      </div>
    </div>
  )
}
