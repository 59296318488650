import { ArrowRightIcon } from '@axieinfinity/dango-icons'
import cx from 'classnames'
import { motion, Variants } from 'framer-motion'
import { Link } from 'react-router-dom'

import { useCaptureEvent } from '#/hooks'

import styles from './back.module.scss'

type Props = {
  children?: React.ReactNode
  href: string
  onClick?: () => void
  className?: string
}

const MotionLink = motion(Link)

export const Back: React.FC<Props> = ({ children, href, onClick, className }) => {
  const captureEvent = useCaptureEvent()
  const onClickBackHome = () =>
    captureEvent('Click Back to Home', { from_location: window.location.href })

  const animate: Record<'button' | 'icon', Variants> = {
    button: {
      initial: { opacity: 1 },
      hover: { opacity: 0.68 },
    },
    icon: {
      initial: { translateX: 0 },
      hover: { translateX: -8 },
    },
  }

  return (
    <motion.div initial="initial" whileHover="hover" className={cx(styles.container, className)}>
      <MotionLink to={href} className={styles.back} variants={animate.button} onClick={onClick || onClickBackHome}>
        <motion.div variants={animate.icon}>
          <ArrowRightIcon size={20} />
        </motion.div>
        <span>{children || 'Back to Home'}</span>
      </MotionLink>
    </motion.div>
  )
}
