import { useSyncExternalStore } from 'react'

export function useNetwork() {
  const isOnline = useSyncExternalStore(subscribe, getSnapshot)

  return isOnline
}

function getSnapshot() {
  return navigator.onLine
}

function subscribe(callback: Parameters<typeof addEventListener<'online'>>[1]) {
  window.addEventListener('online', callback)
  window.addEventListener('offline', callback)

  return () => {
    window.removeEventListener('online', callback)
    window.removeEventListener('offline', callback)
  }
}
