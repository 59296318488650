import { getSearchParams } from '@axieinfinity/hub-services'
import { type QueryKey, useQuery } from '@tanstack/react-query'
import { match, P } from 'ts-pattern'

import { logger } from '#/core/logger'
import { services } from '#/core/services'

const mainKey = 'game_genres'
const queryKeyMap: Record<string, QueryKey> = {
  available: [mainKey, '/available'],
  popular: [mainKey, '/popular'],
}

export const useGameGenres = () => {
  return useQuery({
    staleTime: 18e5, // 30 minutes
    queryKey: queryKeyMap.available,
    queryFn: async () => {
      try {
        return match(await services.request(
          'get /v2/public/game-genres?[query]',
          { query: getSearchParams({ isAvailable: true }).toString() }
        ))
          .with({ response: { status: P.number.gte(500) } }, () => [])
          .with({ error: P.not(P.nullish) }, () => [])
          .otherwise(({ result }) => result)
      } catch (e) {
        logger.error(e, { error: e }, 'useGameGenres')

        return []
      }
    },
  })
}

export const usePopularGameGenres = () => {
  return useQuery({
    staleTime: 18e5, // 30 minutes
    queryKey: queryKeyMap.popular,
    queryFn: async () => {
      try {
        return match(await services.request('get /v2/public/game-genres/popular'))
          .with({ response: { status: P.number.gte(500) } }, () => [])
          .with({ error: P.not(P.nullish) }, () => [])
          .otherwise(({ result }) => result.data)
      } catch (e) {
        logger.error(e, { error: e }, 'usePopularGameGenres')

        return []
      }
    },
  })
}
