import { Search02Icon } from '@axieinfinity/dango-icons'
import { Input, Size, useClickOutside } from '@axieinfinity/konan'
import { useDebounce } from 'ahooks'
import cx from 'classnames'
import { useSetAtom } from 'jotai'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { match, P } from 'ts-pattern'

import { CircularProgress } from '#/components/common/loader'
import { isMobileSearchOpenedAtom } from '#/core/stores'
import { useSearchQuery } from '#/hooks/game'
import { usePublicGamesV3 } from '#/hooks/query'

import styles from './search.module.scss'

const Search: React.FC = () => {
  const navigate = useNavigate()
  const setIsMobileSearchOpened = useSetAtom(isMobileSearchOpenedAtom)
  const { keyword } = useSearchQuery()
  const [value, setValue] = useState('')
  const [showResult, setShowResult] = useState(false)
  const resultRef = useRef(null)

  const debouncedValue = useDebounce(value, { wait: 300 })

  useClickOutside([resultRef], (isOutside) => {
    if (isOutside) {
      setShowResult(false)
    }
  })

  const { isLoading, error, data } = usePublicGamesV3(
    {
      q: debouncedValue,
      page: 1,
      pageSize: 5,
    },
    {
      enabled: debouncedValue.length > 0,
    }
  )

  useEffect(() => {
    setValue(keyword || '')
  }, [keyword])

  useEffect(() => {
    setShowResult(debouncedValue.length > 0)
  }, [debouncedValue])

  const resetSearch = () => {
    setValue('')
    setIsMobileSearchOpened(false)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setValue(value)
  }
  const handleSubmit = () => {
    navigate(`/games?q=${value}`)
    setShowResult(false)
    setIsMobileSearchOpened(false)
  }
  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  const onClickSeeAll = () => {
    navigate('/games')
    resetSearch()
  }
  const onClickGame = (slug: string) => {
    navigate(`/games/${slug}`)
    resetSearch()
  }

  return (
    <div className={styles.container}>
      <Input
        className={styles.input}
        size={Size.Large}
        placeholder="Search game"
        value={value}
        suffixIcon={<Search02Icon color="#8E97A8" onClick={handleSubmit} />}
        onChange={handleChange}
        onKeyDown={handleEnter}
      />
      {showResult && (
        <div ref={resultRef} className={styles.result}>
          {isLoading ? (
            <div className={cx(styles.loading)}>
              <CircularProgress
                value="indeterminate"
                size={24}
                strokeWidth={2}
              />
            </div>
          ) : (
            <>
              <div className={styles.content}>
                {match({ error, data })
                  .with({ error: P.not(P.nullish) }, () => (
                    <div className={cx(styles.contentPadding, styles.error)}>
                      Something went wrong.
                    </div>
                  ))
                  .with({ data: P.not(P.nullish) }, ({ data }) => {
                    if (!data.data.length) {
                      return (
                        <div
                          className={cx(styles.contentPadding, styles.empty)}
                        >
                          No results found.
                        </div>
                      )
                    }

                    return (
                      <div className={styles.items}>
                        {data.data.map((item) => (
                          <div
                            key={item.slug}
                            className={cx(styles.contentPadding, styles.item)}
                            onClick={() => onClickGame(item.slug)}
                          >
                            <img src={item.thumbnailUrl} alt={item.name} />
                            <div>{item.name}</div>
                          </div>
                        ))}
                      </div>
                    )
                  })
                  .otherwise(() => null)}
              </div>
              <div className={styles.all} onClick={onClickSeeAll}>
                <Search02Icon />
                See all Games
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default Search
