import { Typography } from '@axieinfinity/konan'
import cx from 'classnames'
import { useAtomValue, useSetAtom } from 'jotai'
import { useEffect } from 'react'
import { match,P } from 'ts-pattern'

import { mobileMenuAtom } from '#/core/stores'
import { useCaptureEvent } from '#/hooks'
import { useUserProfile } from '#/hooks/query'

import { AddressInfo } from '../address-info'
import type { NavigationItem, ProfileMenuItem } from '../types'
import { NavItem } from './navigation-item'
import styles from './overlay.module.scss'
import { Profile } from './profile'

type Props = {
  navRef: React.RefObject<HTMLElement>
  navigationItems: NavigationItem[]
  profileMenuItems: ProfileMenuItem[]
}

export const MobileMenuOverlay: React.FC<Props> = ({ navRef, navigationItems, profileMenuItems }) => {
  const { data: profile } = useUserProfile()
  const captureEvent = useCaptureEvent('mobile-menu|profile')
  const { isVisible } = useAtomValue(mobileMenuAtom)
  const setMobileMenu = useSetAtom(mobileMenuAtom)

  useEffect(() => {
    if (isVisible) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }, [isVisible])

  const handleMenuItemClick = (label: string, callback: () => void, autoClose = true) => {
    return () => {
      captureEvent(`Click on ${label}`)
      if (autoClose) {
        setMobileMenu({ isVisible: false })
      }
      callback()
    }
  }

  return (
    <div
      className={styles.backdrop}
      style={{ paddingTop: navRef.current?.getBoundingClientRect().height ?? 0 }}
      data-visible={isVisible ? 'true' : undefined}
    >
      <div className={styles.content}>
        <Profile />
        {profile && <AddressInfo className={styles.addressInfo} {...profile} />}
        <div className={styles.separator} />
        <div className={styles.group}>
          {navigationItems.map((props, index) => <NavItem {...props} key={index} />)}
        </div>

        {profile && (
          <>
            <div className={styles.separator} />
            <div className={styles.group} >
              {profileMenuItems.map(({ label, symbol, isNegative, autoCloseMenu, onClick }, index) => (
                <Typography.Text
                  className={cx(styles.profileItem, { [styles.negative]: isNegative })}
                  key={index}
                  onClick={handleMenuItemClick(label, onClick, autoCloseMenu)}
                >
                  {match(symbol)
                    .with(P.not(P.nullish), (Element) => <Element size={20} />)
                    .otherwise(() => null)}
                  <span>{label}</span>
                </Typography.Text>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
