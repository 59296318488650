import { match,P } from 'ts-pattern'

import { Skeleton } from '#/components/common/loader'
import { useStorePublicItemList } from '#/external/store/hooks'

import { StoreCard } from './card'
import styles from './items.module.scss'

type Props = {
  merchantId: number
  categoryId: number
}

export const StoreItem: React.FC<Props> = ({ merchantId, categoryId }) => {
  const { data, isLoading, error } = useStorePublicItemList({
    merchantId,
    categoryId,
  })

  return (
    <div className={styles.container}>
      {match({ data, isLoading, error })
        .with({ isLoading: true }, () => (
          <>
            {Array.from({ length: 8 }, (_, index) => (
              <Skeleton key={index} className={styles.skeleton} corner="rounded" fill={false} />
            ))}
          </>
        ))
        .with({ error: P.not(P.nullish) }, () => null)
        .with(
          { data: P.not(P.nullish) },
          ({ data: itemData }) => (
            itemData.data.map(item => <StoreCard key={item.sku} item={item} />)
          )
        )
        .otherwise(() => null)}
    </div>
  )
}

