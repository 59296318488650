import { AndroidIcon, SvgIconProps } from '@axieinfinity/dango-icons'
import { GameMobileDownloadResource, MobilePlatformOs } from '@axieinfinity/hub-services'
import { Button, ButtonVariant, Typography } from '@axieinfinity/konan'
import cx from 'classnames'
import { useState } from 'react'

import { QR } from '#/components/common/qr'
import { GooglePlayIcon, IOSIcon } from '#/constants'
import { isNotTypeEmpty } from '#/utils'

import styles from './play-on-mobile-content.module.scss'

const mobileResourceMap: Record<MobilePlatformOs, {
	icon: React.FC<SvgIconProps>
	label: string
}> = {
	ios: {
		icon: IOSIcon,
		label: 'App Store',
	},
	android: {
		icon: AndroidIcon,
		label: 'APK',
	},
	'android-google-play': {
		icon: GooglePlayIcon,
		label: 'Play Store',
	},
}

export const PlayOnMobileContent: React.FC<{
	resource: {
		mobileResources: Array<GameMobileDownloadResource | undefined>
		name: string
	}
}> = ({ resource }) => {
	const [tab, setTab] = useState(0)

	const selectedResource = resource?.mobileResources[tab]
	if (!selectedResource) return null
	if (resource === null) return null

	const resourceOss = resource.mobileResources.filter(isNotTypeEmpty)

	return (
		<section className={styles.section}>
			<Typography.Heading level="5">Download on Mobile</Typography.Heading>
			<div
				className={styles.tabs}
				style={{
					gridTemplateColumns: Array(resourceOss.length).fill('1fr').join(' '),
				}}
			>
				{resourceOss.map((resource, index) => {
          const resourceMap = mobileResourceMap[resource.os]

          return (
            <Button
              text={`${resourceMap.label}`}
              key={index}
							variant={ButtonVariant.Plain}
              className={cx(styles.tab, {
								[styles.active]: tab === index,
							})}
              icon={resourceMap.icon}
              onClick={() => setTab(index)}
            />
          )
        })}
			</div>

			<div className={styles.qrCode}>
				<div className={styles.image}>
					<QR value={selectedResource.downloadUrl} />
				</div>
			</div>
		</section>
	)
}
