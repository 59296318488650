import { ArrowUpIcon } from '@axieinfinity/dango-icons'
import { GameDetails } from '@axieinfinity/hub-services'
import Fuse from 'fuse.js'
import { useAtomValue } from 'jotai'
import { useEffect, useMemo, useRef, useState } from 'react'
import { match } from 'ts-pattern'

import { installedGamesAtom, recentlyPlayedAtom } from '#/core/stores'
import { useCaptureEvent } from '#/hooks'
import { useUserLibrary } from '#/hooks/query'
import { getCurrentOSResource } from '#/utils'

import { LibraryItem } from './item'
import styles from './list.module.scss'
import { LibraryQuickFilter } from './quick-filters'

type Props = {
	selected?: string
	onSelectedChange: (selected: string) => void
	quickFilter?: LibraryQuickFilter
	searchValue: string
}

export const LibraryList: React.FC<Props> = ({
	selected,
	onSelectedChange,
	quickFilter,
	searchValue,
}) => {
	const { data } = useUserLibrary()
	const captureEvent = useCaptureEvent()

	// Scrolling handler
	const [availableScrollToTop, setAvailableScrollToTop] = useState(false)
	const scrollRef = useRef<HTMLDivElement>(null)
	useEffect(() => {
		if (!scrollRef.current) return
		const scrollContainer = scrollRef.current

		const scrollHander = () => {
			if (!scrollRef.current) return
			setAvailableScrollToTop(scrollContainer.scrollTop >= 100)
		}
		scrollContainer.onscroll = scrollHander
		scrollHander()
	}, [])

	const scrollToTop = () => {
		scrollRef.current?.scrollTo({ top: 0 })
	}

	// Set default selected gme
	useEffect(() => {
		if (selected === undefined && data?.length) {
			onSelectedChange(data[0].slug)
		}
	}, [data, onSelectedChange, selected])

	// Filtered
	const installedGames = useAtomValue(installedGamesAtom)
	const recentlyPlayed = useAtomValue(recentlyPlayedAtom).map(([slug]) => slug)
	const filtered = useMemo(() => {
		if (!data) {
			return []
		}

		return match(quickFilter)
			.with('system', () =>
				data.filter(
					game =>
						game.latestVersion?.resources &&
						getCurrentOSResource(game.latestVersion.resources) !== undefined
				)
			)
			.with('playable', () =>
				data.filter(game => Boolean(installedGames[game.slug]))
			)
			.with('recent', () =>
				recentlyPlayed.map((slug) => data.find(game => game.slug === slug)).filter(Boolean) as GameDetails[]
			)
			.with(undefined, () => data)
			.exhaustive()
	}, [data, installedGames, quickFilter, recentlyPlayed])

	// Fuse search
	const fuse = useMemo(
		() =>
			new Fuse(filtered, {
				includeScore: false,
				threshold: 0.4,
				keys: ['name', 'slug'],
			}),
		[filtered]
	)
	const fused = useMemo(
		() =>
			!searchValue ? filtered : fuse.search(searchValue).map(item => item.item),
		[filtered, fuse, searchValue]
	)

	const handleSelectGame = (slug: string) => {
		return () => {
			captureEvent('Select game in library', { slug })
			onSelectedChange(slug)
		}
	}

	return (
		<div className={styles.list}>
			<h5>My Library</h5>
			<div className={styles.games}>
				<div className={styles.scroll} ref={scrollRef}>
					<div className={styles.container}>
						{data?.length === 0 && (
							<div className={styles.empty}>
								<span>no game found...</span>
							</div>
						)}
						{fused.map(game => (
							<LibraryItem
								game={game}
								selected={selected}
								key={game.slug}
								onClick={handleSelectGame(game.slug)}
							/>
						))}
					</div>
				</div>
				{availableScrollToTop && (
					<button className={styles.scrollToTop} onClick={scrollToTop}>
						<ArrowUpIcon />
						<span>Scroll to Top</span>
					</button>
				)}
			</div>
		</div>
	)
}
