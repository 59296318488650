import { Button, Intent, Typography } from '@axieinfinity/konan'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

import { useMoss, useNetwork } from '#/hooks'

import styles from './offline.module.scss'

export const OfflineScreen: React.FC = () => {
  const isOnline = useNetwork()
  const { captureScreen } = useMoss()

  useEffect(() => {
    if (!isOnline) captureScreen('Offline')
  }, [captureScreen, isOnline])

  if (isOnline) {
    return null
  }

  return (
    <div className={styles.container}>
      <Helmet>
        <title>You lost in space...</title>
      </Helmet>
      <img src="/assets/axie-sleep.svg" className={styles.illustration} alt="Connection lost" />
      <Typography.Heading level="3" className={styles.label}>
        Connection Lost
      </Typography.Heading>
      <Typography.Text level="2" className={styles.dimmed}>
        Looks like you have lost connection.
      </Typography.Text>
      <Button text="Try Again" intent={Intent.Primary} onClick={() => window.location.reload()} />
    </div>
  )
}
