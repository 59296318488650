import { GameMedia } from '@axieinfinity/hub-services'
import { useMemoizedFn } from 'ahooks'
import { memo, useMemo, useRef, useState } from 'react'
import { match, P } from 'ts-pattern'

import { FigCaption } from './caption'
import styles from './figure.module.scss'
import { FigImage, type Ref as FigImageRef } from './image'

export type Props = {
	items?: GameMedia[]
	onChange?: (src: string) => void
}

const GalleryUnmemo: React.FC<Props> = ({ items = [], onChange }) => {
	const figImageRef = useRef<FigImageRef>(null)
	const [activeItem, setActiveItem] = useState<GameMedia | undefined>(items[0])

	const itemCount = useMemo(() => items.length, [items])

	const handleSlideSelect = useMemoizedFn((value: GameMedia) => () => {
		figImageRef.current?.resetInterval()
		setActiveItem(value)

		const src = match(value)
			.with({ type: 'embed' }, ({ thumbnail }) => thumbnail)
			.with({ type: 'image' }, ({ src }) => src)
			.exhaustive()
		onChange?.(src)
	})

	const handleSlideChange = useMemoizedFn((media: GameMedia) => {
		const lastIndex = items.length - 1
		const currentMediaIndex = items.findIndex((item) => item.type === media.type && item.src === media.src)
		const nextMedia = match(currentMediaIndex)
			.returnType<GameMedia | undefined>()
			.with(lastIndex, () => items[0])
			.with(P.number.lt(lastIndex), (current) => items[current + 1])
			.otherwise(() => undefined)

		if (nextMedia !== undefined) {
			setActiveItem(nextMedia)

			const nextSource = match(nextMedia)
				.with({ type: 'embed' }, ({ thumbnail }) => thumbnail)
				.with({ type: 'image' }, ({ src }) => src)
				.exhaustive()
			onChange?.(nextSource)
		}
	})

	if (!itemCount) {
		return null
	}

	return (
		<figure className={styles.figure}>
			<FigImage
				ref={figImageRef}
				autoplay={itemCount > 1}
				media={activeItem}
				onComplete={handleSlideChange}
			/>

			{itemCount > 1 && (
				<FigCaption
					items={items}
					itemCount={itemCount}
					activeItem={activeItem}
					onSelect={handleSlideSelect}
				/>
			)}
		</figure>
	)
}

export const Gallery = memo(GalleryUnmemo)
