
import { useMemoizedFn } from 'ahooks'
import { useRef } from 'react'
import { match, P } from 'ts-pattern'

import { useCaptureEvent } from '#/hooks'
import { useGameCollection } from '#/hooks/query'

import { Collapse, PaginationButtons, Section } from '../generic'
import { ObservableListItem } from '../generic/observable-list-item'
import { Card } from './card'
import styles from './section.module.scss'

type Props = {
  defaultHidden?: boolean
}

export const FeaturedGameSection: React.FC<Props> = ({ defaultHidden = true }) => {
  const captureEvent = useCaptureEvent()

  const listElementRef = useRef<HTMLUListElement>(null)

  const { isLoading, data } = useGameCollection()

  const handlePaginationPress = useMemoizedFn((direction: 'previous' | 'next') => () => {
    captureEvent(`Click view ${direction} games`, { collection: 'Featured Games' })

    if (!listElementRef.current) {
      return
    }

    const nodes = listElementRef.current.childNodes
    const lastItemIndex = nodes.length === 1 ? 0 : nodes.length - 1
    const inViewNodes = Array
      .from(nodes, (node, index) => ({ isInViewport: (node as HTMLLIElement).dataset.inViewport === 'true', index }))
      .filter((node) => node.isInViewport)

    if (inViewNodes.length === 0) {
      return
    }

    const nextElementIndex = match(direction)

      .with('previous', () => inViewNodes[0].index === 0 ? lastItemIndex : inViewNodes[0].index - 1)
      .with('next', () => inViewNodes[inViewNodes.length - 1].index === lastItemIndex ? 0 : inViewNodes[inViewNodes.length - 1].index + 1)
      .exhaustive()

    const element = nodes.item(nextElementIndex) as HTMLLIElement
    element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
  })

  const isExpanded = match({ defaultHidden, isLoading, data })
    .returnType<boolean>()
    .with(
      { defaultHidden: true, isLoading: true, data: P.nullish },
      { defaultHidden: true, data: P.nullish },
      { data: P.when((data) => data !== undefined && data.data.length === 0) },
      () => false
    )
    .otherwise(() => true)

  return (
    <Collapse isExpanded={isExpanded}>
      <Section
        className={styles.section}
        heading="Featured Games"
        pagination={<PaginationButtons isDisabled={isLoading} onPress={handlePaginationPress} />}
      >
        <ul ref={listElementRef} data-slot="list">
          {data?.data.map((game) => (
            <ObservableListItem key={game.slug} containerRef={listElementRef} data-slot="list-item">
              <Card {...game} />
            </ObservableListItem>
          ))}
        </ul>
      </Section>
    </Collapse>
  )
}
