import cx from 'classnames'
import { useRef } from 'react'

import styles from './loadable-image.module.scss'
import { Skeleton } from './skeleton'

function formatSize(input: React.ImgHTMLAttributes<HTMLImageElement>['width' | 'height']) {
  return typeof input === 'number' ? `${input}px` : input
}

type Props = React.ImgHTMLAttributes<HTMLImageElement> & {
  fill?: boolean
  fillWidth?: boolean
  fillHeight?: boolean
  borderRadius?: React.CSSProperties['borderRadius']
  containerClassName?: string
  containerStyle?: React.CSSProperties
  onImageLoaded?: () => void
}

export const LoadableImage: React.FC<Props> = ({
  fill = true,
  fillWidth,
  fillHeight,
  width,
  height,
  borderRadius = 0,
  containerClassName,
  containerStyle,
  src,
  onLoad,
  ...imageProps
}) => {
  const imageRef = useRef<HTMLImageElement | null>(null)

  function handleImageLoad(event: React.SyntheticEvent<HTMLImageElement, Event>) {
    onLoad?.(event)
    imageRef.current?.removeAttribute('data-src')
  }

  const imageWidth = fill || fillWidth ? '100%' : formatSize(width)
  const imageHeight = fill || fillHeight ? '100%' : formatSize(height)

  return (
    <div
      className={cx(containerClassName, styles.container)}
      style={
        {
          '--image-width': imageWidth,
          '--image-height': imageHeight,
          borderRadius,
          ...containerStyle,
        } as CustomCSSProperties
      }
    >
      <img {...imageProps} ref={imageRef} data-src={src} src={src} loading="lazy" onLoad={handleImageLoad} />
      <Skeleton className={styles.loader} fill />
    </div>
  )
}
