import { AndroidIcon, AppleIcon, BanIcon } from '@axieinfinity/dango-icons'
import { GameDetails } from '@axieinfinity/hub-services'
import { Button, Intent } from '@axieinfinity/konan'
import UAParser from 'ua-parser-js'

import { GooglePlayIcon } from '#/constants'
import { downloadFile, extractMobileResource } from '#/utils'

import styles from './mobile-actions.module.scss'

interface Props {
  game: GameDetails
}

export const MobileGameActions: React.FC<Props> = ({ game }) => {
  const userAgent = UAParser(navigator.userAgent)
  const mobileOs = userAgent.os.name

  const mobilePlatformResources = extractMobileResource(
    game.latestVersion?.resources
  )

  const handleRedirect = (url: string) => () => {
    window.open(url, '_blank')
  }

  const handleDownload = (url: string) => () => {
    downloadFile(url)
  }

  if (mobileOs === 'iOS' && mobilePlatformResources.ios) {
    return (
      <Button
        intent={Intent.Primary}
        text="Download on App Store"
        icon={AppleIcon}
        onClick={handleRedirect(mobilePlatformResources.ios.downloadUrl)}
      />
    )
  }

  if (
    mobileOs === 'Android' &&
    (mobilePlatformResources.android ||
      mobilePlatformResources['android-google-play'])
  ) {
    return (
      <div className={styles.container}>
        {mobilePlatformResources['android-google-play'] && (
          <Button
            intent={Intent.Primary}
            text="Download on Play Store"
            icon={GooglePlayIcon}
            onClick={handleRedirect(mobilePlatformResources['android-google-play'].downloadUrl)}
          />
        )}
        {mobilePlatformResources.android && (
          <Button
            intent={Intent.Default}
            text="Download APK"
            icon={AndroidIcon}
            onClick={handleDownload(mobilePlatformResources.android.downloadUrl)}
          />
        )}
      </div>
    )
  }

  return (
    <Button
      text="Not Supported"
      icon={BanIcon}
      disabled
    />
  )
}
