import { GameDetails } from '@axieinfinity/hub-services'
import { Intent, Tag, useResponsive } from '@axieinfinity/konan'
import cx from 'classnames'
import { useLocation, useNavigate } from 'react-router'
import { match, P } from 'ts-pattern'

import { Badge } from '#/components/common/badge'
import { LoadableImage } from '#/components/common/loader/loadable-image'

import styles from './card.module.scss'

type Props = GameDetails & {
  type?: 'coming soon' | 'beta' | 'new'
  responsive?: boolean
  onTagClick?: (slug: string) => void
}

export const GameCard: React.FC<Props> = ({ type, responsive, onTagClick, ...game }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { md: isMediumDimension } = useResponsive()

  function onClick(slug: string) {
    return (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()
      onTagClick?.(slug)
    }
  }

  function handleClickOtherTag(event: React.MouseEvent<HTMLDivElement>) {
    event.preventDefault()
    event.stopPropagation()

    navigate('/games')
  }

  return (
    <figure className={styles.figure}>
      <div className={cx(styles.imageContainer, { [styles.responsive]: responsive })}>
        <LoadableImage className={styles.image} src={game.thumbnailUrl} />
        {type !== undefined && <Tag className={styles.tag}>{type}</Tag>}
        <div className={styles.badges}>
          {game.mode === 'coming-soon' && <Badge.ComingSoon />}
          {game.event?.code === 'agj-2023' && <Badge.AxieGameJam year={2023} />}
        </div>
      </div>

      <figcaption className={styles.caption}>
        <div className={styles.text}>{game.name}</div>
        {(isMediumDimension || !responsive) && (
          <div className={styles.tags}>
            {match(game.genres)
              .with(undefined, () => null)
              .with(P.not(P.nullish), (genres) => {
                return (
                  <>
                    {genres.slice(0, 2).map((genre) => (
                      <Tag
                        className={styles.tag}
                        intent={Intent.Default}
                        key={genre.slug}
                        interactive={typeof onTagClick === 'function'}
                        onClick={onClick(genre.slug)}
                      >
                        {genre.name}
                      </Tag>
                    ))}
                    {genres.length > 2 && (
                      <Tag
                        className={styles.tag}
                        intent={Intent.Default}
                        key={'other'}
                        interactive={location.pathname !== '/games'}
                        onClick={handleClickOtherTag}
                      >
                        {`+ ${genres.length - 2}`}
                      </Tag>
                    )}
                  </>
                )
              })
              .exhaustive()}
          </div>
        )}
      </figcaption>
    </figure>
  )
}
