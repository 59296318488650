import { Button, Intent } from '@axieinfinity/konan'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

import { EventBannerTitle } from '#/components/views/event/text'
import { useCaptureScreen } from '#/hooks'
import { useEventDetails } from '#/hooks/query'

import styles from './index.module.scss'

export const EventScreen = () => {
  const { slug } = useParams()
  useCaptureScreen(`Event ${slug} page`)

  const { data } = useEventDetails(slug || 'undefined')

  return (
    <div className={styles.screen}>
      <div className={styles.container}>
        <div className={styles.title}>
          <EventBannerTitle text={data?.title || ''} />
          <Link to={`/event/${slug}/submissions`}>
            <Button text="Submissions Page" intent={Intent.Success} />
          </Link>
        </div>
      </div>
    </div>
  )
}
