import { Folder03Icon } from '@axieinfinity/dango-icons'
import { Button, Dialog, Size, Typography } from '@axieinfinity/konan'
import { useAtomValue } from 'jotai'
import { toast } from 'sonner'

import { Tooltip } from '#/components/common/tooltip'
import handlers from '#/core/handlers'
import { installedGamesAtom } from '#/core/stores'
import { useCaptureEvent, useGameDialog } from '#/hooks'

import styles from './manage.module.scss'

export const GameManageDialog: React.FC = () => {
  const { isVisible, resource, closeDialog, openDialog } = useGameDialog()
  const captureEvent = useCaptureEvent()

  const installedGames = useAtomValue(installedGamesAtom)
  const installed = resource && installedGames[resource.slug]?.location

  // const [isAutoUpdate, setIsAutoUpdate] = useState<boolean>(false)

  async function onOpenLocation() {
    captureEvent('Click Open Game Location', {
      game: {
        id: resource?.id,
        name: resource?.name,
        slug: resource?.slug,
      },
    })

    if (!installed) return
    const result = await handlers.openFolderLocation(installed)
    if (typeof result === 'string') toast.error(result)
    if (result === false) toast.error('Unexpected error while open game folder')
  }

  function uninstallGame() {
    captureEvent('Click Uninstall Game', {
      game: {
        id: resource?.id,
        name: resource?.name,
        slug: resource?.slug,
      },
    })

    const cloneResource = resource
    closeDialog().then(() => cloneResource && openDialog('uninstall', cloneResource))
  }

  /* function onToggleAutoUpdate(event: React.ChangeEvent<HTMLInputElement>) {
    setIsAutoUpdate(event.target.checked)
  } */

  if (!resource || !isVisible('manage')) return null

  return (
    <Dialog size="medium" visible={isVisible('manage')} onClose={closeDialog}>
      {resource !== null && (
        <section className={styles.section}>
          <Typography.Heading level="5">Manage {resource.name}</Typography.Heading>

          <div className={styles.main}>
            <div className={styles.logo} style={{ backgroundImage: `url(${resource.logoUrl})` }} />
            <div className={styles.information}>
              <Typography.Text className={styles.name}>{resource.name}</Typography.Text>
              <Tooltip content={installed}>
                <Typography.Text className={styles.location}>{installed}</Typography.Text>
              </Tooltip>
            </div>
            <div className={styles.actions}>
              <Button
                size={Size.Small}
                className={styles.location}
                text={<Folder03Icon size={20} />}
                onClick={onOpenLocation}
              />
              <Button className={styles.uninstall} size={Size.Small} text="Uninstall" onClick={uninstallGame} />
            </div>
          </div>

          {/* <div className={styles.configurations}>
            <div className={styles.row}>
              <div className={styles.label}>
                <Typography.Text className={styles.text}>Auto updates</Typography.Text>
                <Typography.Text className={styles.subText}>
                  Allow Mavis Hub to automatically download and install Axie Infinity: Origin
                  updates
                </Typography.Text>
              </div>
              <div className={styles.value}>
                <Switch checked={isAutoUpdate} onChange={onToggleAutoUpdate} />
              </div>
            </div>
          </div> */}
        </section>
      )}
    </Dialog>
  )
}
