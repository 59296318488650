import { ChevronLeftIcon, ChevronRightIcon } from '@axieinfinity/dango-icons'
import { Button } from '@axieinfinity/konan'
import { PaginationInput } from '@axieinfinity/hub-services'
import classNames from 'classnames'
import { Fragment, useState } from 'react'
import { match, P } from 'ts-pattern'

import { Skeleton } from '#/components/common/loader'
import { useCaptureEvent } from '#/hooks'
import { usePatchNotes } from '#/hooks/query'

import styles from './list.module.scss'
import { PatchNote } from './view'

type Props = {
	game: string
	pagination?: PaginationInput
}
export const PatchNotes: React.FC<Props> = ({ game, pagination }) => {
	const [queries, setQueries] = useState<PaginationInput>({
		pageSize: 5,
		page: 1,
		desc: true,
		...pagination,
	})
	const { isLoading, data } = usePatchNotes(game, { ...queries, ...pagination })
	const captureEvent = useCaptureEvent()

	function onClickPrevPage() {
		captureEvent('Click View Previous Patch Notes', { game: { slug: game } })
		setQueries(prevState => ({
			...prevState,
			page:
				prevState.page !== undefined && prevState.page > 1
					? prevState.page - 1
					: 1,
		}))
	}

	function onClickNextPage() {
		captureEvent('Click View Next Patch Notes', { game: { slug: game } })
		setQueries(prevState => ({
			...prevState,
			page: prevState.page !== undefined ? prevState.page + 1 : 1,
		}))
	}

	const hasPrevPage = data !== undefined && data.pagination.page > 1
	const hasNextPage =
		data !== undefined && data.pagination.page < data.pagination.lastPage

	return match({ isLoading, data })
		.with({ isLoading: true }, () => (
			<Fragment>
				<div className={styles.list}>
					{Array.from({ length: 5 }, () => (
						<Skeleton height={120} corner="rounded" key={crypto.randomUUID()} />
					))}
				</div>
				<div className={styles.pagination}>
					<Skeleton width={126} height={40} corner="rounded" />
					<Skeleton width={100} height={40} corner="rounded" />
				</div>
			</Fragment>
		))
		.with({ data: P.not(P.nullish) }, ({ data: { data } }) => (
			<Fragment>
				<div className={styles.list}>
					{data.map(
						({ tag, patchNotes, createdAt }) =>
							patchNotes && (
								<PatchNote
									{...patchNotes}
									createdAt={createdAt}
									slug={game}
									key={tag}
								/>
							)
					)}
				</div>
				<div className={styles.pagination}>
					<Button
						className={classNames(styles.button, {
							[styles.invisible]: !hasPrevPage,
						})}
						text="Previous"
						icon={ChevronLeftIcon}
						onClick={onClickPrevPage}
					/>
					<Button
						className={classNames(styles.button, {
							[styles.invisible]: !hasNextPage,
						})}
						text="Next"
						rightIcon={ChevronRightIcon}
						onClick={onClickNextPage}
					/>
				</div>
			</Fragment>
		))
		.otherwise(() => null)
}
