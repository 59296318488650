import { GameDetails, getSearchParams } from '@axieinfinity/hub-services'
import { useMutation, useQuery } from '@tanstack/react-query'
import { toast } from 'sonner'
import { match } from 'ts-pattern'

import { errorMessages } from '#/constants'
import { logger } from '#/core/logger'
import { queryClient } from '#/core/react-query'
import { services } from '#/core/services'

import { useCaptureEvent } from '../tracking'
import { gameDetailsQueryKeys } from './game-details'

const defaultPagination = {
	pageSize: 10,
	page: 1,
	lastPage: 1,
	totalRows: 0,
	desc: false,
}
const defaultResponse = { data: [], total: 0, pagination: defaultPagination }

export const gameCommentQueryKeys = {
	all: ['game_comments'] as const,
	details: (slug: string) => [...gameCommentQueryKeys.all, slug] as const,
	filter: (slug: string, page: number, filter = 'all') => [...gameCommentQueryKeys.details(slug), page, filter ?? 'all'],
}
export const useGameComments = (slug: string, page: number, filter?: string) => {
	return useQuery({
		queryKey: gameCommentQueryKeys.filter(slug, page, filter),
		queryFn: async () => {
			try {
				const { response, error, result } = await services.request('get /v2/public/games/[slug]/comments?[query]', {
					slug,
					query: getSearchParams({
						page,
						filterBy: match(filter)
							.with('upvote', () => 'recommended' as const)
							.with('downvote', () => 'not_recommended' as const)
							.otherwise(() => undefined),
					}).toString(),
				})
				if (response.status >= 500) {
					toast.error(errorMessages.serverBusy)

					return defaultResponse
				} else if (error) {
					return defaultResponse
				} else {
					return result
				}
			} catch (e) {
				logger.error(e, { error: e }, 'useGameComments')

				return defaultResponse
			}
		},
	})
}

export const useComment = (game: GameDetails) => {
	const captureEvent = useCaptureEvent()

	return useMutation({
		mutationFn: async ({ content }: { content: string }) => {
			const event = ['Send comment']
			const eventPayload: Record<string, unknown> = {
				slug: game.slug,
				name: game.name,
				content,
			}
			const { response, error, result } = await services.request(
				'post /v2/users/games/[slug]/comments',
				{
					slug: game.slug,
					body: {
						content: content
							.replace(/(\r\n|\r|\n){2}/g, '$1')
							.replace(/(\r\n|\r|\n){3,}/g, '$1\n')
							.replace(/\s*$/gm, '')
							.trim(),
					},
				}
			)
			if (response.status >= 500) {
				event.push('Server busy')
				eventPayload['status'] = response.status
				toast.error(errorMessages.serverBusy)
			} else if (error) {
				event.push('Error')
				eventPayload['error'] = error
				toast.error(error?.message || errorMessages.unexpectedError)
			}
			if (result) {
				event.push('Success')
			}
			captureEvent(event.join(' - '), eventPayload)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: gameDetailsQueryKeys.details(game.slug) })
			queryClient.invalidateQueries({ queryKey: gameCommentQueryKeys.details(game.slug) })
		},
	})
}
