import { forwardRef, useImperativeHandle, useState } from 'react'

import styles from './collapse.module.scss'

export type Ref = {
  setState: (isExpanded: boolean) => void
}

type Props = React.PropsWithChildren<{
  defaultExpanded?: boolean
  isExpanded?: boolean
}>

export const Collapse = forwardRef<Ref, Props>(
  (
    { defaultExpanded = false, isExpanded, children },
    forwardedRef
  ) => {
    const [localIsExpanded, setLocalIsExpanded] = useState<boolean>(defaultExpanded)

    useImperativeHandle(forwardedRef, () => ({
      setState: setLocalIsExpanded,
    }), [])

    return (
      <div
        className={styles.outer}
        data-expanded={isExpanded ?? localIsExpanded ? 'true' : undefined}
      >
        <div data-slot="inner">
          {children}
        </div>
      </div>
    )
  }
)
