import { Helmet } from 'react-helmet-async'

import { GameList } from '#/components/views/browse-games'
import { useCaptureScreen } from '#/hooks'

import { Back } from '../common/back'
import { FilterColumn, GameFilterCategory } from '../views/filters'
import styles from './games.module.scss'

export const BrowseGamesScreen: React.FC = () => {
  useCaptureScreen('Game Browser')

  return (
    <div className={styles.screen}>
      <Helmet>
        <title>Browse Game - Mavis Hub</title>
      </Helmet>

      <div className={styles.container}>
        <aside className={styles.aside}>
          <div className={styles.navigation}>
            <Back href="/" />
          </div>
          <FilterColumn
            filters={[
              GameFilterCategory.Partnership,
              GameFilterCategory.Genre,
              GameFilterCategory.Platform,
            ]}
          />
        </aside>
        <section className={styles.body}>
          <GameList />
        </section>
      </div>
    </div>
  )
}
