import { ExternalLinkIcon } from '@axieinfinity/dango-icons'
import { Button, Checkbox, Intent, Modal } from '@axieinfinity/konan'
import { useEffect, useState } from 'react'

import styles from './open-external.module.scss'

export const OpenExternalDialog = () => {
  const [requestUrl, setRequestUrl] = useState<string | undefined>(undefined)
  const [isTrusted, setIsTrusted] = useState(true)

  useEffect(() => {
    const unsubscribe = window.bridge?.kataru.on(
      'app:request_open_external',
      (_, url) => setRequestUrl(url)
    )

    return () => void unsubscribe?.()
  }, [])

  const confirm = (close: () => void) => () => {
    if (requestUrl) {
      window.bridge?.kataru.invoke('app:confirm_open_external', requestUrl, isTrusted)
    }
    close()
  }

  return (
    <Modal
      isDismissable
      isOpen={Boolean(requestUrl)}
      target={() => null}
      onOpenChange={(isOpen) =>
        isOpen === false ? setRequestUrl(undefined) : void 0}
    >
      {({ close }) => (
        <div className={styles.content}>
          <h5 className={styles.header}>Leaving Mavis Hub</h5>
          <div className={styles.description}>
            We&apos;re just checking that you want to open a link to this
            website: <span className={styles.link}>{requestUrl}</span>
          </div>
          <div className={styles.trust}>
            <Checkbox
              label="Trust this domain"
              checked={isTrusted}
              onClick={() => setIsTrusted(prev => !prev)}
            />
          </div>
          <div className={styles.footer}>
            <Button text="Cancel" onClick={close} />
            <Button rightIcon={ExternalLinkIcon} text="Follow link" intent={Intent.Primary} onClick={confirm(close)} />
          </div>
        </div>
      )}
    </Modal>
  )
}
