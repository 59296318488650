import cx from 'classnames'
import { isValidElement } from 'react'
import { match } from 'ts-pattern'

import styles from './section.module.scss'

type Props =
  | React.HTMLAttributes<HTMLElement>
  & {
    heading?: string
    suffix?: React.ReactNode
    pagination?: React.ReactNode
  }

export const Section: React.FC<Props> = ({ className, heading, suffix, pagination, children, ...props }) => {
  return (
    <section {...props} className={cx(className, styles.section)}>
      <div data-slot="header">
        <div data-slot="heading">
          <div data-slot="text">{heading}</div>

          {match(suffix)
            .when(isValidElement, (element) => (
              <>
                <div data-slot="separator" />
                {element}
              </>
            ))
            .otherwise(() => null)}
        </div>

        {match(pagination)
          .when(isValidElement, (element) => (
            <div data-slot="pagination">
              {element}
            </div>
          ))
          .otherwise(() => null)}
      </div>

      <div data-slot="body">
        {children}
      </div>
    </section>
  )
}
