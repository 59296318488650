import { forwardRef, useImperativeHandle, useRef } from 'react'

import styles from './blurry-background.module.scss'

const defaultBGImageUrlVarName = '--default-background-image-url'
const bgImageUrlVarName = '--background-image-url'

export type Ref = {
  setDefaultSource: (src: string) => void
  updateSource: (src: string) => void
}

type Props = {
  defaultSrc?: string
}

export const BlurryBackground = forwardRef<Ref, Props>(({ defaultSrc }, forwardedRef) => {
  const elementRef = useRef<HTMLDivElement>(null)

  useImperativeHandle(forwardedRef, () => ({
    setDefaultSource: (src: string) => {
      elementRef.current?.style.setProperty(defaultBGImageUrlVarName, `url(${src})`)
    },
    updateSource: (src: string) => {
      elementRef.current?.style.setProperty(bgImageUrlVarName, `url(${src})`)
    },
  }))

  return (
    <div
      className={styles.background}
      id="blurry-background"
      style={{
        [defaultBGImageUrlVarName]: `url(${defaultSrc})`,
        [bgImageUrlVarName]: `url(${defaultSrc})`,
      } as CustomCSSProperties}
      ref={elementRef}
    />
  )
})
