import { BaseConnector } from './base-connector'
import { WC_CAIP_CHAIN } from './constants'
import { RoninMobileConnector } from './mobile-connector'

export const isMobileConnector = (connector: BaseConnector): connector is RoninMobileConnector => {
  return connector instanceof isMobileConnector
}

/**
 * Generate a deeplink / universal link to the mobile wallet
 * @param uri URI of the wallet connect pairing
 * @param prefix scheme and hostname. Default is "https://wallet.roninchain.com/"
 * @return a string which can be used to open the mobile wallet
 */
export const toDeepLink = (uri: string, prefix?: string): string => {
  prefix = prefix ?? 'https://wallet.roninchain.com/'
  const encoded = encodeURIComponent(uri)

  return `${prefix}auth-connect?uri=${encoded}`
}

export const openMobileWallet = (uri?: string) => {
  window.open(uri || 'https://wallet.roninchain.com', '_self')
}

export const toCaipChain = (chainId: number) => {
  return `${WC_CAIP_CHAIN}:${chainId}`
}
