import { useQuery } from '@tanstack/react-query'

import { logger } from '#/core/logger'
import { services } from '#/core/services'

export const homeBannerQueryKey = ['home_banner']
export const useHomeBanner = () => {
  return useQuery({
    queryKey: homeBannerQueryKey,
    queryFn: async () => {
      try {
        const { response, error, result } = await services.request('get /v2/public/banners')

        if (response.status === 500 || error) {
          return []
        }

        return result
      } catch (e) {
        logger.error(e, { error: e }, 'useHomeBanner')

        return []
      }
    },
  })
}
