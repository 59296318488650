import { AxieRotationCaptcha, type AxieRotationCaptchaData, type AxieRotationCaptchaRef } from '@axieinfinity/captcha'
import { ResponseStatusCode } from '@axieinfinity/hub-services'
import config from '@mavishub/config'
import { createRoninMobileConnector, RoninMobileConnector } from '@mavishub/connect'
import { useSetAtom } from 'jotai'
import { useCallback, useEffect, useRef, useState } from 'react'
import { match, P } from 'ts-pattern'

import { Skeleton } from '#/components/common/loader/skeleton'
import { QR } from '#/components/common/qr'
import { persistStorage } from '#/core/local-storage'
import { logger } from '#/core/logger'
import { services } from '#/core/services'
import { twoFactorTokenAtom } from '#/core/stores'
import { useHandler, useLoginDialog } from '#/hooks'
import { useCaptureEvent } from '#/hooks/tracking'

import { prepareWeb3Auth } from './helper'
import styles from './mobile-qr.module.scss'
import { QROverlay } from './qr-overlay'

type AuthPayload = {
  message: string
  signature: string
}

export type LoginState = 'init' | 'wait_for_scan' | 'wait_for_confirm' | 'expired' | 'error' | 'logged_in'
const expireTime = 60

const wckeys = [
  'wc@2:client:0.3//proposal',
  'wc@2:universal_provider:/namespaces',
  'wc@2:core:0.3//subscription',
  'wc@2:core:0.3//keychain	',
  'wc@2:core:0.3//messages',
  'wc@2:core:0.3//history',
  'wc@2:client:0.3//session',
  'wc@2:core:0.3//expirer',
  'wc@2:core:0.3//pairing',
  'wc@2:ethereum_provider:/chainId',
  'wc@2:universal_provider:/optionalNamespaces',
]

export const LoginQRCode: React.FC = () => {
  const captureEvent = useCaptureEvent()
  const { isVisible } = useLoginDialog()

  const setTwoFactorInfo = useSetAtom(twoFactorTokenAtom)
  const { handleLogin, handleOverlay } = useHandler()

  const [error, setError] = useState<string>('')
  const [connector, setConnector] = useState<RoninMobileConnector | null>(null)
  const [address, setAddress] = useState<string | undefined>(undefined)
  const [uri, setUri] = useState<string | undefined>(undefined)
  const [state, setState] = useState<LoginState>('init')
  const [authPayload, setAuthPayload] = useState<AuthPayload | undefined>(undefined)
  const captchaRef = useRef<AxieRotationCaptchaRef>(null)

  useEffect(() => void setState('init'), [isVisible])

  const setup = useCallback(async () => {
    try {
      setError('')
      wckeys.forEach((key) => localStorage.removeItem(key))
      const connector = await createRoninMobileConnector({
        clientMeta: {
          description: 'Mavis Hub',
          url: 'https://hub.skymavis.com',
          icons: ['https://welcome.skymavis.com/mavis-hub-2.png'],
          name: 'Mavis Hub',
        },
      })
      setConnector(connector)
      setState('wait_for_scan')
    } catch (error) {
      logger.error(error, { error })
      setError(error instanceof Error ? error.message : 'Something went wrong')
    }
  }, [setError])

  useEffect(() => void (isVisible && setup()), [isVisible, setup])

  useEffect(() => {
    const isWaitingForScan = state === 'wait_for_scan'
    if (!connector || !isWaitingForScan) return

    const onUriChange = (uri: string) => setUri(uri)
    const onAddressChange = (address: string) => setAddress(address)
    const call = async () => {
      if (!connector) return
      connector.on('display_uri', onUriChange)
      connector.on('account_changed', onAddressChange)
      const connect = await connector.connect()
      if (connect.account) setAddress(connect.account)
      setState('wait_for_confirm')
    }
    call()

    return () => {
      connector.off('display_uri', onUriChange)
      connector.off('onAddressChange', onAddressChange)
    }
  }, [connector, state])

  useEffect(() => {
    const call = async () => {
      const isWaitingForConfirm = state === 'wait_for_confirm'
      if (!address || !connector || !isVisible || !isWaitingForConfirm) return

      let err = null
      try {
        const { message, signature } = await prepareWeb3Auth(connector, address)

        setAuthPayload({ message, signature })
        captchaRef.current?.init()
      } catch (error) {
        err = error instanceof Error ? error.message : 'Something went wrong'
      } finally {
        captureEvent('QR Login', { err })
        if (err) {
          setState('error')
          setError(err)
        } else {
          setState('logged_in')
        }
      }
    }
    call()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, connector, handleLogin, isVisible, setError, state])

  const handleCaptchaValidationSuccess = useCallback(async (
    captchaData: AxieRotationCaptchaData
  ) => {
    if (authPayload === undefined) {
      return
    }

    let err = null

    try {
      const { response, error, result } = await services.request('post /v2/auth/login-with', {
        headers: { 'x-captcha': captchaData.token },
        body: { service: 'ronin', ...authPayload },
      })

      if (response.status >= 500) {
        err = 'Connection to server error'

        return
      }

      if (error) {
        match(error)
          .with(
            {
              code: ResponseStatusCode.TwoFactorRequired,
              details: { mfaToken: P.string, expiresAt: P.string },
            },
            ({ details }) => setTwoFactorInfo(details)
          )
          .otherwise(() => {
            err = error.message || 'Something went wrong'
          })

        return
      }

      await handleLogin(result, persistStorage.getIsSession())
      handleOverlay()
    } catch (error) {
      captureEvent('QR Login', { err })
      if (err) {
        setState('error')
        setError(err)
      } else {
        setState('logged_in')
      }
    }
  }, [authPayload, handleLogin, handleOverlay, setTwoFactorInfo, captureEvent, setError])

  const handleCaptchaValidationFailure = useCallback((message: string) => {
    captureEvent('Login', { error: message })
    captchaRef.current?.close()
    setAuthPayload(undefined)
    setState('error')
    setError('The captcha is incorrect. Please try again.')
  }, [captureEvent, setError])

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (state === 'wait_for_scan' && isVisible) {
      timer = setTimeout(() => setState('expired'), expireTime * 1000)
    }

    return () => void clearTimeout(timer)
  }, [state, isVisible])

  return (
    <>
      <section className={styles.container}>
        <div className={styles.qrContainer}>
          {uri === undefined ? (
            <Skeleton width={160} height={160} />
          ) : (
            <div className={styles.qr}>
              <QR value={uri} logo="/assets/ronin.svg" />
              <QROverlay state={state} />
            </div>
          )}
        </div>
        <div className={styles.description}>
        {error ? (
          <span className={styles.error}>{error}</span>
        ) : (
          'Scan this QR code with Ronin Mobile Wallet to continue.'
        )}</div>
      </section>

      <AxieRotationCaptcha
        ref={captchaRef}
        url={config.captchaEndpoint}
        apiKey={config.captchaKey}
        onSuccess={handleCaptchaValidationSuccess}
        onFailure={handleCaptchaValidationFailure}
      />
    </>
  )
}
