import customProtocolCheck from 'custom-protocol-check'
import { useNavigate } from 'react-router'

import { useCaptureEvent } from '#/hooks'
import { isBrowsingOnWeb } from '#/utils'

type Props = {
  open: string
  onSuccess?: (() => void) | undefined
  timeout?: number
  children?: React.ReactNode
  onUnsupported?: (() => void) | undefined
} & (
    | {
      orNavigate: string
      onFail?: (() => void) | undefined
    }
    | {
      orNavigate?: string
      onFail: (() => void) | undefined
    }
  )

export const Deeplink: React.FC<Props> = ({
  open,
  orNavigate,
  onFail,
  onSuccess,
  timeout = 1000,
  onUnsupported,
  children,
}) => {
  const navigate = useNavigate()
  const captureEvent = useCaptureEvent()

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    customProtocolCheck(
      open,
      () => {
        if (orNavigate) {
          try {
            const url = new URL(orNavigate)
            if (url.origin === window.location.origin) {
              navigate(url.toString())
            } else {
              window.open(url, '_blank')?.focus()
            }
          } catch (_) {
            if (orNavigate.startsWith('/')) navigate(orNavigate)
          }
        }
        onFail?.()
        captureEvent('Click deeplink', { open, type: 'failure', navigate: orNavigate })
      },
      () => {
        onSuccess?.()
        captureEvent('Click deeplink', { open, type: 'success' })
      },
      timeout,
      () => {
        onUnsupported?.()
        captureEvent('Click deeplink', { open, type: 'unsupported' })
      }
    )
  }

  if (isBrowsingOnWeb) {
    return (
      <a href={open} onClick={handleClick}>
        {children}
      </a>
    )
  }

  return children
}
