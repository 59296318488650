import { useAtom, useAtomValue } from 'jotai'
import { useEffect, useRef } from 'react'

import { appVersionAtom, isDevModeAtom } from '#/core/stores'
import { useCaptureEvent } from '#/hooks/tracking'

const cheatCode = [
  'ArrowUp',
  'ArrowUp',
  'ArrowDown',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'ArrowLeft',
  'ArrowRight',
  'm',
  'v',
  'h',
] as const

export const useCheatCode = () => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [isDevMode, setDevMode] = useAtom(isDevModeAtom)
  const appVersion = useAtomValue(appVersionAtom)

  const captureEvent = useCaptureEvent()

  useEffect(() => {
    if (appVersion && isDevMode) captureEvent('Enable Dev Mode', { appVersion })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appVersion, isDevMode])

  useEffect(() => {
    if (!inputRef.current) return
    let streak = 0
    inputRef.current.onkeydown = (e: KeyboardEvent) => {
      if (e.key === cheatCode[streak]) {
        streak += 1
        if (streak === cheatCode.length) setDevMode(true)
      } else if (streak !== 0) {
        streak = 0
      }
    }
  }, [setDevMode])

  return inputRef
}
