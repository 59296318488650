import { Button, Intent, Modal, Typography } from '@axieinfinity/konan'
import { GameDetails } from '@axieinfinity/hub-services'
import CharacterCount from '@tiptap/extension-character-count'
import Placeholder from '@tiptap/extension-placeholder'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import cx from 'classnames'
import { useEffect, useState } from 'react'
import { Markdown } from 'tiptap-markdown'

import { useComment } from '#/hooks/query'

import styles from './vote.module.scss'

type Props = {
	isOpen: boolean
	onOpenChange: (state: boolean) => void
	isMobileViewPort?: boolean
	game: GameDetails
}

const commentMaxLength = 1_000
const extensions = [
	StarterKit,
	Placeholder.configure({
		placeholder: 'Write your comment here...',
	}),
	Markdown.configure({
		html: false,
	}),
	CharacterCount.configure({
		limit: commentMaxLength,
		mode: 'textSize',
	}),
]

export const CommentDialog: React.FC<Props> = ({
	isOpen,
	onOpenChange,
	isMobileViewPort,
	game,
}) => {
	const [content, setContent] = useState('')
	const [length, setLength] = useState(0)
	const editor = useEditor({
		extensions,
		content: '',
		autofocus: 'end',
		onUpdate({ editor }) {
			setContent(editor.storage.markdown.getMarkdown())
			setLength(editor.storage.characterCount.characters())
		},
	})

	useEffect(() => {
		editor?.commands.focus()
	}, [editor?.commands])

	const { mutateAsync: handleSubmitComment, isPending } = useComment(game)

	return (
		<Modal
			isDismissable
			turnIntoDrawerOnMobile
			isOpen={isOpen}
			onOpenChange={onOpenChange}
		>
			{({ close }) => (
				<div
					className={cx(styles.dialogNote, {
						[styles.desktop]: !isMobileViewPort,
					})}
				>
					<Typography.Heading level="5" className={styles.heading}>
						Do you recommend this game?
					</Typography.Heading>
					<Typography.Text>
						Please describe what you like or dislike about this game and whether
						you recommend this game to others.
					</Typography.Text>
					<div className={styles.comment}>
						<EditorContent
							autoFocus
							editor={editor}
							className={styles.editor}
						/>
					</div>
					<div className={styles.commentLength}>
						{length}/{commentMaxLength}
					</div>
					<div className={styles.actions}>
						<Button text="Maybe later" disabled={isPending} onClick={close} />
						<Button
							intent={Intent.Primary}
							disabled={isPending}
							text="Submit review"
							onClick={async () => {
								await handleSubmitComment({ content })
								close()
							}}
						/>
					</div>
				</div>
			)}
		</Modal>
	)
}
