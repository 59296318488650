import { CheckmarkCircleIcon } from '@axieinfinity/dango-icons'
import { Typography } from '@axieinfinity/konan'
import cx from 'classnames'

import styles from './vote.module.scss'

export const VoteRequirementContent: React.FC<{
  accountAgeRequirementMet: boolean
  isMobileViewPort?: boolean
}> = ({ accountAgeRequirementMet, isMobileViewPort }) => {
  const required = [accountAgeRequirementMet]
  const completed = required.filter(item => item).length

  return (
    <div
      className={cx(styles.dialogNote, { [styles.desktop]: !isMobileViewPort })}
    >
      <div className={styles.heading}>
        <img src="/assets/huhu.png" />
      </div>
      <Typography.Heading level="5" className={styles.heading}>
        You are not eligible for voting!
      </Typography.Heading>
      <Typography.Text>
        To unlock voting for this game, you must meet the following conditions:
      </Typography.Text>
      <div>
        <span className={styles.completeText}>
          Complete {completed}/{required.length}
        </span>
        <div className={styles.completeProgress}>
          <div style={{ width: (completed / 3) * 100 + '%' }} />
        </div>
      </div>
      <div className={styles.completeStep}>
        <CheckmarkCircleIcon
          className={styles.icon}
          data-active={accountAgeRequirementMet}
          size={20}
        />
        <Typography.Text>
          Your account has been registered for at least 24 hours.
        </Typography.Text>
      </div>
      {/* <div className={styles.completeStep}>
          <CheckmarkCircleIcon
            className={styles.icon}
            data-active={gameInLibraryRequirementMet}
            size={20}
          />
          <Typography.Text>
            The game has been added to your library.
          </Typography.Text>
        </div>
        <div className={styles.completeStep}>
          <CheckmarkCircleIcon
            className={styles.icon}
            data-active={sessionPlayedRequirementMet}
            size={20}
          />
          <Typography.Text>
            You have played 1 session of the game on Mavis Hub.
          </Typography.Text>
        </div> */}
    </div>
  )
}
