import { WarningErrorIcon } from '@axieinfinity/dango-icons'

import { Back } from '#/components/common/back'
import { Well } from '#/components/common/well'
import { useEventDetails } from '#/hooks/query'

import { EventBannerTitle } from '../event/text'
import styles from './header.module.scss'

export const GreenlightHeader = () => {
  const { data } = useEventDetails('greenlight')

  if (!data) return null

  return (
    <div className={styles.header}>
      <div className={styles.banner}>
        <div className={styles.container}>
          <Back href="/" className={styles.back} />

          <div className={styles.entry}>
            <div className={styles.content}>
              <EventBannerTitle text={data.title} />
              <div className={styles.disclaimer}>
                <Well icon={<WarningErrorIcon className={styles.icon} />} title="Disclaimer" className={styles.well}>
                  {data.description.replace(/^Disclaimer: /, '')}
                </Well>
              </div>
            </div>
            <div className={styles.mascot}>
              <img src="/assets/buba-mascot.png" alt="Buba" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
