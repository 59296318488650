import { EditIcon } from '@axieinfinity/dango-icons'
import { Button, ButtonVariant, Dialog, Input, Intent, Typography } from '@axieinfinity/konan'
import { GameDetails } from '@axieinfinity/hub-services'
import cx from 'classnames'
import { useEffect, useState } from 'react'
import { toast } from 'sonner'
import { match, P } from 'ts-pattern'

import { Tooltip } from '#/components/common/tooltip'
import handlers from '#/core/handlers'
import { logger } from '#/core/logger'
import { useCaptureEvent, useGameDialog } from '#/hooks'
import { convertBytes, isUsingDarwin, sleep } from '#/utils'

import styles from './game.module.scss'

export const GameInstallationDialog: React.FC = () => {
	const { isVisible, resource, closeDialog } = useGameDialog()
	const [installDir, setInstallDir] = useState<string | undefined>(undefined)
	const [freeSpace, setFreeSpace] = useState<number>(0)
	const captureEvent = useCaptureEvent()

	useEffect(() => {
		if (isVisible('install')) {
			if (!installDir) handlers.getDefaultInstallDir()?.then(setInstallDir)
			if (installDir) handlers.getFreeSpace(installDir)?.then(setFreeSpace)
		} else {
			sleep(500).then(() => {
				setInstallDir(undefined)
				setFreeSpace(0)
			})
		}
	}, [installDir, isVisible])

	const gameRequiredSize = match(
		resource?.latestVersion?.resources?.find((resource) => resource.os === (isUsingDarwin ? 'macos' : 'windows'))
	)
		.with({ size: P.number }, (resource) => resource.size)
		.otherwise(() => 0)

	function installGame(game: GameDetails) {
		return async () => {
			const { id, name, slug } = game
			captureEvent('Start Installation', { game: { id, name, slug } })

			logger.info(`Request to install game: ${game.slug}`)
			const result = await handlers.requestInstallGame({
				slug: game.slug,
				name: game.name,
				location: installDir,
			})

			match(result)
				.with(P.string, (message) => toast(message))
				.otherwise((result) => {
					if (!result) {
						toast(`Something went wrong when request to install game ${game.name}`)

						return
					}
					closeDialog()
				})
		}
	}

	const changeInstallDir = async () => {
		captureEvent('Click Edit Installation location', {
			game: {
				id: resource?.id,
				name: resource?.name,
				slug: resource?.slug,
			},
		})

		const location = await handlers.selectDestinationFolder()
		if (location) setInstallDir(location)
	}

	const cannotEstimateDiskSpace = freeSpace <= 0
	const isEnoughDiskSpace = gameRequiredSize < freeSpace

	if (!resource) return null

	return (
		<Dialog size="large" visible={isVisible('install')} onClose={closeDialog}>
			{resource !== null && (
				<section className={styles.section}>
					<Typography.Heading level="5">Install {resource.name}</Typography.Heading>

					<div className={styles.information}>
						<div className={styles.logo} style={{ backgroundImage: `url(${resource.logoUrl})` }} />
						<Typography.Text level="2">{resource.name}</Typography.Text>
					</div>

					<div className={styles.location}>
						<Typography.Text className={styles.label} level="2">
							Install location:
						</Typography.Text>
						<Input className={styles.input} readOnly value={installDir || ''} />
						<Button
							className={styles.button}
							intent={Intent.Primary}
							variant={ButtonVariant.Plain}
							icon={EditIcon}
							text="Edit"
							onClick={changeInstallDir}
						/>
					</div>

					<div className={styles.diskSpace}>
						<div className={styles.row}>
							<Typography.Text className={styles.label} level="2">
								Disk space required:
							</Typography.Text>
							<Typography.Text>{convertBytes(gameRequiredSize || 0)}</Typography.Text>
						</div>
						<div className={styles.row}>
							<Typography.Text className={styles.label} level="2">
								Disk space available:
							</Typography.Text>
							<div
								className={cx(styles.available, {
									[styles.error]: !isEnoughDiskSpace,
									[styles.warning]: cannotEstimateDiskSpace,
								})}
							>
								<Typography.Text className={styles.text}>{convertBytes(freeSpace)}</Typography.Text>
								{cannotEstimateDiskSpace ? (
									<Tooltip side="right" content="Unable to determine available free space on your computer. Please verify manually to ensure your disk has enough space for game installation.">
										<span className={styles.question}>
											!
										</span>
									</Tooltip>
								) : !isEnoughDiskSpace && (
									<Tooltip side="right" content="Your disk doesn't have enough space to install this game.">
										<span className={styles.question}>
											!
										</span>
									</Tooltip>
								)}
							</div>
						</div>
					</div>

					<Button
						className={styles.install}
						intent={cannotEstimateDiskSpace ? Intent.Danger : Intent.Primary}
						autoFocus
						disabled={!cannotEstimateDiskSpace && !isEnoughDiskSpace}
						text={cannotEstimateDiskSpace ? 'Install anyway' : 'Start Installation'}
						onClick={installGame(resource)}
					/>
				</section>
			)}
		</Dialog>
	)
}
