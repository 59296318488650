import { config } from '@mavishub/config'
import cx from 'classnames'

import { LoadableImage } from '#/components/common/loader'
import { useMapMerchants } from '#/external/store/hooks'
import { formatAmount } from '#/external/store/number'
import { StoreItem } from '#/external/store/types'
import { getTokenInstanceBySymbol } from '#/external/store/web3'
import { useMoss } from '#/hooks'

import styles from './card.module.scss'

type Props = {
  item: StoreItem
  type?: 'horizontal' | 'vertical'
}

export const StoreCard: React.FC<Props> = ({ item, type = 'vertical' }) => {
  const { captureScreen } = useMoss()
  const merchants = useMapMerchants()
  const merchantSlug = merchants[item.merchantId]?.slug
  const token = getTokenInstanceBySymbol(item.price.currency)
  const { text: priceText } = formatAmount(
    item.price.amount,
    token.decimals
  )
  const isOutOfStock = item.availableStock === 0
  const externalStoreUrl = `${config.storeDomain}/stores/${merchantSlug}/${item.slug}?source=ref-mavis-hub`
  const badge = isOutOfStock && 'Out of Stock'
  const unitImg = item.display?.images.unit
  const displayName = item.display?.name

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    window.open(externalStoreUrl, '_blank')?.focus()

    captureScreen(`Game Details - Click on store ${merchantSlug} with item ${item.slug}`)
  }

  return (
    <a
      href={externalStoreUrl}
      className={cx(styles.container, { [styles.disabled]: isOutOfStock })}
      data-type={type}
      onClick={handleClick}
    >
      <div className={cx(styles.image, { [styles.disabled]: isOutOfStock })}>
        <LoadableImage src={item.display?.images?.listing} alt={item.name} />
      </div>
      <div className={styles.content}>
        <div className={styles.name}>{item.name}</div>
        <div className={styles.unit}>
          {unitImg && <img src={unitImg} alt={displayName} />}
          <span>{displayName}</span>
        </div>
        <div className={styles.price}>{`${priceText} ${token.symbol}`}</div>
      </div>
      {badge && <div className={cx(styles.badge)}>{badge}</div>}
    </a>
  )
}
