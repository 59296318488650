import { FlagIcon, LikeIcon } from '@axieinfinity/dango-icons'
import { Button, Size } from '@axieinfinity/konan'
import { GameComment } from '@axieinfinity/hub-services'
import cx from 'classnames'
import { useEffect, useMemo, useReducer, useRef, useState } from 'react'

import { Markdown } from '#/components/common/markdown'

import styles from './comment.module.scss'

type Props = {
	type: 'upvote' | 'downvote'
	comment: GameComment
}

export const Comment: React.FC<Props> = ({ type, comment }) => {
	const containerRef = useRef<HTMLDivElement>(null)

	const [isExpanded, toggleExpand] = useReducer((prev) => !prev, false)
	const [shouldCollapse, setShouldCollapse] = useState<boolean | undefined>(
		undefined
	)

	useEffect(() => {
		setShouldCollapse(
			(containerRef.current &&
				Math.floor(containerRef.current.getBoundingClientRect().height) >=
				244) ??
			undefined
		)
	}, [])

	const createdAt = useMemo(() => {
		return {
			full: new Intl.DateTimeFormat('en-US', {
				dateStyle: 'full',
				timeStyle: 'full',
			}).format(comment.createdAt * 1000),
			compact: new Intl.DateTimeFormat('en-US', {
				dateStyle: 'medium',
				timeStyle: 'short',
			}).format(comment.createdAt * 1000),
		}
	}, [comment.createdAt])

	return (
		<div className={styles.comment} data-vote={type}>
			<div className={styles.vote}>
				<div className={styles.thumb}>
					<LikeIcon size={24} />
				</div>
				<div>
					<div className={styles.type}>
						{type === 'upvote' ? 'Recommended' : 'Not Recommended'}
					</div>
					<div className={styles.user}>
						<span className={styles.name}>
							{comment.user?.name ?? 'Unknown Person'}
						</span>
					</div>
				</div>
			</div>

			<div
				ref={containerRef}
				className={cx(
					styles.commentContainer,
					isExpanded && styles.expanded,
					!isExpanded && shouldCollapse && styles.collapsed
				)}
			>
				<Markdown className={styles.content}>{comment.content}</Markdown>
			</div>

			{shouldCollapse && (
				<button className={styles.expand} onClick={toggleExpand}>
					Show {isExpanded ? 'less' : 'more'}
				</button>
			)}

			<div className={styles.footer}>
				<div className={styles.date} title={createdAt.full}>
					Posted on {createdAt.compact}
				</div>

				<div className={styles.actions}>
					{null && <Button text={<FlagIcon />} size={Size.Small} />}
				</div>
			</div>
		</div>
	)
}
