import { useNavigate } from 'react-router'

import { Back } from '#/components/common/back'
import { AGJHeader } from '#/components/views/axie-game-jam/header'
import { AGJGameList } from '#/components/views/axie-game-jam/list'
import { FilterColumn, GameFilterCategory } from '#/components/views/filters'
import { useCaptureEvent, useCaptureScreen } from '#/hooks'

import styles from './submissions.module.scss'

const tabs = [
  {
    name: 'overview',
    label: 'Overview',
    href: '/axie-game-jam/2023/#overview',
  },
  {
    name: 'result',
    label: 'Result',
    href: '/axie-game-jam/2023/#result',
  },
  {
    name: 'submission',
    label: 'Submission',
    href: '/axie-game-jam/2023/submissions',
    active: true,
  },
]

export const AxieGameJam2023Submissions = () => {
  useCaptureScreen('Axie Game Jam 2023 Submission')
  const captureEvent = useCaptureEvent()
  const navigate = useNavigate()

  return (
    <div className={styles.screen}>
      <AGJHeader className={styles.header}>
        <div className={styles.navigate}>
          <Back href="/axie-game-jam/2023">
            Back to Event
          </Back>
        </div>
        <div className={styles.logo} />
        <div className={styles.rocket} />
      </AGJHeader>
      <div className={styles.container}>
        <div className={styles.tabs}>
          {tabs.map((item) => (
            <button
              className={styles.tab}
              key={item.name}
              title={item.label}
              data-active={item.active}
              onClick={() => {
                navigate(item.href)
                captureEvent('Click AGJ tab ' + item.name)
              }}
            >
              {item.label}
            </button>
          ))}
        </div>
        <div className={styles.entry}>
          <aside>
            <FilterColumn
              filters={[
                GameFilterCategory.Genre,
                GameFilterCategory.Platform,
              ]}
            />
          </aside>
          <div className={styles.body}>
            <AGJGameList />
          </div>
        </div>
      </div>
    </div>
  )
}
