import styles from './tab-title.module.scss'

export const TabTitle: React.FC = () => {
  return (
    <div className={styles.container}>
      <span>Items</span>
      <span className={styles.tag}>New</span>
    </div>
  )
}
