import { Back } from '#/components/common/back'
import { AGJContent } from '#/components/views/axie-game-jam/content'
import { AGJHeader } from '#/components/views/axie-game-jam/header'
import { useCaptureScreen } from '#/hooks'

import styles from './tos.module.scss'

export const AxieGameJamToS = () => {
  useCaptureScreen('Axie Game Jam - Terms of Service')

  return (
    <div className={styles.screen}>
      <AGJHeader>
        <div className={styles.navigate}>
          <Back href="/" />
        </div>
        <div className={styles.logo} />
        <p className={styles.description}>
          Axie Game Jam is an epic annual event hosted by Sky Mavis. This is an opportunity for game developers,
          designers, and enthusiasts from around the world to showcase their unique and innovative creations, win cash
          prizes and get their games featured on Mavis Hub!
        </p>
      </AGJHeader>
      <AGJContent />
    </div>
  )
}
