import { ArrowLeftIcon, ArrowRightIcon } from '@axieinfinity/dango-icons'
import type { GameDetails, Pagination } from '@axieinfinity/hub-services'
import { Button, Input } from '@axieinfinity/konan'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { Link } from 'react-router-dom'
import { match, P } from 'ts-pattern'

import { Skeleton } from '#/components/common/loader'
import { usePaginationQuery } from '#/hooks'

import { ValidatingIndicator } from '../indicators/validate'
import { GameCard } from './card'
import styles from './grid.module.scss'

type Props = {
  isLoading: boolean
  error?: unknown
  data?: GameDetails[]
  emptyImage?: string
  emptyMessage?: string
  navigationParams?: URLSearchParams
  onClickGameCard?: (game: GameDetails) => void
  pagination?: Pagination
}

const EmptyPage: React.FC<{
  message: Props['emptyMessage']
  image: Props['emptyImage']
}> = ({ message, image }) => (
  <figure className={styles.placeholder}>
    <img
      className={styles.image}
      src={image || '/assets/momo-empty-result.png'}
    />
    <figcaption className={styles.caption}>
      {message || 'Unable to find items matching your filters'}
    </figcaption>
  </figure>
)

export const GameGrid: React.FC<Props> = ({
  isLoading,
  data,
  error,
  emptyImage,
  emptyMessage,
  navigationParams,
  onClickGameCard,
  pagination,
}) => {
  const { page: queryPage, setPage } = usePaginationQuery()
  const paginationInputRef = useRef<HTMLInputElement>(null)

  const totalPage = useMemo(
    () =>
      pagination ? Math.ceil(pagination.totalRows / pagination.pageSize) : 0,
    [pagination]
  )
  const page = queryPage ? queryPage : (pagination?.page ?? 1)

  useEffect(() => {
    if (
      queryPage &&
      ((totalPage > 0 && queryPage > totalPage) || queryPage < 1)
    ) {
      setPage(1)

      return
    }
  }, [queryPage, setPage, totalPage])

  const goToPage = useCallback(
    (to: number | 'prev' | 'next') => {
      const next = match(to)
        .with('next', () => Math.min(page + 1, totalPage))
        .with('prev', () => Math.max(page - 1, 1))
        .with(P.number, page => Math.max(1, Math.min(page, totalPage)))
        .exhaustive()
      setPage(next)
      if (paginationInputRef.current) {
        paginationInputRef.current.value = next.toString()
      }
    },
    [setPage, page, totalPage]
  )

  return match({ isLoading, error, data })
    .with(
      ({ isLoading: true }),
      () => (
        <div className={styles.grid}>
          {Array.from({ length: 9 }, (_, index) => (
            <Skeleton key={index} corner="rounded" />
          ))}
        </div>
      )
    )
    .with({ data: undefined }, () => null)
    .with({ data: P.when(data => data?.length === 0) }, () => (
      <EmptyPage image={emptyImage} message={emptyMessage} />
    ))
    .with({ data: P.not(P.nullish) }, ({ data }) => (
      <>
        <div className={styles.grid}>
          {data.map(game => (
            <Link
              key={game.slug}
              to={`/games/${game.slug}?${navigationParams?.toString()}`}
              onClick={() => onClickGameCard?.(game)}
            >
              <GameCard {...game} responsive />
            </Link>
          ))}
        </div>
        {pagination && pagination.totalRows > pagination.pageSize && (
          <div className={styles.pagination}>
            <Button
              text={<ArrowRightIcon />}
              disabled={page === 1}
              onClick={() => goToPage('prev')}
            />
            <span>Page</span>
            <Input
              type="number"
              ref={paginationInputRef}
              defaultValue={page}
              onBlur={e => (e.currentTarget.value = page.toString())}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  goToPage(e.currentTarget.valueAsNumber)
                }
              }}
            />
            <span>of {totalPage}</span>
            <Button
              text={<ArrowLeftIcon />}
              disabled={page === totalPage}
              onClick={() => goToPage('next')}
            />
          </div>
        )}
        <ValidatingIndicator isVisible={isLoading} />
      </>
    ))
    .exhaustive()
}
