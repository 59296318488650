import { LikeIcon } from '@axieinfinity/dango-icons'
import { Button, Intent } from '@axieinfinity/konan'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router'

import { EventBannerTitle } from '#/components/views/event/text'
import { useEventDetails } from '#/hooks/query'

import styles from './event.module.scss'

export const HomeEventBanner = () => {
  const navigate = useNavigate()
  const { data } = useEventDetails('greenlight')

  if (!data) return null

  function navigateToEventPage() {
    navigate('/greenlight')
  }

  return (
    <section className={styles.section}>
      <figure className={styles.figure}>
        <div className={styles.mascot} />
        <figcaption className={styles.caption}>
          <div className={styles.content}>
            <EventBannerTitle text={data.title} />
            <div className={styles.subTitle}>{data.subTitle}</div>
          </div>

          <motion.div
            initial={{ scale: 1 }}
            whileHover={{ scale: 0.95 }}
            whileTap={{ scale: 0.9 }}
          >
            <Button
              intent={Intent.Success}
              text="Start voting now!"
              rightIcon={LikeIcon}
              onClick={navigateToEventPage}
            />
          </motion.div>
        </figcaption>
      </figure>
    </section>
  )
}
