import { Remove01Icon } from '@axieinfinity/dango-icons'
import { useAtom } from 'jotai'
import { FC } from 'react'

import { isMobileSearchOpenedAtom } from '#/core/stores'

import Search from '../search'
import styles from './search-overlay.module.scss'

const MobileSearchOverlay: FC = () => {
  const [isMobileSearchOpened, setIsMobileSearchOpened] = useAtom(isMobileSearchOpenedAtom)

  return (
    <div
      className={styles.container}
      data-visible={isMobileSearchOpened ? 'true' : undefined}
    >
      <div className={styles.header}>
        <div className={styles.title}>Search</div>
        <div className={styles.closeIcon} onClick={() => setIsMobileSearchOpened(false)}>
          <Remove01Icon size={24} />
        </div>
      </div>
      <div className={styles.main}>
        <Search />
      </div>
    </div>
  )
}

export default MobileSearchOverlay
