import { DevModeConfig } from '@axieinfinity/kataru'
import { useEffect, useState } from 'react'

import { getDevConfig } from '#/core/handlers/app'

import styles from './dev-mode.module.scss'

export const DevModeView: React.FC = () => {
  const [data, setData] = useState<DevModeConfig>()

  useEffect(() => {
    getDevConfig()?.then((config) => {
      setData(config)
    })
  }, [])

  if (!data || !data.enabled) {
    return null
  }

  return (
    <div className={styles.container}>
      <span>DEV MODE</span>
    </div>
  )
}
