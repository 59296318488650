import { useEffect } from 'react'
import { useNavigate } from 'react-router'

import { useCaptureScreen } from '#/hooks'
import { isBrowsingOnWeb } from '#/utils'

import styles from './2023.module.scss'

export const AxieGameJam2023 = () => {
  useCaptureScreen('Axie Game Jam - 2023 (Preload Page)')
  const navigate = useNavigate()

  useEffect(() => {
    if (isBrowsingOnWeb) {
      window.location.href = '/axie-game-jam/2023/' + window.location.hash
    }
  }, [])

  function handleIframeLoad(e: React.SyntheticEvent<HTMLIFrameElement, Event>) {
    const { contentWindow } = e.currentTarget
    if (!contentWindow) {
      return
    }
    const { origin, pathname } = contentWindow.location
    if (pathname === '/axie-game-jam/2023/') {
      return
    }
    if (origin === window.location.origin) {
      navigate(pathname)

      return
    }
  }

  return (
    <iframe src={`${window.location.origin}/axie-game-jam/2023/${window.location.hash}`} className={styles.screen} onLoad={handleIframeLoad} />
  )
}
