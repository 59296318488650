import { config } from '@mavishub/config'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'

import { logger } from '#/core/logger'
import { convertKeysToCamelCase } from '#/utils'

import {
  Paging,
  PagingInput,
  StoreItem,
  StoreMerchant,
  StoreMerchantCategory,
  StoreStock,
} from './types'

export const useStoreCategoryList = (merchantId: number) => {
  return useQuery({
    queryKey: ['store_category_list', merchantId],
    queryFn: async () => {
      try {
        const response = await fetch(
          `${config.storeGateway}/v1/public/category/${merchantId}/list`
        )

        if (!response.ok) {
          throw new Error('Failed to fetch store category list')
        }

        const result = await response.json()

        return convertKeysToCamelCase<Array<StoreMerchantCategory>>(result)
      } catch (e) {
        logger.error(e, { error: e }, 'useStoreCategoryList')

        throw e
      }
    },
  })
}

export const useStorePublicItemList = (
  filter: { merchantId: number; categoryId: number },
  paging: PagingInput = {
    page: 1,
    limit: 50,
  }
) => {
  return useQuery({
    queryKey: ['store_item_list', filter, paging],
    queryFn: async () => {
      try {
        const itemsResponse = await fetch(
          `${config.storeGateway}/v1/public/item/list?merchant_id=${filter.merchantId}&category_id=${filter.categoryId}&page=${paging.page}&limit=${paging.limit}`
        )

        if (!itemsResponse.ok) {
          throw new Error('Failed to fetch store item list')
        }

        const itemsResult = await itemsResponse.json()
        const itemsData = convertKeysToCamelCase<{
          data: StoreItem[]
          paging: Paging
        }>(itemsResult)

        const codes = itemsData.data.map((item) => item.code)
        const codeUrl = new URL(`${config.storeGateway}/v1/public/item/list/stock`)
        codes.forEach(code => codeUrl.searchParams.append('codes', code))
        const stocksResponse = await fetch(codeUrl.toString())

        if (!stocksResponse.ok) {
          throw new Error('Failed to fetch store stock list')
        }

        const stocksResult = await stocksResponse.json() as Array<StoreStock>
        const stocks = stocksResult.reduce<Record<string, number>>((acc, { code, stock }) => {
          acc[code] = stock

          return acc
        }, {})
        itemsData.data.forEach((item) => {
          item.availableStock = stocks[item.code]
        })

        return itemsData
      } catch (e) {
        logger.error(e, { error: e }, 'useStorePublicItemList')

        throw e
      }
    },
  })
}

export const useStoreMerchantList = () => {
  return useQuery({
    queryKey: ['store_merchant_list'],
    queryFn: async () => {
      try {
        const response = await fetch(
          `${config.storeGateway}/v1/public/merchant/list?page=1&limit=100`
        )

        if (!response.ok) {
          throw new Error('Failed to fetch store merchant list')
        }

        const result = await response.json()

        return convertKeysToCamelCase<{
          data: StoreMerchant[]
          paging: Paging
        }>(result)
      } catch (e) {
        logger.error(e, { error: e }, 'useStoreMerchantList')

        throw e
      }
    },
  })
}

export const useMapMerchants = () => {
  const queryClient = useQueryClient()
  const data = queryClient.getQueryData<{
    data: StoreMerchant[]
    paging: Paging
  }>(['store_merchant_list'])

  if (!data) throw new Error('Merchant list not found')

  const mapData = useMemo(() => {
    return data.data.reduce<Record<number, StoreMerchant>>((acc, item) => {
      acc[item.id] = item

      return acc
    }, {})
  }, [data.data])

  return mapData
}
