import { config } from '@mavishub/config'
import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom/client'

import { Application } from './application'
import { isBrowsingOnDesktop } from './utils'

if (config.sentryDsn) {
  Sentry.init({
    dsn: config.sentryDsn,
    environment: config.env,
  })
}

if (isBrowsingOnDesktop) {
  document.body.classList.toggle('desktop', true)
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Application />
  </React.StrictMode>
)

window.bridge?.kataru.handle('app:user_agent', async () => navigator.userAgent)
postMessage({ payload: 'hub:content_loaded' }, '*')
