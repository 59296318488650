import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import cx from 'classnames'

import styles from './tooltip.module.scss'

type Props = {
  children: React.ReactNode
  content: React.ReactNode
  delayDuration?: number
} & Omit<TooltipPrimitive.TooltipContentProps, 'content'> &
  React.RefAttributes<HTMLDivElement> &
  Pick<TooltipPrimitive.TooltipProps, 'open' | 'defaultOpen' | 'onOpenChange'>

export const Tooltip: React.FC<Props> = ({
  children,
  content,
  open,
  defaultOpen,
  onOpenChange,
  className,
  delayDuration = 300,
  ...props
}) => {
  return (
    <TooltipPrimitive.Provider delayDuration={delayDuration}>
      <TooltipPrimitive.Root
        open={open}
        defaultOpen={defaultOpen}
        onOpenChange={onOpenChange}
      >
        <TooltipPrimitive.Trigger asChild>
          {typeof children === 'string' ? <span>{children}</span> : children}
        </TooltipPrimitive.Trigger>
        <TooltipPrimitive.Content
          align="center"
          {...props}
          className={cx(styles.content, className)}
          sideOffset={4}
        >
          {content}
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  )
}
