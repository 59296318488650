import {
	BookDownload01Icon,
	BookUpload01Icon,
	MobileIcon,
	PlayCircleIcon,
} from '@axieinfinity/dango-icons'
import { ContextMenu, Modal } from '@axieinfinity/konan'
import { GameDetails } from '@axieinfinity/hub-services'
import classNames from 'classnames'
import { Fragment, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { match } from 'ts-pattern'

import { GameButtonState } from '#/constants'
import { useCaptureEvent, useGame, useGameDialog } from '#/hooks'

import { PlayOnMobileContent } from '../dialogs/play-on-mobile-content'
import styles from './menu.module.scss'

type Props = React.PropsWithChildren & {
	game: GameDetails
}

export const LibraryItemContextMenu: React.FC<Props> = ({ game, children }) => {
	const captureEvent = useCaptureEvent()
	const {
		state,
		installed,
		isInstalled,
		isMobileSupported,
		mobileResource,
		launchGame,
	} = useGame(game)
	const { openDialog } = useGameDialog()
	const navigate = useNavigate()

	const [isMobileOpen, setIsMobileOpen] = useState(false)

	const action = useMemo(() => {
		return match(state)
			.with(GameButtonState.Install, () => ({
				label: 'Install',
				icon: <BookDownload01Icon />,
				action: () => openDialog('install', game),
			}))
			.with(GameButtonState.Play, () => ({
				label: 'Play',
				icon: <PlayCircleIcon />,
				action: () => launchGame(),
			}))
			.with(GameButtonState.Update, () => ({
				label: 'Install Update',
				icon: <BookUpload01Icon size={24} />,
				action: () => openDialog('update', game),
			}))
			.otherwise(() => undefined)
	}, [game, launchGame, openDialog, state])

	function captureContextMenuEvent(event: string, props?: object) {
		const { id, name, slug } = game
		captureEvent(`Game Context Menu - ${event}`, {
			game: { id, name, slug, currentVersion: installed?.version },
			...props,
		})
	}

	const onMenuAction = (action: string) => {
		match(action)
			.with('detail', () => {
				captureContextMenuEvent('Click View Game Details')
				navigate(
					`/games/${game.slug}?${new URLSearchParams({
						from: '/library',
						name: 'Library',
					}).toString()}`
				)
			})
			.with('manage', () => {
				captureContextMenuEvent('Click Manage')
				openDialog('manage', game)
			})
			.with('uninstall', () => {
				captureContextMenuEvent('Click Uninstall')
				openDialog('uninstall', game)
			})
			.otherwise(() => null)
	}

	return (
		<Fragment>
			<Modal isOpen={isMobileOpen} isDismissable onOpenChange={setIsMobileOpen}>
				<PlayOnMobileContent
					resource={{
						mobileResources: Object.values(mobileResource).filter(Boolean),
						name: game.name,
					}}
				/>
			</Modal>
			<ContextMenu.Root>
				<ContextMenu.Trigger>{children}</ContextMenu.Trigger>
				<ContextMenu.Portal>
					<ContextMenu.Content className={styles.content} alignOffset={5}>
						{action && (
							<ContextMenu.Item
								className={classNames(styles.item, styles.action)}
								onSelect={() => action.action()}
							>
								{action.icon}
								<span>{action.label}</span>
							</ContextMenu.Item>
						)}
						{isMobileSupported && (
							<ContextMenu.Item
								className={styles.item}
								onSelect={() => setIsMobileOpen(true)}
							>
								<MobileIcon />
								<span>Play on mobile</span>
							</ContextMenu.Item>
						)}
						<ContextMenu.Separator className={styles.separator} />
						<ContextMenu.Item
							className={styles.item}
							onSelect={() => onMenuAction('detail')}
						>
							View game details
						</ContextMenu.Item>
						{isInstalled && (
							<Fragment>
								<ContextMenu.Item
									className={styles.item}
									onSelect={() => onMenuAction('manage')}
								>
									Manage
								</ContextMenu.Item>
								<ContextMenu.Item
									className={styles.item}
									onSelect={() => onMenuAction('uninstall')}
								>
									Uninstall
								</ContextMenu.Item>
							</Fragment>
						)}
					</ContextMenu.Content>
				</ContextMenu.Portal>
			</ContextMenu.Root>
		</Fragment>
	)
}
