import { Modal, Typography } from '@axieinfinity/konan'
import { GameDetails, GameVersionResponse, PatchNoteResponse } from '@axieinfinity/hub-services'
import classNames from 'classnames'

import { Markdown } from '#/components/common/markdown'
import { useCaptureEvent } from '#/hooks'
import { formatDate } from '#/utils'

import styles from './view.module.scss'

type Props = PatchNoteResponse &
	Pick<GameDetails, 'slug'> &
	Pick<GameVersionResponse, 'createdAt'>

export const PatchNote: React.FC<Props> = ({
	title,
	slug,
	thumbnailUrl,
	brief,
	content,
	createdAt,
}) => {
	const captureEvent = useCaptureEvent()

	function handleOpen(callback: () => void) {
		return () => {
			captureEvent('Click Patch Note', { title, game: { slug } })
			callback()
		}
	}

	const patchDate = formatDate(createdAt * 1000, {
		day: '2-digit',
		month: 'long',
		year: 'numeric',
	})

	return (
		<Modal
			isDismissable
			turnIntoDrawerOnMobile
			target={({ open }) => (
				<figure className={styles.card} onClick={handleOpen(open)}>
					<img className={styles.image} src={thumbnailUrl} />
					<figcaption className={styles.caption}>
						<Typography.Text className={classNames(styles.title, { [styles.empty]: !title })} level="1">
							{title || 'No title'}
						</Typography.Text>
						{brief && (
							<Typography.Text className={styles.description}>
								{brief}
							</Typography.Text>
						)}
						<Typography.Text className={styles.date}>
							{patchDate}
						</Typography.Text>
					</figcaption>
				</figure>
			)}
		>
			{({ isMobileViewPort }) => (
				<div className={classNames(styles.body, { [styles.small]: isMobileViewPort })}>
					<div
						className={classNames(styles.header, {
							[styles.thumbnail]: Boolean(thumbnailUrl),
						})}
						style={{
							backgroundImage: thumbnailUrl && `url(${thumbnailUrl})`,
						}}
					>
						<h3 className={classNames(styles.title, { [styles.empty]: !title })}>{title || 'No title'}</h3>
						<small className={styles.date}>{patchDate}</small>
					</div>
					{brief && <div className={styles.brief}>{brief}</div>}
					<Markdown>{content || ''}</Markdown>
				</div>
			)}
		</Modal>
	)
}
