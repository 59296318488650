import { Intent } from '@axieinfinity/konan'
import cx from 'classnames'

import styles from './circular-progress.module.scss'

interface Props {
  intent?: Intent
  size?: number
  strokeWidth?: number
  value: number | 'indeterminate'
}

export const CircularProgress: React.FC<Props> = (props) => {
  const { intent = Intent.Primary, size = 48, strokeWidth = 6, value } = props
  const percentage = value === 'indeterminate' ? 24 : value
  const center = size / 2,
    radius = center - strokeWidth,
    arcLength = 2 * Math.PI * radius,
    arcOffset = arcLength * ((100 - percentage) / 100)

  const commonCircleProps: React.SVGProps<SVGCircleElement> = {
    cx: center,
    cy: center,
    r: radius,
    strokeWidth,
  }

  return (
    <svg className={styles.indicator} style={{ width: size, height: size }}>
      <circle {...commonCircleProps} className={styles.track} />
      <circle
        key={value === 'indeterminate' ? 'indeterminate' : 'progressing'}
        {...commonCircleProps}
        className={cx(styles.indication, {
          [styles.indeterminate]: value === 'indeterminate',
          [styles[Intent.Secondary]]: intent === Intent.Secondary,
        })}
        style={{ strokeDasharray: arcLength, strokeDashoffset: arcOffset }}
      />
    </svg>
  )
}
