export enum GameButtonState {
  ComingSoon = 'Coming Soon',
  Queued = 'Queued',
  Install = 'Install',
  Installing = 'Installing',
  Play = 'Play',
  Playing = 'Playing',
  Update = 'Update',
  NotSupported = 'Not Supported',
  AddToLibrary = 'Add to Library',
}
