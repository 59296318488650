import { getSearchParams } from '@axieinfinity/hub-services'
import { useQuery } from '@tanstack/react-query'
import { toast } from 'sonner'

import { errorMessages } from '#/constants'
import { logger } from '#/core/logger'
import { services } from '#/core/services'

const defaultPagination = {
  lastPage: 1,
  totalRows: 0,
  desc: false,
  pageSize: 18,
  page: 1,
}
const defaultResponse = { data: [], pagination: defaultPagination }

type Options = {
  enabled?: boolean
}

export type GameV3Query = Record<string, Array<string> | string | number>

export const publicGamesKeysV3 = {
  all: ['public_games_v3'] as const,
  filter: (query: GameV3Query) => [...publicGamesKeysV3.all, query] as const,
}
export const usePublicGamesV3 = (query: GameV3Query, options?: Options) => {
  return useQuery({
    queryKey: publicGamesKeysV3.filter(query),
    queryFn: async () => {
      try {
        const { response, error, result } = await services.request(
          'get /v3/public/games?[query]',
          {
            query: getSearchParams(query).toString(),
          }
        )
        if (response.status >= 500) {
          toast.error(errorMessages.serverBusy)

          return defaultResponse
        } else if (error) {
          return defaultResponse
        } else {
          return result
        }
      } catch (e) {
        logger.error(e, { error: e }, 'usePublicGamesV3')

        return defaultResponse
      }
    },
    enabled: options?.enabled,
  })
}
