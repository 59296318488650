import { Facebook01Icon, ShareIcon } from '@axieinfinity/dango-icons'
import { GameDetails } from '@axieinfinity/hub-services'
import { Button, Input, Modal, TwitterXIcon } from '@axieinfinity/konan'
import { config } from '@mavishub/config'
import cx from 'classnames'
import { useEffect, useMemo, useState } from 'react'

import { useCaptureEvent, useHandler } from '#/hooks'

import styles from './share.module.scss'
import shared from './shared.module.scss'

type Props = {
  game: GameDetails
  minimal: boolean
}

export const SocialShare: React.FC<Props> = ({ game, minimal }) => {
  const { handleSetClipboard } = useHandler()
  const captureEvent = useCaptureEvent()

  const shareLink = useMemo(() => {
    const url = new URL(config.uiEndpoint)
    url.pathname = `/games/${game.slug}`

    return url.toString()
  }, [game.slug])
  const shareMessage = `${game.name} on Mavis Hub\n${shareLink}`
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCopied(false)
    }, 3000)

    return () => clearTimeout(timeout)
  }, [])

  const copyShareLink = async () => {
    captureEvent('Click Copy Share Link', { slug: game.slug, name: game.name })
    handleSetClipboard(shareLink)?.then(() => setCopied(true))
  }

  const clickShare = (social: string) => {
    captureEvent('Click Share to ' + social, { slug: game.slug, name: game.name })
  }

  return (
    <Modal
      isDismissable
      turnIntoDrawerOnMobile
      target={({ open }) => (
        <Button
          className={cx(shared.button, shared.animated)}
          {...(minimal ? { text: <ShareIcon /> } : { text: 'Share with friends', icon: ShareIcon })}
          onClick={open}
        />
      )}
    >
      {({ isMobileViewPort }) => (
        <div className={cx(styles.dialogContent, { [styles.desktop]: !isMobileViewPort })}>
          <div className={styles.heading}>
            Share with friends
          </div>
          <div className={styles.copyLink}>
            <Input type="text" defaultValue={shareLink} readOnly className={styles.linkInput} />
            <Button text={copied ? 'Copied' : 'Copy link'} className={styles.copyButton} onClick={copyShareLink} />
          </div>
          <div>
            <div>
              Or share on social media:
            </div>
            <div className={styles.links}>
              <a href={`https://twitter.com/intent/tweet?text=${encodeURI(shareMessage)}`} target="_blank" rel="noopener noreferrer" onClick={() => clickShare('Twitter')}>
                <Button text={<TwitterXIcon width={16} height={16} className={styles.icon} />} />
              </a>
              <a href={`https://facebook.com/sharer.php?u=${encodeURI(shareLink)}&t=${encodeURI(shareMessage)}`} target="_blank" rel="noopener noreferrer" onClick={() => clickShare('Facebook')}>
                <Button text={<Facebook01Icon className={styles.icon} />} />
              </a>
            </div>
          </div>
        </div>
      )}
    </Modal>
  )
}
