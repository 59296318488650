import { Banner } from '@axieinfinity/hub-services'
import { useMemoizedFn } from 'ahooks'
import cx from 'classnames'
import { useEffect, useRef } from 'react'

import { Tooltip } from '#/components/common/tooltip'
import { useCaptureEvent } from '#/hooks'

import { ActiveBanner } from './banner'
import styles from './dots.module.scss'

const updateInterval = 5_000

type Props = {
	items: Banner[]
	isPaused?: boolean
	activeItem?: ActiveBanner
	onChange: (item: ActiveBanner) => void
}

export const Dots: React.FC<Props> = ({ items, isPaused, activeItem, onChange }) => {
	const captureEvent = useCaptureEvent()

	const remaining = useRef<number>(updateInterval)

	const handleItemSelect = useMemoizedFn((item: Banner, index: number) => () => {
		const { id, title } = item
		captureEvent('Click Banner Dot', { id, title, index })
		remaining.current = updateInterval
		onChange({ ...item, index })
	})

	useEffect(() => {
		if (!isPaused && activeItem) {
			const interval = setInterval(() => {
				if (remaining.current === 0) {
					remaining.current = updateInterval
					const currentIndex = items.findIndex((item) => activeItem?.id === item.id)
					const nextItemIndex = currentIndex === items.length - 1 ? 0 : currentIndex + 1
					onChange({ ...items[nextItemIndex], index: nextItemIndex })
				} else {
					remaining.current -= 100
				}
			}, 100)

			return () => clearInterval(interval)
		}
	}, [isPaused, items, activeItem, onChange])

	return (
		<section
			className={styles.section}
			style={{ '--animation-duration': `${updateInterval}ms` } as CustomCSSProperties}
		>
			{items.map((item, index) => (
				<Tooltip
					className={styles.tooltipLayer}
					key={index}
					side="bottom"
					content={
						<div
							className={styles.preview}
							// todo - remove `item.thumbnailURL`
							style={{ backgroundImage: `url(${item.thumbnailUrl || item.thumbnailURL || item.bannerUrl})` }}
						/>
					}
				>
					<div
						className={styles.trigger}
						onClick={handleItemSelect(item, index)}
					>
						<div
							className={cx(styles.dot, {
								[styles.filled]: activeItem && index < activeItem.index,
								[styles.active]: item.id === activeItem?.id,
							})}
							style={{ '--animation-play-state': isPaused ? 'paused' : 'running' } as CustomCSSProperties}
						/>
					</div>
				</Tooltip>
			))}
		</section>
	)
}
