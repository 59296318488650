import { Remove01Icon } from '@axieinfinity/dango-icons'
import { Typography } from '@axieinfinity/konan'
import cx from 'classnames'
import { useAtomValue } from 'jotai'
import { match } from 'ts-pattern'

import { CircularProgress } from '#/components/common/loader/circular-progress'
import { Skeleton } from '#/components/common/loader/skeleton'
import { Tooltip } from '#/components/common/tooltip'
import handlers from '#/core/handlers'
import { downloadProgressAtom, DownloadQueueItem } from '#/core/stores'
import { convertBytes } from '#/utils'

import styles from './card.module.scss'

type Props = React.HTMLAttributes<HTMLElement> & {
  game: DownloadQueueItem
  active?: boolean
}

export const DownloadCard: React.FC<Props> = ({ className, active, game, ...props }) => {
  const progress = useAtomValue(downloadProgressAtom)

  const percentage = progress?.total && progress?.transferred ? (progress.transferred / progress.total) * 100 : 0

  const transferred = convertBytes(progress?.transferred || 0, {
    unit: 'MB',
    showUnit: false,
  })
  const total = convertBytes(progress?.total || 0)
  const progressing = `${transferred}/${total}`

  const handleRemoveQueue = async () => handlers.removeFromQueue(game.slug)

  return (
    <figure {...props} className={cx(styles.card, { [styles.validating]: progress === undefined }, className)}>
      <CircularProgress
        size={40}
        strokeWidth={4}
        value={active && progress !== undefined && ['download', 'update'].includes(progress.type) ? percentage : 'indeterminate'}
      />

      <div className={cx(styles.information, { [styles.active]: active })}>
        <Typography.Text className={styles.name}>{game.name}</Typography.Text>
        {active && (progress === undefined ? (
          <Skeleton width={204} height={16} />
        ) : (
          <Typography.Text className={styles.subText} level="3">
            {match(progress.type)
              .with('queued', () => 'Queued')
              .with('getting_latest', () => 'Checking')
              .with('download', () => `Downloading... ${progressing}`)
              .with('update', () => `Updating... ${progressing}`)
              .with('extract', () => 'Extracting')
              .with('copy', () => 'Copying')
              .with('remove_temp', () => 'Cleaning up')
              .with('storage', () => 'Saving')
              .with('done', () => 'Ready')
              .exhaustive()}
          </Typography.Text>
        ))}
      </div>
      <div className={styles.cancelContainer}>
        {!active && (
          <Tooltip content="Cancel queue">
            <button className={styles.cancel} onClick={handleRemoveQueue}>
              <Remove01Icon />
            </button>
          </Tooltip>
        )}
      </div>
    </figure>
  )
}
