import cx from 'classnames'

import { CrossIcon } from '#/components/common/icons/cross'
import { MinusIcon } from '#/components/common/icons/minus'
import { SquareIcon } from '#/components/common/icons/square'
import handlers from '#/core/handlers'

import styles from './window-handler.module.scss'

const items = [
  { label: <MinusIcon />, onClick: () => handlers.handleWindow('minimize') },
  { label: <SquareIcon />, onClick: () => handlers.handleWindow('maximize') },
  { label: <CrossIcon />, negative: true, onClick: () => handlers.handleWindow('close') },
]

export const WindowHandler: React.FC = () => {
  return (
    <div className={styles.container}>
      {items.map(({ label, negative, onClick }, index) => (
        <button className={cx(styles.action, { [styles.close]: negative })} key={index} tabIndex={-1} onClick={onClick}>
          {label}
        </button>
      ))}
    </div>
  )
}
