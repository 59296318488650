export function randomInt(min: number, max: number) {
  min = Math.ceil(min)
  max = Math.floor(max)

  return Math.floor(Math.random() * (max - min) + min)
}

const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'] as const
type StorageSizeUnit = typeof units[number]
type ConvertBytesOption = {
  decimals?: number
  unit?: StorageSizeUnit
  showUnit?: boolean
}
export const convertBytes = (bytes: number, options?: ConvertBytesOption): string => {
  const { decimals = 2, unit, showUnit = true } = options || {}
  let unitIndex = units.findIndex((u) => u === unit)
  if (bytes <= 0) return 'Unknown'

  if (unitIndex === -1) unitIndex = Math.floor(Math.log(bytes) / Math.log(1024))

  let value = (bytes / Math.pow(1024, unitIndex)).toFixed(decimals)
  if (showUnit) value += ` ${units[unitIndex]}`

  return value
}

export const compactNumber = (number: number, options?: Intl.NumberFormatOptions) =>
  Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
    ...options,
  }).format(number)
