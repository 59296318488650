import { KataruInvokeFnOnWeb } from '@axieinfinity/kataru'

export const setClipboard: KataruInvokeFnOnWeb<'system:set_clipboard'> = (content) =>
	window.bridge?.kataru.invoke('system:set_clipboard', content)

export const getFreeSpace: KataruInvokeFnOnWeb<'system:get_free_space'> = (location) =>
	window.bridge?.kataru.invoke('system:get_free_space', location)

export const openFolderLocation: KataruInvokeFnOnWeb<'system:show_folder'> = (path) =>
	window.bridge?.kataru.invoke('system:show_folder', path)

export const selectDestinationFolder: KataruInvokeFnOnWeb<'system:select_location'> = () =>
	window.bridge?.kataru.invoke('system:select_location')
