import { useSearchParams } from 'react-router-dom'

export const useSearchQuery = () => {
  const [searchParams] = useSearchParams()
  const keyword = searchParams.get('q')

  return {
    keyword,
  }
}
