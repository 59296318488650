import { ArrowLeftIcon, ArrowRightIcon } from '@axieinfinity/dango-icons'
import { Button, Dropdown, DropdownOption, Input } from '@axieinfinity/konan'
import { useCallback, useMemo, useRef, useState } from 'react'
import { match, P } from 'ts-pattern'

import { Skeleton } from '#/components/common/loader'
import { useGameComments } from '#/hooks/query'

import { Comment } from './comment'
import styles from './list.module.scss'

const filterOptions: Array<DropdownOption> = [
  { value: 'all', key: 'all', label: 'All' },
  { value: 'upvote', key: 'upvote', label: 'Recommended' },
  { value: 'downvote', key: 'downvote', label: 'Not recommended' },
]

type Props = {
  slug: string
}

export const Reviews: React.FC<Props> = ({ slug }) => {
  const [selectedFilter, setSelectedFilter] = useState(filterOptions[0])
  const [page, setPage] = useState(1)
  const paginationInputRef = useRef<HTMLInputElement>(null)

  const { data, isLoading } = useGameComments(slug, page, selectedFilter.value)

  const totalPage = useMemo(
    () =>
      data?.pagination
        ? Math.ceil(data.pagination.totalRows / data.pagination.pageSize)
        : 0,
    [data?.pagination]
  )
  const goToPage = useCallback(
    (to: number | 'prev' | 'next') => {
      const next = match(to)
        .with('next', () => Math.min(page + 1, totalPage))
        .with('prev', () => Math.max(page - 1, 1))
        .with(P.number, page => Math.max(1, Math.min(page, totalPage)))
        .exhaustive()
      setPage(next)
    },
    [setPage, page, totalPage]
  )

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.heading}>
          Total {data?.total ?? 0} review{(data?.total !== undefined && data.total > 1) ? 's' : ''}
        </div>

        <Dropdown
          className={styles.dropdown}
          popoverProps={{ applyLayerMinWidth: true }}
          selectedOption={selectedFilter}
          onChange={event => setSelectedFilter(event.option)}
        >
          {filterOptions.map(item => (
            <Dropdown.Item key={item.key} data={item}>
              {item.label}
            </Dropdown.Item>
          ))}
        </Dropdown>
      </div>

      {isLoading
        ? Array.from({ length: 3 }, (_, index) => (
          <Skeleton height={128} corner="rounded" key={index} />
        ))
        : data?.data.map(comment => (
          <Comment
            type={comment.gameVoteValue === 1 ? 'upvote' : 'downvote'}
            key={comment.id}
            comment={comment}
          />
        ))}

      {data?.pagination && data.pagination.totalRows > data.pagination.pageSize && (
        <div className={styles.pagination}>
          <Button
            text={<ArrowRightIcon />}
            disabled={data.pagination.page === 1}
            onClick={() => goToPage('prev')}
          />

          <span>Page</span>

          <Input
            type="number"
            ref={paginationInputRef}
            defaultValue={page}
            onBlur={e => (e.currentTarget.value = page.toString())}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                goToPage(e.currentTarget.valueAsNumber)
              }
            }}
          />

          <span>of {totalPage}</span>

          <Button
            text={<ArrowLeftIcon />}
            disabled={page === totalPage}
            onClick={() => goToPage('next')}
          />
        </div>
      )}
    </div>
  )
}
