import { useEffect } from 'react'
import { useNavigate } from 'react-router'

import { logger } from '#/core/logger'

import { useLoginDialog } from '../login-dialog'

export const autoPerformDeeplinkQueryPart = 'deeplink=1'

export const useDesktopDeeplinkListener = () => {
  const navigate = useNavigate()
  const { openLoginDialog } = useLoginDialog()

  // Listen deeplink is called
  useEffect(() => {
    const unsubscribe = window.bridge?.kataru.on('deeplink:run', (_, { game: slug, query }) => {
      const queries = new URLSearchParams(query)
      logger.debug('Received from deeplink', { queries }) // might pass access token here
      navigate(`/games/${slug}?${autoPerformDeeplinkQueryPart}`)
    })

    return () => void unsubscribe?.()
  }, [navigate, openLoginDialog])
}
