import { useMemoizedFn } from 'ahooks'
import { useRef } from 'react'
import { match, P } from 'ts-pattern'

import { useCaptureEvent } from '#/hooks'
import { useNews } from '#/hooks/query'

import { ObservableListItem, PaginationButtons, Section } from '../generic'
import { NewsCard } from './card'
import styles from './section.module.scss'

type Props = {
  defaultHidden?: boolean
}

export const NewsSection: React.FC<Props> = () => {
  const captureEvent = useCaptureEvent()

  const { isLoading, data } = useNews()

  const listElementRef = useRef<HTMLUListElement>(null)

  const handlePaginationPress = useMemoizedFn((direction: 'previous' | 'next') => () => {
    captureEvent(`Click view ${direction} page`, { collection: 'News & Events' })

    if (!listElementRef.current) {
      return
    }

    const nodes = listElementRef.current.childNodes
    const lastItemIndex = nodes.length === 1 ? 0 : nodes.length - 1
    const inViewNodes = Array
      .from(nodes, (node, index) => ({ isInViewport: (node as HTMLLIElement).dataset.inViewport === 'true', index }))
      .filter((node) => node.isInViewport)

    if (inViewNodes.length === 0) {
      return
    }

    const nextElementIndex = match(direction)

      .with('previous', () => inViewNodes[0].index === 0 ? lastItemIndex : inViewNodes[0].index - 1)
      .with('next', () => inViewNodes[inViewNodes.length - 1].index === lastItemIndex ? 0 : inViewNodes[inViewNodes.length - 1].index + 1)
      .exhaustive()

    const element = nodes.item(nextElementIndex) as HTMLLIElement
    element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
  })

  return (
    <Section
      className={styles.section}
      heading="News & Events"
      pagination={<PaginationButtons isDisabled={isLoading} onPress={handlePaginationPress} />}
    >
      {match({ isLoading, data })
        .with({ isLoading: true }, () => null)
        .with({ data: P.nullish }, () => null)
        .with({ data: P.not(P.nullish) }, ({ data }) => (
          <ul ref={listElementRef} data-slot="list">
            {data.map((news) => (
              <ObservableListItem key={news.id} containerRef={listElementRef} data-slot="list-item">
                <a href={news.canonical_url} target="_blank" rel="noopener noreferrer" >
                  <NewsCard {...news} />
                </a>
              </ObservableListItem>
            ))}
          </ul>
        ))
        .exhaustive()}
    </Section>
  )
}
