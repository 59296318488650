import {
  AndroidIcon,
  AppleIcon,
  Browser01Icon,
  HelpCircle01Icon,
  LightbulbIcon,
  MapPin06Icon,
  MoneyTransferIcon,
  PluginIcon,
  type SvgIconProps,
  WindowsIcon,
} from '@axieinfinity/dango-icons'
import type { SupportPlatformOs } from '@axieinfinity/hub-services'

export const BirdIcon: React.FC<SvgIconProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor" {...props}>
    <path d="M12.4189 5.28189C12.1833 5.28189 11.9942 5.09829 11.9942 4.86536C11.9942 4.63244 12.186 4.44062 12.4189 4.44062C12.6519 4.44062 12.8437 4.63244 12.8437 4.86536C12.8437 5.09829 12.6519 5.28189 12.4189 5.28189ZM10.931 5.28189C10.7063 5.28189 10.5144 5.09829 10.5144 4.86536C10.5144 4.63244 10.7063 4.44062 10.931 4.44062C11.1748 4.44062 11.3667 4.63244 11.3667 4.86536C11.3667 5.09829 11.1748 5.28189 10.931 5.28189ZM12.4436 3.85693C12.123 3.85693 11.8435 4.02135 11.6791 4.21317C11.4955 4.01313 11.2351 3.85693 10.9063 3.85693C10.3418 3.85693 9.88965 4.30908 9.88965 4.86536C9.88965 5.42164 10.3582 5.87379 10.9063 5.87379C11.0543 5.87379 11.2187 5.84639 11.342 5.7861V6.60271H12.0106V5.7861C12.1586 5.85461 12.323 5.87379 12.4463 5.87379C13.0108 5.87379 13.4712 5.41342 13.4712 4.86536C13.4712 4.3173 13.0108 3.85693 12.4463 3.85693H12.4436Z"/>
    <path d="M3.8634 16.202H4.6389C5.28835 16.202 5.72954 16.054 6.02275 15.6128L8.67535 11.5161C8.82333 11.7518 8.78223 12.1053 8.45888 12.6067L6.13237 16.202H6.84484C7.49429 16.202 7.93547 16.054 8.22868 15.6128L10.8813 11.5161C11.0293 11.7518 10.9882 12.1053 10.6648 12.6067L8.36021 16.1664H9.04529C9.7057 16.1664 10.1414 16.0102 10.4264 15.558L10.8868 14.862H10.9032C12.6433 14.862 14.1559 13.7741 14.1559 11.5051V10.124C14.1559 9.07998 16.0002 9.19508 16.0002 8.27982H14.1998C14.06 8.27982 14.0436 8.26338 13.9203 8.13184C13.3119 7.42759 12.5282 7.04395 11.6678 7.04395C10.251 7.04395 9.42345 7.69614 8.62329 8.8772L3.85791 16.1993L3.8634 16.202Z" />
  </svg>
)

export const IOSIcon: React.FC<SvgIconProps> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M11.6329 14.1831C12.041 13.7925 12.3999 13.1236 12.3999 12C12.3999 10.8764 12.041 10.2075 11.6329 9.81689C11.2084 9.41051 10.6204 9.2 9.99993 9.2C9.37942 9.2 8.79151 9.41051 8.36695 9.81689C7.95883 10.2075 7.59993 10.8764 7.59993 12C7.59993 13.1236 7.95883 13.7925 8.36695 14.1831C8.79151 14.5895 9.37942 14.8 9.99993 14.8C10.6204 14.8 11.2084 14.5895 11.6329 14.1831Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M6 2C3.79086 2 2 3.79086 2 6V18C2 20.2091 3.79086 22 6 22H18C20.2091 22 22 20.2091 22 18V6C22 3.79086 20.2091 2 18 2H6ZM15.717 8.37576C16.0938 8.13075 16.5365 8 16.9893 8H17.2162C18.4461 8 19.5181 8.81648 19.8164 9.98033L18.666 10.2609C18.4997 9.61194 17.902 9.15669 17.2162 9.15669H16.9893C16.7706 9.15669 16.5568 9.21984 16.3748 9.33819L16.295 9.39011C16.0366 9.55809 15.8815 9.8409 15.8815 10.1437C15.8815 10.5522 16.1617 10.9101 16.5657 11.0176L18.3089 11.4813C19.445 11.7836 20.1621 12.8756 19.9685 14.0087C19.8478 14.715 19.3879 15.3224 18.7313 15.6427L18.5618 15.7254C18.0313 15.9841 17.4276 16.0629 16.8461 15.9495L16.6485 15.9109C15.8022 15.7458 15.0578 15.2595 14.579 14.559L14.4 14.2971L15.3866 13.6554L15.5657 13.9174C15.87 14.3627 16.3431 14.6718 16.8811 14.7767L17.0787 14.8153C17.4016 14.8783 17.7369 14.8345 18.0315 14.6908L18.201 14.6081C18.5183 14.4533 18.7405 14.1598 18.7988 13.8185C18.8924 13.271 18.5459 12.7433 17.9969 12.5973L16.2537 12.1335C15.3338 11.8888 14.6957 11.0738 14.6957 10.1437C14.6957 9.45416 15.049 8.81019 15.6372 8.42768L15.717 8.37576ZM4 16.0001V10.4999H5.2V16.0001H4ZM4.6 8C4.26863 8 4 8.26863 4 8.6C4 8.93137 4.26863 9.2 4.6 9.2C4.93137 9.2 5.2 8.93137 5.2 8.6C5.2 8.26863 4.93137 8 4.6 8ZM9.99993 16C11.7672 16 13.5999 14.8 13.5999 12C13.5999 9.2 11.7672 8 9.99993 8C8.23262 8 6.39993 9.2 6.39993 12C6.39993 14.8 8.23262 16 9.99993 16Z" />
  </svg>
)

export const GooglePlayIcon: React.FC<SvgIconProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="currentColor" {...props}>
    <path d="M4.05096 1.9385C4.69884 1.58123 5.46442 1.5729 6.11647 1.93329L14.1952 6.39242L11.6443 8.94332L4.05096 1.9385Z"/>
    <path d="M3 3.67278C3 3.30509 3.10624 2.95824 3.29165 2.65513L10.9079 9.68078L3.27186 17.3168C3.09687 17.02 3 16.6804 3 16.3242V3.67278Z"/>
    <path d="M17.5773 8.25899L15.1441 6.91635L12.4099 9.64953L15.7608 12.74L17.5784 11.7369C18.2325 11.3744 18.6241 10.7245 18.6241 9.99743C18.6231 9.27039 18.2325 8.62042 17.5773 8.25899Z"/>
    <path d="M4.01661 18.0438L11.6735 10.387L14.8004 13.2712L6.11649 18.0636C5.79046 18.2438 5.43423 18.3334 5.08009 18.3334C4.71344 18.3334 4.34888 18.2334 4.01661 18.0438Z"/>
  </svg>
)

const GameRPG: React.FC<SvgIconProps> = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.2857 11.2857C18.0215 11.2857 19.4286 9.87861 19.4286 8.14286C19.4286 6.40711 18.0215 5 16.2857 5C14.55 5 13.1429 6.40711 13.1429 8.14286C13.1429 9.87861 14.55 11.2857 16.2857 11.2857ZM16.2857 11.2857C14.2888 11.2857 12.5093 12.2169 11.3579 13.6689C11.1673 13.9092 10.9939 14.1639 10.8395 14.431C10.3056 15.355 10 16.4275 10 17.5714C10 18.4393 10.7036 19.1429 11.5714 19.1429C12.4393 19.1429 13.1429 19.8464 13.1429 20.7143V23.8571C13.1429 25.5929 14.55 27 16.2857 27C18.0215 27 19.4286 25.5929 19.4286 23.8571V20.7143C19.4286 20.0297 19.8663 19.4474 20.4772 19.2319C20.6407 19.1742 20.8167 19.1429 21 19.1429C21.8679 19.1429 22.5714 18.4393 22.5714 17.5714C22.5714 16.6285 22.3638 15.734 21.9917 14.9312C20.9941 12.7787 18.8142 11.2857 16.2857 11.2857Z" />
  </svg>
)

const GameBuild: React.FC<SvgIconProps> = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M6.53224 22.8903C6.18902 23.2335 6 23.691 6 24.1787C6 24.6664 6.18902 25.124 6.53224 25.4672C6.88749 25.8224 7.35405 26 7.82073 26C8.28729 26 8.75397 25.8224 9.10918 25.4672L16.1265 18.4499L13.5495 15.8729L6.53224 22.8903Z" />
    <path d="M23.5897 8.40982C23.0552 7.8754 22.2687 7.7431 21.6093 8.01275L23.9867 10.3902C24.2563 9.73083 24.1241 8.94429 23.5897 8.40982Z" />
    <path d="M25.8216 13.943L18.0564 6.17793C17.9425 6.06398 17.7881 6 17.627 6H15.3935C15.2323 6 15.0779 6.06398 14.964 6.17793L11.8145 9.32739C11.7006 9.4413 11.6366 9.59578 11.6366 9.75686V11.9904C11.6366 12.1515 11.7006 12.306 11.8145 12.4198L19.5796 20.185C19.6935 20.2989 19.848 20.3629 20.0091 20.3629H22.2426C22.4037 20.3629 22.5582 20.2989 22.6721 20.185L25.8216 17.0355C25.9355 16.9216 25.9995 16.7671 25.9995 16.606V14.3725C25.9994 14.2114 25.9355 14.0569 25.8216 13.943Z" />
  </svg>
)

export const platformSymbolMapper: Record<
  SupportPlatformOs,
  React.FC<SvgIconProps> | undefined
> = {
  windows: WindowsIcon,
  macos: AppleIcon,
  android: AndroidIcon,
  ios: IOSIcon,
  web: Browser01Icon,
  'android-google-play': GooglePlayIcon,
}

export const gameGenreSymbolMapper: Record<string, React.FC<SvgIconProps>> = {
  'early-access': HelpCircle01Icon,
  'turn-based': MoneyTransferIcon,
  strategy: LightbulbIcon,
  adventure: MapPin06Icon,
  rpg: GameRPG,
  mmorpg: HelpCircle01Icon,
  'action-rpg': HelpCircle01Icon,
  '4x': HelpCircle01Icon,
  moba: HelpCircle01Icon,
  'auto-battler': HelpCircle01Icon,
  fps: HelpCircle01Icon,
  'battle-royale': HelpCircle01Icon,
  puzzle: PluginIcon,
  'match-3': PluginIcon,
  merge: HelpCircle01Icon,
  hypercasual: HelpCircle01Icon,
  sports: HelpCircle01Icon,
  racing: HelpCircle01Icon,
  fighting: HelpCircle01Icon,
  simulation: HelpCircle01Icon,
  survival: HelpCircle01Icon,
  'build-and-battle': GameBuild,
}
