import styles from './text.module.scss'

type Props = {
  text?: string
}

export const EventBannerTitle: React.FC<Props> = ({ text }) => {
  return (
    <div className={styles.container}>
      <img className={styles.brand} src="/assets/event-banner-brand.svg" />
      <div className={styles.text} data-title={text} />
    </div>
  )
}
