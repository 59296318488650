import { Profile } from '@axieinfinity/hub-services'
import { useQuery } from '@tanstack/react-query'

import { logger } from '#/core/logger'
import { services } from '#/core/services'

import { useUserProfile } from '../query'

export const gameDetailsQueryKeys = {
	all: ['game_details'] as const,
	details: (slug: string | undefined) =>
		[...gameDetailsQueryKeys.all, slug] as const,
	private: (
		slug: string | undefined,
		profile: Profile['asProfile'] | null | undefined
	) => [...gameDetailsQueryKeys.details(slug), profile?.userID ?? 'unknown'] as const,
}

export const useGameDetails = (slug?: string) => {
	const { data: profile } = useUserProfile()

	return useQuery({
		queryKey: gameDetailsQueryKeys.private(slug, profile),
		queryFn: async () => {
			try {
				if (!slug) return undefined

				const { response, error, result } = await services.request(
					'get /v2/public/games/[slug]',
					{ slug }
				)
				if (response.status >= 500) return undefined
				if (error) return null

				return result
			} catch (e) {
				logger.error(e, { error: e }, 'useGameDetails')

				return null
			}
		},
	})
}
