import { useEffect } from 'react'
import { useNavigate } from 'react-router'

import { useUserProfile } from '#/hooks/query'

export const withAuthentication = <T extends JSX.IntrinsicAttributes>(
  Component: React.ComponentType<T>
): React.FC<T> => {
  const WrappedComponent: React.FC<T> = props => {
    const navigate = useNavigate()
    const { isLoading, data: profile } = useUserProfile()

    useEffect(() => {
      if (isLoading === false && profile === null) {
        navigate('/')
      }
    }, [profile, navigate, isLoading])

    const isRedirecting = profile === undefined || (isLoading && profile === null)

    if (!isRedirecting) {
      return <Component {...props} />
    }
  }

  return WrappedComponent
}
