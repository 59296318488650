/*
- minimumFractionDigits:
    Purpose: Determines the minimum number of decimal places to display for each numerical value.
    Example: If minimumFractionDigits is set to 2, a number like 3.5 will be displayed as 3.50.
- maximumFractionDigits:
    Purpose: Determines the maximum number of decimal places to display for each numerical value.
    Example: If maximumFractionDigits is set to 2, a number like 3.456 will be displayed as 3.46 (rounding up or down depending on the actual value).
- maximumSignificantDigits:
    Purpose: Determines the maximum number of significant digits to display.
    Example: If maximumSignificantDigits is set to 3, the number 12345 will be displayed as 12300.
*/

type AmountFormatOptions = {
  minimumFractionDigits?: number
  maximumFractionDigits?: number
  maximumSignificantDigits?: number
}

type Numeric = string | number

const convertToNumber = (value: Numeric) => {
  if (typeof value === 'string') return parseFloat(value)

  return value
}

const convertToBigInt = (value: Numeric) => {
  return BigInt(value)
}

// https://github.com/wevm/viem/blob/main/src/utils/unit/formatUnits.ts
export function formatUnits(value: bigint, decimals: number) {
  let display = value.toString()

  const negative = display.startsWith('-')
  if (negative) display = display.slice(1)

  display = display.padStart(decimals, '0')

  const integer = display.slice(0, display.length - decimals)
  let fraction = display.slice(display.length - decimals)
  fraction = fraction.replace(/(0+)$/, '')

  return `${negative ? '-' : ''}${integer || '0'}${
    fraction ? `.${fraction}` : ''
  }`
}

// https://github.com/axieinfinity/mavis-store-sand-box/blob/feat/preproduction/src/utils/number.ts#L30
export const formatNumber = (
  value: Numeric,
  options?: AmountFormatOptions
) => {
  const number = convertToNumber(value)

  const maximumFractionDigits = (() => {
    if (number < 1) return 20

    return Math.max(4 - Math.floor(Math.log10(number)), 0)
  })()

  const maximumSignificantDigits = (() => {
    if (number < 1) return 4

    return undefined
  })()

  return {
    number,
    text: new Intl.NumberFormat('en-US', {
      minimumFractionDigits: options?.minimumFractionDigits,
      maximumFractionDigits:
        options?.maximumFractionDigits ?? maximumFractionDigits,
      maximumSignificantDigits:
        options?.maximumSignificantDigits ?? maximumSignificantDigits,
    }).format(number),
  }
}

export const formatAmount = (
  amount: Numeric,
  decimals: number,
  options?: AmountFormatOptions
) => {
  return formatNumber(formatUnits(convertToBigInt(amount), decimals), options)
}
