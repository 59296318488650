import { BookmarkIcon, ConsoleIcon } from '@axieinfinity/dango-icons'
import cx from 'classnames'

import styles from './badge.module.scss'

const InLibrary: React.FC = () => (
  <div className={cx(styles.tag, styles.library)}>
    <BookmarkIcon className={styles.icon} />
    <span>In Library</span>
  </div>
)

const ComingSoon: React.FC = () => (
  <div className={cx(styles.tag, styles.comingSoon)}>
    <span>Coming Soon</span>
  </div>
)

const Demo: React.FC = () => (
  <div className={cx(styles.tag, styles.demo)}>
    <span>Demo</span>
  </div>
)

const Greenlight: React.FC = () => (
  <div className={cx(styles.tag, styles.greenlight)}>
    <span>Greenlight</span>
  </div>
)

const AxieGameJam: React.FC<{ year?: number }> = ({ year }) => (
  <div className={cx(styles.tag, styles.axiejam)} title={`Axie Game Jam ${year}`}>
    <ConsoleIcon /> <span>AGJ {year}</span>
  </div>
)

export const Badge = {
  InLibrary,
  ComingSoon,
  Demo,
  Greenlight,
  AxieGameJam,
}
